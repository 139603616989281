/***************************************
     START CARD AREA
****************************************/
/* card style1 */
.card_style1{
  @extend .cardify;
  margin-bottom: 30px;
  .card_style1__info{
    img{
      width: 100%;
      border-radius: 6px 6px  0 0;
    }
    .date_place{
      li{
        margin-bottom: 17px;
        span{
          color: $pcolor;
          display: inline-block;
          font-size: 20px;
        }
        span,p{
          @include vertically_middle;
        }
        p{
          display: inline-block;
          font-weight: 500;
          font-size: 16px;
          margin-left: 13px;
          margin-bottom: 0;
        }
      }
    }
    figcaption{
      a:hover{
        h3{
          color: $pcolor;
        }
      }
      h3{
        transition: 0.3s;
        font-weight: 500;
        line-height: 36px;
        font-size: 23px;
        margin-bottom: 17px;
      }
      padding: 36px 40px;
    }
  }
}

/* card style 2 */
.card_style2{
  @extend .cardify;
  padding: 40px;
  margin-bottom: 30px;
  .card_style2__title{
    color: #000;
    font-size: 24px;
    margin-bottom: 25px;
    line-height: 30px;
  }
  .card_style2__location_type{
    margin-bottom: 20px;
    p,.type{
      display: inline-block;
      margin-bottom: 0;
    }
    p{
      color: #5a6671;
      font-size: 16px;
      line-height: 30px;
      margin-right: 17px;
      span{
        color: $pcolor;
        font-size: 20px;
        margin-right: 8px;
        vertical-align: -3px;
      }
    }
    .type{
      color: #fff;
      line-height: 28px;
      font-weight: 500;
      border-radius: 200px;
      padding: 0 17px;
    }
  }
  a{
    font-size: 16px;
    line-height: 30px;
    color: $pcolor;
    font-weight: 500;
  }
}
/***************************************
     END CARD AREA
****************************************/
/*
Template Name: MartPlace - Multipurpose Online Marketplace HTML Template with Dashboard
Template URI: https://themeforest.net/user/aazztech/portfolio
Author: Aazztech
Author URI: https://themeforest.net/user/aazztech
Version: 2.1.0
NB: this file contains the list of components/scss partial along with the style.scss contents order.
*/
/**                              **
**       TABLE OF CONTENTS       **
**                               **
-----------------------------------*/
/*
  01.COMMON
    -- reset
    -- helper
    -- theme
    -- general
    -- mixins

  02.COMPONENTS
    -- menu
    -- sidebar
    -- btn
    -- product
    -- social
    -- accordion
    -- breadcrumb
    -- modal
    -- table
    -- progressbar
    -- timeline
    -- team
    -- pricing
    -- bumba
    -- datepicker
  03. MENU AREA
  04. HERO AREA
  05. FEATURE AREA
  06. PRODUCTS AREA
  07. FEATURED PRODUCTS AREA
  08. FOLLOWERS FEED
  09. COUNTER UP AREA
  10. WHY CHOOSE AREA
  11. SELL BUY AREA
  12. TESTIMONIAL AREA
  13. LATEST NEWS AREA
  14. SPECIAL FEATURE
  15. CALL TO ACTION
  16. FOOTER AREA
  17. PROMOTION AREA
  18. OVERVIEW AREA
  19. PARTNER AREA
  20. SEARCH AREA2
  21. SINGLE PRODUCT1 AREA
  22. OVERVIEW AREA
  23. AUTHOR AREA
  24. DASHBOARD AREA
  25. NOTIFICATIONS AREA
  26. MESSAGE AREA
  27. CART PAGE AREA
  28. CHECKOUT AREA
  29. SUPPORT AREA
  30. HOW IT WORKS AREA
  31. FORUM AREA
  32. ABOUT US  HERO AREA
  33. ABOUT AND MISSION AREA
  34. GALLERY ARE
  35. TESTIMONIAL VIDEO
  36. PRICING AREA
  37. FAQ AREA
  38. AFFILIATE PAGE
  39. TERM AND CONDITION AREA
  40. BLOG AREA
  41. CONTACT AREA
  42. 404 AREA
  43. JOB HERO HERO
  44. JOB  AREA
  45. FEATURE AREA
  46. JOB DETAIL
  47. DETAIL AREA
  48. EVENT AREA
  49. EVENT DETAIL
  50. MAP AREA
  51. TICKET AREA
  52. RESPONSIVE CSS
***************************/
/* import fonts */
@import url("https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700");
/* import style css */
/*****************************
	-- THEME
*****************************/
/* RESET CSS */
/*****************************
	-- RESET
*****************************/
body {
  font-family: 'Quicksand', sans-serif;
  position: relative;
  font-weight: 400;
  font-size: 15px;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a:hover, a:focus {
  text-decoration: none;
}

a:focus {
  outline: 0;
}

img {
  max-width: 100%;
}

input[type=date],
input[type=datetime-local],
input[type=email],
input[type=number],
input[type=password],
input[type=search-md],
input[type=search],
input[type=tel],
input[type=text],
input[type=time],
input[type=url],
textarea,
select {
  box-sizing: border-box;
  box-shadow: none;
  outline: none;
  width: 100%;
  padding: 0 20px;
}

select.text_field {
  height: 50px;
}

select.text_field {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input {
  line-height: 45px;
  height: 45px;
  border: 1px solid #ececec;
  border-radius: 2px;
  color: #747b86;
  font-size: 16px;
}

label, .form-group p.label {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-transform: capitalize;
  margin-bottom: 10px;
  color: #000;
}

label span, .form-group p.label span {
  font-weight: 400;
  font-size: 15px;
  color: #555;
}

label sup, .form-group p.label sup {
  color: #ff6a6d;
}

select[multiple] {
  height: 80px;
}

select:focus {
  outline: 0;
  border-color: #0674ec;
}

.select--field {
  height: 55px;
  border: 1px solid #dcdcdc;
}

.text_field {
  line-height: 50px;
  background: #f4f5f8;
  border: none;
}

textarea {
  border: 1px solid #ececec;
  min-height: 135px;
  line-height: 25px;
  padding-top: 15px;
}

textarea.text_field {
  line-height: 25px;
  padding-top: 15px;
  min-height: 135px;
}

button {
  margin: 0;
  padding: 0;
}

button:focus {
  outline: 0;
}

/* TYPOGRAPHY */
p {
  font-size: 16px;
  line-height: 26px;
  color: #555555;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Quicksand', sans-serif;
  margin: 0;
  font-weight: 400;
  padding: 0;
  color: #000000;
}

a {
  color: #0674ec;
}

.form-group {
  margin-bottom: 20px;
}

.form-group p {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 0;
  color: #898f96;
}

.form-group p span {
  font-weight: 500;
  color: #000;
}

.form-group p.label {
  margin-top: 0;
  clear: right;
  padding: 0;
}

/* HELPER CLASSES: this file contains classes for quick uses */
/*****************************
	-- HELPER
*****************************/
.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.padding-left0 {
  padding-left: 0;
}

.ltr {
  text-align: right;
}

.rtl {
  text-align: left;
}

.br_0 {
  border-right: none !important;
}

.bt1 {
  border-top: 1px solid #e4e4e4;
}

.v_middle, .user_area .user_single .user__short_desc .user_avatar, .user_area .user_single .user__short_desc .user_info, .statement_info_card .icon, .statement_info_card .info {
  display: inline-block;
  vertical-align: middle;
  margin-right: -3px;
  float: none;
}

.inline {
  display: inline-block;
}

.text--white {
  color: #fff;
}

.rounded, .credit_modules .modules__content .amounts ul li p, .shortcode_modules .modules__content .amounts ul li p, .upload_modules .modules__content .amounts ul li p, .withdraw_module .modules__content .amounts ul li p {
  border-radius: 200px !important;
}

.border-right1 {
  border-right: 1px solid #ececec;
}

.bold {
  font-weight: 500;
}

/* verticaly middle */
.ver_mid {
  display: table-cell;
  vertical-align: middle;
}

/*****************************
	-- THEME
*****************************/
/*****************************
	-- THEME
*****************************/
/*****************************
	-- THEME
*****************************/
/*****************************
	-- MIXINS
*****************************/
/* container sizes for various devices */
/* Custom scrollbar */
/*****************************
	-- GENERAL
*****************************/
.preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}

.pcolor {
  color: #0674ec;
}

.scolor {
  color: #7347c1;
}

/* Background Image */
.bgimage {
  position: relative;
}

.bgimage:before {
  z-index: 1;
}

.content_above {
  position: relative;
  z-index: 2;
}

/* Background image holder */
.bg_image_holder {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-size: cover !important;
  background-position: 50% 50% !important;
  z-index: 0;
  transition: opacity .3s linear;
  -webkit-transition: opacity .3s linear;
  opacity: 0;
}

.bg_image_holder img {
  display: none;
}

.or {
  width: 100%;
  background: #ececec;
  height: 1px;
  margin-bottom: 33px;
  position: relative;
}

.or:before {
  content: 'Or';
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  color: #0674ec;
  font-weight: 500;
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.mcolor1 {
  color: #77de51;
}

.mcolor2 {
  color: #feb71d;
}

.mcolor3 {
  color: #58c9e9;
}

.mcolor4 {
  color: #ff6a6d;
}

.pcolorbg {
  background: #0674ec;
}

.scolorbg {
  background: #7347c1;
}

.mcolorbg1 {
  background: #77de51;
}

.mcolorbg2 {
  background: #feb71d;
}

.mcolorbg3 {
  background: #58c9e9;
}

.mcolorbg4 {
  background: #ff6a6d;
}

/* tooltip */
.tooltip {
  display: block;
}

.tooltip .tooltip-inner {
  font-family: "Quicksand", sans-serif;
  font-size: 14px;
  text-align: left;
  display: block;
}

/*.highlighted{
  background: $pcolor;
}*/
.section--padding {
  padding-top: 120px;
  padding-bottom: 120px;
}

.section--padding2 {
  padding: 70px 0 120px;
}

.section--padding3 {
  padding: 70px 0 0;
}

.section-title {
  text-align: center;
  padding-bottom: 53px;
}

.section-title h1 {
  font-weight: 400;
  margin-bottom: 24px;
}

.section-title h1 .highlighted {
  color: #0674ec;
}

.section-title p {
  padding: 0 230px;
  line-height: 30px;
  margin-bottom: 0;
}

.shortcode_wrapper + .shortcode_wrapper {
  margin-top: 80px;
}

/* Input with icons */
.input_with_icon {
  position: relative;
}

.input_with_icon span {
  position: absolute;
  right: 20px;
  top: 50%;
  color: #747b86;
  transform: translateY(-50%);
}

.product-title-area, .shortcode_module_title, .dashboard_contents .dashboard_title_area {
  background: #fff;
  padding: 32px 30px 32px;
  margin-bottom: 30px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-title-area:before, .shortcode_module_title:before, .dashboard_contents .dashboard_title_area:before, .product-title-area:after, .shortcode_module_title:after, .dashboard_contents .dashboard_title_area:after {
  display: table;
  content: "";
  clear: both;
}

.product-title-area .product__slider-nav, .shortcode_module_title .product__slider-nav, .dashboard_contents .dashboard_title_area .product__slider-nav {
  float: right;
  background: #7347c1;
}

.product-title-area .product__slider-nav span, .shortcode_module_title .product__slider-nav span, .dashboard_contents .dashboard_title_area .product__slider-nav span {
  line-height: 45px;
  color: #fff;
  cursor: pointer;
  width: 52px;
  display: inline-block;
  text-align: center;
}

.product-title-area .date_area, .shortcode_module_title .date_area, .dashboard_contents .dashboard_title_area .date_area {
  float: right;
}

.product-title-area .date_area p, .shortcode_module_title .date_area p, .dashboard_contents .dashboard_title_area .date_area p {
  float: left;
}

.product-title-area .date_area .input_with_icon, .shortcode_module_title .date_area .input_with_icon, .dashboard_contents .dashboard_title_area .date_area .input_with_icon {
  max-width: 158px;
}

.product-title-area .date_area .input_with_icon, .shortcode_module_title .date_area .input_with_icon, .dashboard_contents .dashboard_title_area .date_area .input_with_icon, .product-title-area .date_area .select-wrap, .shortcode_module_title .date_area .select-wrap, .dashboard_contents .dashboard_title_area .date_area .select-wrap {
  float: left;
  margin-right: 10px;
}

.product-title-area .date_area .select-wrap select, .shortcode_module_title .date_area .select-wrap select, .dashboard_contents .dashboard_title_area .date_area .select-wrap select {
  height: 47px;
}

.product-title-area .date_area .btn:hover, .shortcode_module_title .date_area .btn:hover, .dashboard_contents .dashboard_title_area .date_area .btn:hover {
  background: #0674ec;
  color: #fff;
}

.product__title {
  float: left;
}

.product__title h2 {
  font-weight: 400;
  line-height: 45px;
}

.filter__menu {
  float: right;
  position: relative;
  margin-top: 10px;
}

.filter__menu p {
  margin-bottom: 0;
  display: inline-block;
  vertical-align: middle;
  color: #747b86;
  margin-right: 15px;
}

.filter__menu .filter__menu_icon {
  display: inline-block;
  vertical-align: middle;
  margin-top: 6px;
  width: 17px;
}

.filter__menu .filter__menu_icon svg {
  height: 16px;
  cursor: pointer;
}

.filter__menu .filter__menu_icon svg:hover circle {
  fill: #0673ec;
}

.filter__menu .filter__menu_icon svg circle {
  fill: #a3b1c4;
}

.filter__menu .filter__menu_icon .filter_dropdown {
  position: absolute;
  min-width: 183px;
  padding: 20px 27px;
  top: 45px !important;
  z-index: 3;
  background: #fff;
  right: -15px;
  left: auto !important;
  box-shadow: 0 5px 40px rgba(82, 85, 90, 0.2);
  transform: translate3d(0, 0, 0) !important;
}

.filter__menu .filter__menu_icon .filter_dropdown.active {
  display: block;
}

.filter__menu .filter__menu_icon .filter_dropdown:before {
  position: absolute;
  content: "";
  border-bottom: 10px solid #fff;
  border-left: 10px solid transparent;
  right: 15px;
  bottom: 100%;
  border-right: 10px solid transparent;
}

.filter__menu .filter__menu_icon .filter_dropdown li a {
  color: #747b86;
  display: block;
  transition: 0.3s ease;
  line-height: 28px;
}

.filter__menu .filter__menu_icon .filter_dropdown li a:hover {
  color: #0674ec;
}

.transparent {
  background: transparent;
}

.rounded, .credit_modules .modules__content .amounts ul li p, .shortcode_modules .modules__content .amounts ul li p, .upload_modules .modules__content .amounts ul li p, .withdraw_module .modules__content .amounts ul li p {
  border-radius: 200px;
}

.tags ul > li {
  display: inline-block;
}

.tags--round ul > li a {
  color: #868c96;
  line-height: 28px;
  background: #f1f3f6;
  display: inline-block;
  padding: 0 17px;
  margin-right: 4px;
  border-radius: 50px;
  transition: 0.3s ease;
}

.tags--round ul > li a:hover {
  background: #7347c1;
  color: #fff;
}

.bgcolor {
  background: #eff1f5;
}

.bgcolor2 {
  background: #f6f7fb;
}

.range-slider.ui-slider {
  height: 6px;
  background: #efedf3;
  border-radius: 6px;
}

.range-slider.ui-slider .ui-slider-range {
  background: #7347c1;
}

.range-slider.ui-slider .ui-slider-handle {
  height: 18px;
  width: 18px;
  background: #0674ec;
  border-radius: 50px;
  top: -6px;
  z-index: 2;
  cursor: pointer;
}

.range-slider.ui-slider .ui-slider-handle:focus {
  outline: 0;
}

.range-slider.ui-slider .ui-slider-handle:before {
  content: '';
  position: absolute;
  height: calc(100% - 8px);
  width: calc(100% - 8px);
  background: #fff;
  left: 4px;
  top: 4px;
  z-index: -1;
  border-radius: 50%;
}

.price-ranges {
  padding-top: 34px;
  text-align: center;
}

.price-ranges span {
  line-height: 32px;
  display: inline-block;
  background: rgba(115, 71, 193, 0.1);
  width: 80px;
  color: #7347c1;
}

.rating {
  display: inline-block;
  vertical-align: middle;
}

.rating ul {
  float: left;
  padding-bottom: 0;
}

.rating ul li {
  float: left;
  margin-right: 3px;
}

.rating ul li span.fa-star, .rating ul li span.fa-star-half-o {
  color: #ffc000;
}

.rating ul li span.fa-star-o {
  color: #7e7e7e;
}

.rating .rating__count {
  display: inline-block;
  color: #6f7d8d;
  vertical-align: middle;
  margin-left: 5px;
}

.custom-checkbox2 input[type='checkbox'], .custom-radio input[type='checkbox'] {
  display: none;
}

.custom-checkbox2 input[type='checkbox']:checked + label span:before, .custom-radio input[type='checkbox']:checked + label span:before, .custom-checkbox2 .form-group input[type='checkbox']:checked + p.label span:before, .form-group .custom-checkbox2 input[type='checkbox']:checked + p.label span:before, .custom-radio .form-group input[type='checkbox']:checked + p.label span:before, .form-group .custom-radio input[type='checkbox']:checked + p.label span:before {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.custom-checkbox2 label, .custom-radio label, .custom-checkbox2 .form-group p.label, .form-group .custom-checkbox2 p.label, .custom-radio .form-group p.label, .form-group .custom-radio p.label {
  font-size: 15px;
  font-weight: 400;
  margin: 0;
  line-height: 36px;
  cursor: pointer;
  text-transform: none;
}

.custom-checkbox2 label span.circle, .custom-radio label span.circle, .custom-checkbox2 .form-group p.label span.circle, .form-group .custom-checkbox2 p.label span.circle, .custom-radio .form-group p.label span.circle, .form-group .custom-radio p.label span.circle {
  height: 20px;
  margin-right: 12px;
  vertical-align: middle;
  width: 20px;
  background: #fff;
  display: inline-block;
  border: 4px solid #e6e9ed;
  border-radius: 50%;
  position: relative;
}

.custom-checkbox2 label span.circle:before, .custom-radio label span.circle:before, .custom-checkbox2 .form-group p.label span.circle:before, .form-group .custom-checkbox2 p.label span.circle:before, .custom-radio .form-group p.label span.circle:before, .form-group .custom-radio p.label span.circle:before {
  content: '\f00c';
  font-family: 'FontAwesome',sans-serif;
  position: absolute;
  height: 20px;
  width: 20px;
  text-align: center;
  font-size: 12px;
  bottom: -4px;
  transition: 0.2s;
  left: -4px;
  opacity: 0;
  transform: scale(0.5);
  border-radius: 50%;
  background: #0674ec;
  color: #fff;
  line-height: 20px;
}

.custom-radio input[type='radio'] {
  display: none;
}

.custom-radio input[type='radio']:checked + label span:before, .custom-radio .form-group input[type='radio']:checked + p.label span:before, .form-group .custom-radio input[type='radio']:checked + p.label span:before {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.custom-radio label span.circle:before, .custom-radio .form-group p.label span.circle:before, .form-group .custom-radio p.label span.circle:before {
  content: "";
  background: #fff;
  border: 4px solid #0674ec;
}

.custom-radio.custom_radio--big span.circle {
  height: 30px;
  width: 30px;
  border-width: 5px;
}

.custom-radio.custom_radio--big span.circle:before {
  width: 30px;
  height: 30px;
  font-size: 16px;
  line-height: 30px;
  border-width: 5px;
}

.radio-group .label {
  display: block;
  text-align: left;
}

.radio-group .custom-radio {
  display: inline-block;
}

.radio-group .custom-radio + .custom-radio {
  margin-left: 30px;
}

/* custom checkbox area css*/
.custom_checkbox {
  position: relative;
  margin-top: 10px;
}

.custom_checkbox input[type='checkbox'] {
  display: none;
}

.custom_checkbox input[type='checkbox']:checked + label .shadow_checkbox, .custom_checkbox .form-group input[type='checkbox']:checked + p.label .shadow_checkbox, .form-group .custom_checkbox input[type='checkbox']:checked + p.label .shadow_checkbox {
  border: transparent;
}

.custom_checkbox input[type='checkbox']:checked + label .shadow_checkbox:before, .custom_checkbox .form-group input[type='checkbox']:checked + p.label .shadow_checkbox:before, .form-group .custom_checkbox input[type='checkbox']:checked + p.label .shadow_checkbox:before {
  visibility: visible;
}

.custom_checkbox label, .custom_checkbox .form-group p.label, .form-group .custom_checkbox p.label {
  margin-bottom: 0;
}

.custom_checkbox label .label_text, .custom_checkbox .form-group p.label .label_text, .form-group .custom_checkbox p.label .label_text {
  font-weight: 400;
  color: #555;
  display: inline-block;
  vertical-align: middle;
  margin-left: 11px;
}

.custom_checkbox label .shadow_checkbox, .custom_checkbox .form-group p.label .shadow_checkbox, .form-group .custom_checkbox p.label .shadow_checkbox {
  height: 18px;
  width: 18px;
  top: 0;
  border: 1px solid #d3d3d3;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  border-radius: 2px;
}

.custom_checkbox label .shadow_checkbox:before, .custom_checkbox .form-group p.label .shadow_checkbox:before, .form-group .custom_checkbox p.label .shadow_checkbox:before {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  content: '\f00c';
  font-size: 12px;
  visibility: hidden;
  text-align: center;
  color: #fff;
  line-height: 18px;
  font-family: FontAwesome, sans-serif;
  background: #0674ec;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

/* custom upload field */
.custom_upload p {
  font-weight: 500;
  font-size: 16px;
  color: #000;
  margin-bottom: 12px;
}

.custom_upload p span {
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
  color: #555;
}

.custom_upload input[type='file'] {
  display: none;
}

/* cardify any area */
.cardify, .content_title, .table_module, .card_style1, .card_style2, .credit_modules, .shortcode_modules, .upload_modules, .withdraw_module, .statement_info_card, .statement_table, .thread_sort_area, .author_info, .comment_area, .contact_tile, .featured_event, .google_map .location_address .addres_module {
  background: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  -moz-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
}

/* common toggle module css */
.toggle_title {
  display: block;
  padding: 30px 30px 25px;
}

.toggle_title span.lnr {
  float: right;
  line-height: 20px;
  font-size: 14px;
}

.toggle_title h4 {
  font-size: 22px;
}

.social_icon {
  display: inline-block;
}

.social_icon span {
  display: inline-block;
  line-height: 45px;
  width: 45px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  text-align: center;
  border-radius: 50%;
  font-size: 16px;
  color: #fff;
}

.social_icon span.fa-facebook {
  background: #395799;
}

.social_icon span.fa-twitter {
  background: #19b2f5;
}

.social_icon span.fa-google-plus {
  background: #f05b44;
}

.social_icon span.fa-behance {
  background: #0057ff;
}

.social_icon span.fa-dribbble {
  background: #ea4c89;
}

.input-group .input-group-addon {
  background: none;
  border: none;
  font-size: 18px;
  color: #555;
  padding-left: 0;
}

.input-group.with--addon {
  align-items: center;
}

.input-group.with--addon .text_field {
  width: calc(100% - 25px);
  margin-left: 14px;
}

/* content area title */
.content_title {
  padding: 30px;
  border-radius: 4px;
  margin-bottom: 30px;
}

/* video modal css */
.video_modal .modal-content iframe {
  width: 100%;
  height: 450px;
}

.messages::-webkit-scrollbar-track {
  background-color: transparent;
}

.messages::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.messages::-webkit-scrollbar-thumb {
  background-color: #e9eef2;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.offcanvas-menu::-webkit-scrollbar-track {
  background-color: transparent;
}

.offcanvas-menu::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.offcanvas-menu::-webkit-scrollbar-thumb {
  background-color: #e9eef2;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

/*****************************
	-- THEME
*****************************/
/*****************************
	-- MIXINS
*****************************/
/* container sizes for various devices */
/* Custom scrollbar */
h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 18px;
}

/*****************************
	--- MENU
*****************************/
.top-menu-area .logo {
  height: 100%;
  display: flex;
  align-items: center;
}

/*menu style 1*/
.menu--style1 .top-menu-area {
  background: #fff;
}

.menu--style1 .mainmenu, .menu--style1 .mainmenu__menu {
  background: #0674ec;
}

.menu--style1 .mainmenu__menu ul li:hover a {
  color: #333;
}

.menu--style1 .mainmenu__menu ul li a {
  color: #fff;
}

.menu--style1 .searc-wrap input {
  background: rgba(255, 255, 255, 0.2);
  border: 0;
  color: #ffffff;
  font-weight: 400;
}

.menu--style1 .searc-wrap input::-webkit-input-placeholder {
  color: #fff;
}

.menu--style1 .searc-wrap input::-moz-placeholder {
  color: #fff;
}

.menu--style1 .searc-wrap input:-ms-input-placeholder {
  color: #fff;
}

.menu--style1 .searc-wrap .search-wrap__btn {
  background: #fff;
  color: #0674ec;
}

/* menu style 2 */
.menu--style2 .author-area__seller-btn, .menu--style2 .searc-wrap input,
.menu--style2 .searc-wrap .search-wrap__btn {
  border-radius: 4px;
}

.menu--style2 .searc-wrap .search-wrap__btn {
  width: 60px;
}

.menu--style3 .top-menu-area {
  background: #262b30;
}

.menu--style3 .top-menu-area .author__notification_area ul li span.lnr {
  color: #9ba9b7;
}

.menu--style3 .top-menu-area .autor__info .name {
  color: #fff;
}

.menu--style3 .top-menu-area .autor__info .ammount {
  color: #bbc4ce;
}

/*****************************
	-- PRODUCT SIDEBAR
*****************************/
/*****************************
	-- THEME
*****************************/
/*****************************
	-- MIXINS
*****************************/
/* container sizes for various devices */
/* Custom scrollbar */
.sidebar-card, .item-preview {
  background: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  -moz-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  margin-bottom: 30px;
}

.sidebar-card .card-title, .item-preview .card-title {
  padding: 30px;
  border-bottom: 1px solid #ececec;
}

.product--sidebar .sidebar-card, .support--sidebar .sidebar-card, .faq--sidebar .sidebar-card, .affliate_rule_module .sidebar-card, .sidebar--blog .sidebar-card, .product--sidebar .item-preview, .support--sidebar .item-preview, .faq--sidebar .item-preview, .affliate_rule_module .item-preview, .sidebar--blog .item-preview {
  background: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  -moz-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  margin-bottom: 30px;
}

.product--sidebar .sidebar-card .card-title, .support--sidebar .sidebar-card .card-title, .faq--sidebar .sidebar-card .card-title, .affliate_rule_module .sidebar-card .card-title, .sidebar--blog .sidebar-card .card-title, .product--sidebar .item-preview .card-title, .support--sidebar .item-preview .card-title, .faq--sidebar .item-preview .card-title, .affliate_rule_module .item-preview .card-title, .sidebar--blog .item-preview .card-title {
  padding: 30px 30px 28px;
  display: block;
  margin-bottom: 0;
}

.product--sidebar .sidebar-card .card-title .h4, .support--sidebar .sidebar-card .card-title .h4, .faq--sidebar .sidebar-card .card-title .h4, .affliate_rule_module .sidebar-card .card-title .h4, .sidebar--blog .sidebar-card .card-title .h4, .product--sidebar .item-preview .card-title .h4, .support--sidebar .item-preview .card-title .h4, .faq--sidebar .item-preview .card-title .h4, .affliate_rule_module .item-preview .card-title .h4, .sidebar--blog .item-preview .card-title .h4 {
  font-size: 20px;
}

.product--sidebar .sidebar-card .card-title span, .support--sidebar .sidebar-card .card-title span, .faq--sidebar .sidebar-card .card-title span, .affliate_rule_module .sidebar-card .card-title span, .sidebar--blog .sidebar-card .card-title span, .product--sidebar .item-preview .card-title span, .support--sidebar .item-preview .card-title span, .faq--sidebar .item-preview .card-title span, .affliate_rule_module .item-preview .card-title span, .sidebar--blog .item-preview .card-title span {
  float: right;
  font-size: 12px;
  line-height: 20px;
  color: #555;
}

.product--sidebar .sidebar-card .collapsible-content, .support--sidebar .sidebar-card .collapsible-content, .faq--sidebar .sidebar-card .collapsible-content, .affliate_rule_module .sidebar-card .collapsible-content, .sidebar--blog .sidebar-card .collapsible-content, .product--sidebar .item-preview .collapsible-content, .support--sidebar .item-preview .collapsible-content, .faq--sidebar .item-preview .collapsible-content, .affliate_rule_module .item-preview .collapsible-content, .sidebar--blog .item-preview .collapsible-content {
  padding: 0 30px;
}

.product--sidebar .sidebar-card .card-content, .support--sidebar .sidebar-card .card-content, .faq--sidebar .sidebar-card .card-content, .affliate_rule_module .sidebar-card .card-content, .sidebar--blog .sidebar-card .card-content, .product--sidebar .item-preview .card-content, .support--sidebar .item-preview .card-content, .faq--sidebar .item-preview .card-content, .affliate_rule_module .item-preview .card-content, .sidebar--blog .item-preview .card-content {
  padding: 20px 0;
}

.product--sidebar .card--category a, .support--sidebar .card--category a, .faq--sidebar .card--category a, .affliate_rule_module .card--category a, .support--sidebar .card--forum_categories a, .faq--sidebar .card--forum_categories a, .affliate_rule_module .card--forum_categories a, .sidebar--blog .card--forum_categories a, .sidebar--blog .card--category a {
  display: block;
}

.product--sidebar .card--category ul li a, .support--sidebar .card--category ul li a, .faq--sidebar .card--category ul li a, .affliate_rule_module .card--category ul li a, .support--sidebar .card--forum_categories ul li a, .faq--sidebar .card--forum_categories ul li a, .affliate_rule_module .card--forum_categories ul li a, .sidebar--blog .card--forum_categories ul li a, .sidebar--blog .card--category ul li a {
  display: block;
  line-height: 36px;
  transition: 0.3s ease;
  color: #555;
}

.product--sidebar .card--category ul li a:hover, .support--sidebar .card--category ul li a:hover, .faq--sidebar .card--category ul li a:hover, .affliate_rule_module .card--category ul li a:hover, .support--sidebar .card--forum_categories ul li a:hover, .faq--sidebar .card--forum_categories ul li a:hover, .affliate_rule_module .card--forum_categories ul li a:hover, .sidebar--blog .card--forum_categories ul li a:hover, .sidebar--blog .card--category ul li a:hover {
  color: #0674ec;
}

.product--sidebar .card--category ul li a span.lnr, .support--sidebar .card--category ul li a span.lnr, .faq--sidebar .card--category ul li a span.lnr, .affliate_rule_module .card--category ul li a span.lnr, .support--sidebar .card--forum_categories ul li a span.lnr, .faq--sidebar .card--forum_categories ul li a span.lnr, .affliate_rule_module .card--forum_categories ul li a span.lnr, .sidebar--blog .card--forum_categories ul li a span.lnr, .sidebar--blog .card--category ul li a span.lnr {
  font-size: 10px;
  margin-right: 10px;
}

.product--sidebar .card--category ul li a .item-count, .support--sidebar .card--category ul li a .item-count, .faq--sidebar .card--category ul li a .item-count, .affliate_rule_module .card--category ul li a .item-count, .support--sidebar .card--forum_categories ul li a .item-count, .faq--sidebar .card--forum_categories ul li a .item-count, .affliate_rule_module .card--forum_categories ul li a .item-count, .sidebar--blog .card--forum_categories ul li a .item-count, .sidebar--blog .card--category ul li a .item-count {
  float: right;
}

.product--sidebar .card--slider .card-content, .support--sidebar .card--slider .card-content, .faq--sidebar .card--slider .card-content, .affliate_rule_module .card--slider .card-content, .sidebar--blog .card--slider .card-content {
  padding: 30px 0;
}

.product--sidebar .card--slider .card-content .price-ranges, .support--sidebar .card--slider .card-content .price-ranges, .faq--sidebar .card--slider .card-content .price-ranges, .affliate_rule_module .card--slider .card-content .price-ranges, .sidebar--blog .card--slider .card-content .price-ranges {
  padding-top: 25px;
}

.product--sidebar .card--slider .card-content .price-ranges .from, .support--sidebar .card--slider .card-content .price-ranges .from, .faq--sidebar .card--slider .card-content .price-ranges .from, .affliate_rule_module .card--slider .card-content .price-ranges .from, .sidebar--blog .card--slider .card-content .price-ranges .from {
  margin-right: 30px;
}

/* SINGLE PRODUCT SIDEBAR */
.sidebar--single-product .sidebar-card, .sidebar--single-product .item-preview {
  padding: 30px;
}

.sidebar--single-product .sidebar-card.card-pricing .price, .sidebar--single-product .card-pricing.item-preview .price {
  padding: 30px 0;
  text-align: center;
  margin-bottom: 20px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.sidebar--single-product .sidebar-card.card-pricing .price h1, .sidebar--single-product .card-pricing.item-preview .price h1 {
  font-weight: 500;
  font-size: 36px;
  line-height: 30px;
  color: #0674ec;
}

.sidebar--single-product .sidebar-card.card-pricing .price h1 sup, .sidebar--single-product .card-pricing.item-preview .price h1 sup {
  font-size: 18px;
}

.sidebar--single-product .sidebar-card.card-pricing .pricing-options, .sidebar--single-product .card-pricing.item-preview .pricing-options {
  margin-bottom: 25px;
}

.sidebar--single-product .sidebar-card.card-pricing .pricing-options li, .sidebar--single-product .card-pricing.item-preview .pricing-options li {
  padding: 10px 0;
}

.sidebar--single-product .sidebar-card.card-pricing .pricing-options li + li, .sidebar--single-product .card-pricing.item-preview .pricing-options li + li {
  border-top: 1px solid #ececec;
}

.sidebar--single-product .sidebar-card.card-pricing .pricing-options .custom-radio label, .sidebar--single-product .card-pricing.item-preview .pricing-options .custom-radio label, .sidebar--single-product .sidebar-card.card-pricing .pricing-options .custom-radio .form-group p.label, .form-group .sidebar--single-product .sidebar-card.card-pricing .pricing-options .custom-radio p.label, .sidebar--single-product .card-pricing.item-preview .pricing-options .custom-radio .form-group p.label, .form-group .sidebar--single-product .card-pricing.item-preview .pricing-options .custom-radio p.label {
  font-size: 16px;
}

.sidebar--single-product .sidebar-card.card-pricing .pricing-options .custom-radio label .pricing__opt, .sidebar--single-product .card-pricing.item-preview .pricing-options .custom-radio label .pricing__opt, .sidebar--single-product .sidebar-card.card-pricing .pricing-options .custom-radio .form-group p.label .pricing__opt, .form-group .sidebar--single-product .sidebar-card.card-pricing .pricing-options .custom-radio p.label .pricing__opt, .sidebar--single-product .card-pricing.item-preview .pricing-options .custom-radio .form-group p.label .pricing__opt, .form-group .sidebar--single-product .card-pricing.item-preview .pricing-options .custom-radio p.label .pricing__opt {
  color: #333333;
  font-weight: 500;
}

.sidebar--single-product .sidebar-card.card-pricing .purchase-button .btn, .sidebar--single-product .card-pricing.item-preview .purchase-button .btn {
  width: 100%;
  font-weight: 500;
}

.sidebar--single-product .sidebar-card.card-pricing .purchase-button .btn + .btn, .sidebar--single-product .card-pricing.item-preview .purchase-button .btn + .btn {
  margin-top: 30px;
}

.sidebar--single-product .sidebar-card.card--pricing2, .sidebar--single-product .card--pricing2.item-preview {
  padding: 0;
}

.sidebar--single-product .sidebar-card.card--pricing2 .price, .sidebar--single-product .card--pricing2.item-preview .price {
  background: #0674ec;
  border-radius: 4px 4px 0 0;
}

.sidebar--single-product .sidebar-card.card--pricing2 .price h1, .sidebar--single-product .card--pricing2.item-preview .price h1 {
  color: #fff;
}

.sidebar--single-product .sidebar-card.card--pricing2 .pricing-options, .sidebar--single-product .card--pricing2.item-preview .pricing-options {
  padding: 0 30px;
}

.sidebar--single-product .sidebar-card.card--pricing2 .pricing-options li p, .sidebar--single-product .card--pricing2.item-preview .pricing-options li p {
  padding-top: 18px;
  padding-left: 35px;
}

.sidebar--single-product .sidebar-card.card--pricing2 .purchase-button, .sidebar--single-product .card--pricing2.item-preview .purchase-button {
  padding: 0  30px 30px;
}

.sidebar--single-product .card-title {
  padding: 30px;
  border-bottom: 1px solid #ececec;
}

.sidebar--single-product .card--metadata ul.data > li {
  padding: 22px 0 22px;
  border-bottom: 1px solid #ececec;
  overflow: hidden;
  line-height: 30px;
}

.sidebar--single-product .card--metadata ul.data > li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.sidebar--single-product .card--metadata ul.data > li p {
  float: left;
  font-size: 16px;
  line-height: inherit;
  margin-bottom: 0;
}

.sidebar--single-product .card--metadata ul.data > li p span {
  margin-right: 8px;
}

.sidebar--single-product .card--metadata ul.data > li > span {
  float: right;
  font-weight: 500;
  font-size: 18px;
  color: #333;
}

.sidebar--single-product .card--metadata .rating {
  text-align: center;
  padding-top: 28px;
}

.sidebar--single-product .card--product-infos {
  padding: 0;
}

.sidebar--single-product .card--product-infos ul.infos {
  padding: 19px 30px;
}

.sidebar--single-product .card--product-infos ul.infos li {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

.sidebar--single-product .card--product-infos ul.infos li p {
  display: inline-block;
  vertical-align: middle;
  margin-right: -3px;
  margin: 0;
  font-size: 15px;
  vertical-align: top;
  line-height: 36px;
}

.sidebar--single-product .card--product-infos ul.infos li p.data-label {
  width: 40%;
  font-weight: 500;
  color: #333333;
}

.sidebar--single-product .card--product-infos ul.infos li p.data-label:after {
  content: ':';
  margin-left: 2px;
}

.sidebar--single-product .card--product-infos ul.infos li p.info {
  width: 60%;
}

/* SUPPORT PAGE SIDEBAR */
.support--sidebar .login_promot, .faq--sidebar .login_promot, .affliate_rule_module .login_promot, .sidebar--blog .login_promot {
  background: #0674ec;
  color: #fff;
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 20px;
  border-radius: 4px;
  padding: 25px 30px;
  display: block;
}

.support--sidebar .login_promot span, .faq--sidebar .login_promot span, .affliate_rule_module .login_promot span, .sidebar--blog .login_promot span {
  margin-right: 10px;
}

.support--sidebar .card--top_discussion .card-content li, .faq--sidebar .card--top_discussion .card-content li, .affliate_rule_module .card--top_discussion .card-content li, .sidebar--blog .card--top_discussion .card-content li, .faq--sidebar .faq--card .card-content li, .affliate_rule_module .faq--card .card-content li {
  position: relative;
  padding-left: 23px;
  margin-bottom: 10px;
}

.support--sidebar .card--top_discussion .card-content li:before, .faq--sidebar .card--top_discussion .card-content li:before, .affliate_rule_module .card--top_discussion .card-content li:before, .sidebar--blog .card--top_discussion .card-content li:before, .faq--sidebar .faq--card .card-content li:before, .affliate_rule_module .faq--card .card-content li:before {
  position: absolute;
  content: '';
  height: 7px;
  width: 7px;
  -webkit-border-radius: 200px;
  -moz-border-radius: 200px;
  border-radius: 200px;
  background: #c5cad4;
  vertical-align: middle;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.support--sidebar .card--top_discussion .card-content li a, .faq--sidebar .card--top_discussion .card-content li a, .affliate_rule_module .card--top_discussion .card-content li a, .sidebar--blog .card--top_discussion .card-content li a, .faq--sidebar .faq--card .card-content li a, .affliate_rule_module .faq--card .card-content li a {
  color: #333333;
  line-height: 28px;
}

.support--sidebar .card--top_discussion .card-content li a:hover, .faq--sidebar .card--top_discussion .card-content li a:hover, .affliate_rule_module .card--top_discussion .card-content li a:hover, .sidebar--blog .card--top_discussion .card-content li a:hover, .faq--sidebar .faq--card .card-content li a:hover, .affliate_rule_module .faq--card .card-content li a:hover {
  color: #0674ec;
}

/* FAQ SIDEBAR */
.faq--sidebar .faq--card .card-content li:before, .affliate_rule_module .faq--card .card-content li:before {
  transform: translateY(0);
  top: 13px;
}

.faq--sidebar .faq--card .card-content a, .affliate_rule_module .faq--card .card-content a {
  font-size: 16px;
}

.faq--sidebar .faq--card .btn--faq, .affliate_rule_module .faq--card .btn--faq {
  text-align: center;
  margin-bottom: 38px;
}

.author-card .card-title {
  padding: 30px;
}

.author-card.sidebar-card, .author-card.item-preview {
  padding: 0;
}

.author-card .author-infos {
  padding: 30px 0;
  text-align: center;
}

.author-card .author-infos .author_avatar {
  width: 100px;
  height: 100px;
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
}

.author-card .author-infos .author {
  padding-top: 15px;
}

.author-card .author-infos .author h4 {
  font-weight: 500;
  line-height: 30px;
  color: #000000;
}

.author-card .author-infos .author p {
  line-height: 30px;
  padding-top: 6px;
  margin: 0;
}

.author-card .author-infos .author-badges {
  margin-top: 25px;
  padding: 0 30px 20px 30px;
  border-bottom: 1px solid #ececec;
}

.author-card .author-infos .author-badges ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.author-card .author-infos .author-badges ul li {
  margin: 0 0px 10px 0px;
}

.author-card .author-infos .author-badges ul li img {
  width: 50px;
  height: 50px;
  display: block;
}

.author-card .author-infos .social, .author-card .author-infos .single_blog_content .share_tags .share .social_share, .single_blog_content .share_tags .share .author-card .author-infos .social_share {
  margin-top: 30px;
  margin-bottom: 25px;
}

.author-card .author-infos .author-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.author-card .author-infos .author-btn .btn {
  width: 140px;
  margin: 5px;
}

.author-card .author-infos .author-btn .btn:hover {
  background: #7347c1;
  color: #fff;
}

/* upload page sidebar */
.upload_sidebar .card_content {
  padding: 30px 30px 26px;
}

.upload_sidebar .card_content p {
  line-height: 30px;
  margin-bottom: 0;
}

.upload_sidebar .card_content ul {
  padding-left: 19px;
  padding-top: 20px;
}

.upload_sidebar .card_content ul li {
  list-style-type: disc;
  line-height: 30px;
  font-size: 16px;
}

.upload_sidebar .card_content ul li + li {
  margin-top: 13px;
}

.upload_sidebar .card_info h4 {
  color: #000;
  font-weight: 500;
  padding-bottom: 20px;
}

.upload_sidebar .card_info + .card_info {
  margin-top: 30px;
}

/* CARD BLOG SIDEBAR */
.sidebar--blog .card--blog_sidebar .card-title h4 {
  font-size: 20px;
}

.sidebar--blog .card--blog_sidebar .card_content {
  padding: 0 25px;
}

.sidebar--blog .card--search .searc-wrap input {
  width: 100%;
}

.sidebar--blog .card--search .card_content {
  padding: 30px;
}

.sidebar--blog .sidebar--post .card-title ul {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.sidebar--blog .sidebar--post .card-title ul li + li {
  padding-left: 0px;
}

.sidebar--blog .sidebar--post .card-title ul li a {
  font-size: 20px;
  color: #000;
}

.sidebar--blog .sidebar--post .card-title ul li a.active {
  color: #0674ec;
}

.sidebar--blog .sidebar--post .post-list li {
  padding: 25px 0;
}

.sidebar--blog .sidebar--post .post-list li + li {
  border-top: 1px solid #ececec;
}

.sidebar--blog .sidebar--post .post-list .thumbnail_img {
  width: 71px;
  display: inline-block;
  height: 71px;
  vertical-align: middle;
}

.sidebar--blog .sidebar--post .post-list .thumbnail_img img {
  width: 100%;
}

.sidebar--blog .sidebar--post .post-list .title_area {
  width: calc(100% - 76px);
  display: inline-block;
  vertical-align: top;
  padding-left: 10px;
}

.sidebar--blog .sidebar--post .post-list .title_area a {
  display: inline-block;
  font-weight: 500;
  margin-bottom: 6px;
}

.sidebar--blog .sidebar--post .post-list .title_area a h4 {
  font-size: 16px;
  line-height: 24px;
  color: #333;
  font-weight: 500;
}

.sidebar--blog .sidebar--post .post-list .title_area .date_time span {
  display: inline-block;
}

.sidebar--blog .sidebar--post .post-list .title_area .date_time p {
  margin: 0;
  display: inline-block;
  color: #898f96;
  font-size: 15px;
}

.sidebar--blog .card--tags .tags {
  padding: 30px 20px;
}

.sidebar--blog .card--tags .tags li {
  display: inline-block;
  margin: 0 7px 12px;
}

.sidebar--blog .card--tags .tags li a {
  color: #0674ec;
  background: rgba(6, 116, 236, 0.07);
  line-height: 30px;
  display: inline-block;
  padding: 0 15px;
  border-radius: 200px;
}

.banner {
  position: relative;
}

.banner img {
  border-radius: 4px;
}

.banner:before {
  content: '';
  background: linear-gradient(to left, #7347c1, #0674ec);
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  opacity: 0.8;
}

.banner .banner_content {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
}

.banner .banner_content h1 {
  color: #f9f9f9;
}

.banner .banner_content p {
  margin: 0;
  color: #f9f9f9;
}

/*****************************
	---BUTTON
*****************************/
/*****************************
	-- THEME
*****************************/
.btn--round {
  border-radius: 100px;
}

.btn {
  background: #0674ec;
  padding: 0;
  margin: 0;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  transition: 0.3s ease;
  border: none;
  position: relative;
}

.btn:before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  background: #000;
  left: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  top: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 0;
  border-radius: 4px;
}

.btn:focus, .btn:active {
  color: #fff;
  outline: 0;
}

.btn:hover {
  color: #fff;
}

.btn:hover:before {
  opacity: 0.07;
  visibility: visible;
}

.btn.btn--round:hover:before {
  border-radius: 200px;
}

.btn--bordered,
.btn--bordered:hover, .btn--bordered:focus, .btn--bordered:active {
  background: none;
  border: 1px solid #0674ec;
  color: #0674ec;
  box-shadow: none;
}

.btn.btn--white, .btn-light, .btn--light,
.btn.btn--white:hover, .btn-light:hover, .btn--light:hover {
  background: #fff;
  color: #0674ec;
}

.btn--default {
  line-height: 50px;
  min-width: 170px;
}

.btn-default {
  line-height: 50px;
  min-width: 170px;
}

.btn--lg, .btn-lg {
  line-height: 55px;
  font-size: 16px;
  min-width: 195px;
}

.btn--md, .btn-md {
  line-height: 45px;
  padding: 0 22px;
  min-width: 150px;
}

.btn--xs {
  line-height: 36px;
  padding: 0 19px;
  font-size: 14px;
}

.btn--fullwidth {
  width: 100%;
}

.btn--sm, .btn-sm {
  line-height: 40px;
  padding: 0 22px;
}

.btn--icon span {
  margin-right: 5px;
  line-height: inherit;
  display: inline-block;
}

.btn--faq {
  min-width: 140px;
}

.search-btn {
  background: #0674ec;
  color: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  min-width: 223px;
  font-size: 16px;
  border: 0;
}

/* btn colors */
.btn.btn--color1, .btn-secondary, .btn--secondary,
.btn.btn--color1:hover, .btn-secondary:hover, .btn--secondary:hover {
  background: #7347c1;
}

.btn.btn--color1.btn--bordered, .btn-secondary.btn--bordered, .btn--secondary.btn--bordered,
.btn.btn--color1:hover.btn--bordered, .btn-secondary:hover.btn--bordered, .btn--secondary:hover.btn--bordered {
  background: none;
  border: 1px solid #7347c1;
  color: #7347c1;
}

.btn.btn--color2, .btn-success, .btn--success,
.btn.btn--color2:hover, .btn-success:hover, .btn--success:hover {
  background: #77de51;
}

.btn.btn--color2.btn--bordered, .btn-success.btn--bordered, .btn--success.btn--bordered,
.btn.btn--color2:hover.btn--bordered, .btn-success:hover.btn--bordered, .btn--success:hover.btn--bordered {
  background: none;
  border: 1px solid #77de51;
  color: #77de51;
}

.btn.btn--color3, .btn-warning, .btn--warning,
.btn.btn--color3:hover, .btn-warning:hover, .btn--warning:hover {
  background: #feb71d;
}

.btn.btn--color3.btn--bordered, .btn-warning.btn--bordered, .btn--warning.btn--bordered,
.btn.btn--color3:hover.btn--bordered, .btn-warning:hover.btn--bordered, .btn--warning:hover.btn--bordered {
  background: none;
  border: 1px solid #feb71d;
  color: #feb71d;
}

.btn.btn--color4, .btn-info, .btn--info,
.btn.btn--color4:hover, .btn-info:hover, .btn--info:hover {
  background: #58c9e9;
}

.btn.btn--color4.btn--bordered, .btn-info.btn--bordered, .btn--info.btn--bordered,
.btn.btn--color4:hover.btn--bordered, .btn-info:hover.btn--bordered, .btn--info:hover.btn--bordered {
  background: none;
  border: 1px solid #58c9e9;
  color: #58c9e9;
}

.btn.btn--color5, .btn--danger, .btn-danger,
.btn.btn--color5:hover, .btn--danger:hover, .btn-danger:hover {
  background: #ff6a6d;
}

.btn.btn--color5.btn--bordered, .btn--danger.btn--bordered, .btn-danger.btn--bordered,
.btn.btn--color5:hover.btn--bordered, .btn--danger:hover.btn--bordered, .btn-danger:hover.btn--bordered {
  background: none;
  border: 1px solid #ff6a6d;
  color: #ff6a6d;
}

.btn.btn-dark, .btn--dark,
.btn.btn-dark:hover, .btn--dark:hover {
  background: #000;
  color: #fff;
}

.btn.btn-dark.btn--bordered, .btn--dark.btn--bordered,
.btn.btn-dark:hover.btn--bordered, .btn--dark:hover.btn--bordered {
  background: none;
  border: 1px solid #000;
  color: #000;
}

/*****************************
	-- THEME
*****************************/
/*****************************
	--- PRODUCTS
*****************************/
/* GENERAL PRODUCT STYLE */
.products {
  padding: 80px 0;
}

.product {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  margin-bottom: 30px;
}

.product .product__thumbnail {
  position: relative;
}

.product .product__thumbnail img {
  border-radius: 4px 4px 0 0;
  width: 100%;
}

.product .product__thumbnail:before {
  position: absolute;
  content: "";
  transition: 0.3s ease;
  height: 100%;
  opacity: 0;
  z-index: 2;
  border-radius: 4px 4px 0 0;
  width: 100%;
  top: 0;
  background-image: linear-gradient(to left, #7347c1 0%, #0674ec 100%);
  /* Fallback (could use .jpg/.png alternatively) */
  background-color: #7347c1;
  /* SVG fallback for IE 9 (could be data URI, or could use filter) */
  background: #0674ec;
  /* Safari 4, Chrome 1-9, iOS 3.2-4.3, Android 2.1-3.0 */
  background-image: -webkit-gradient(linear, left top, right top, from(#7347c1), to(#0674ec));
  /* Safari 5.1, iOS 5.0-6.1, Chrome 10-25, Android 4.0-4.3 */
  background-image: -webkit-linear-gradient(left, #7347c1, #0674ec);
  /* Firefox 3.6 - 15 */
  background-image: -moz-linear-gradient(left, #7347c1, #0674ec);
  /* Opera 11.1 - 12 */
  background-image: -o-linear-gradient(left, #7347c1, #0674ec);
  /* Opera 15+, Chrome 25+, IE 10+, Firefox 16+, Safari 6.1+, iOS 7+, Android 4.4+ */
  background-image: linear-gradient(to right, #7347c1, #0674ec);
  left: 0;
}

.product .prod_btn {
  position: absolute;
  top: calc(50% + 40px);
  opacity: 0;
  transform: translateY(-50%);
  z-index: 99;
  width: 100%;
  text-align: center;
  transition: 0.3s ease;
}

.product .prod_btn a {
  display: inline-block;
  color: #fff;
  border: 1px solid #fff;
  font-weight: 500;
  padding: 0 30px;
}

.product .prod_btn a:hover {
  color: #0674ec;
  transition: 0.3s ease;
  background: #fff;
}

.product .prod_btn a + a {
  margin-left: 6px;
}

.product:hover .prod_btn {
  opacity: 1;
  top: 50%;
}

.product:hover .product__thumbnail:before {
  opacity: 0.9;
}

.product-desc {
  padding: 30px;
}

.product-desc p {
  margin: 0;
}

.product-desc ul.titlebtm {
  padding-bottom: 23px;
}

.product-desc ul.titlebtm > li {
  display: inline-block;
  margin-right: 16px;
}

.product-desc ul.titlebtm > li .by {
  margin-right: 6px;
}

.product-desc ul.titlebtm > li img, .product-desc ul.titlebtm > li p {
  display: inline-block;
  margin: 0;
}

.product-desc ul.titlebtm > li a {
  color: #000000;
  font-size: 15px;
}

.product-desc ul.titlebtm > li a:hover {
  color: #0674ec;
}

.product-desc ul.titlebtm > li a, .product-desc ul.titlebtm > li p {
  font-size: 15px;
}

.product-desc ul.titlebtm > li.product_cat {
  margin-right: 0;
}

.product-desc ul.titlebtm .rating {
  float: right;
  margin-right: 0;
}

.product-desc ul.titlebtm .auth-img {
  margin-right: 6px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.product-desc .product_title {
  margin-bottom: 15px;
  display: inline-block;
}

.product-desc .product_title:hover h4 {
  color: #0674ec;
}

.product-desc .product_title h4 {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 500;
  transition: 0.3s ease;
}

.product-desc ul li.product_cat a {
  color: #7347c1;
}

.product-desc ul li.product_cat a img {
  margin-right: 4px;
  width: 15px;
  vertical-align: middle;
  height: auto;
}

.product-desc ul li.product_cat a span {
  margin-right: 5px;
}

.product-purchase {
  border-top: 1px solid #ececec;
  padding: 15px 30px;
  overflow: hidden;
}

.product-purchase .rating {
  margin-left: 6px;
}

.product-purchase p {
  margin: 0;
  font-size: 15px;
  display: inline;
  color: #555;
}

.product-purchase .price_love {
  display: inline-block;
  vertical-align: middle;
}

.product-purchase .price_love > span {
  background: rgba(6, 116, 236, 0.1);
  line-height: 32px;
  display: inline-block;
  padding: 0 15px;
  color: #0674ec;
  margin-right: 10px;
  font-size: 15px;
  font-weight: 500;
  border-radius: 100px;
}

.product-purchase .price_love p span {
  color: #7347c1;
  font-size: 15px;
}

.product-purchase .sell {
  float: right;
  display: inline-block;
}

.product-purchase .sell p span {
  color: #0674ec;
  font-size: 15px;
  margin-right: 4px;
}

.product-purchase .sell p span.lnr {
  margin-right: 7px;
}

/*-------- PRODUCT MODIFIERS --------------*/
.product--card .product-desc {
  height: 222px;
  overflow: hidden;
  padding-bottom: 0;
}

.product--card .product-purchase {
  margin-top: 10px;
}

.product--card2 .product__thumbnail img {
  border-radius: 4px 4px 0 0;
}

.product--card2 .product-desc {
  padding-bottom: 15px;
  height: 176px;
}

.product--card2 ul.titlebtm {
  overflow: hidden;
  padding: 0px 30px 10px;
}

.product--card2 ul.titlebtm .product_cat {
  float: left;
}

.product--card2 ul.titlebtm .rating {
  float: right;
}

.product--card3 .product-desc ul.titlebtm {
  padding-bottom: 0;
}

.product--card3 .product-desc {
  height: 130px;
  padding: 30px 30px 20px;
}

.product--card-small .product__thumbnail img {
  width: 100%;
  border-radius: 4px 4px 0 0;
}

.product--card-small .product__thumbnail .prod_btn a {
  line-height: 35px;
  padding: 0 20px;
}

.product--card-small .product-desc {
  height: auto;
  padding: 20px 20px 15px;
}

.product--card-small .product-desc ul.titlebtm {
  padding-bottom: 0;
}

.product--card-small .product-desc .product_title h4 {
  font-size: 18px;
  line-height: 22px;
}

.product--card-small .product-purchase {
  padding: 15px 20px;
}

.product--card-small .product-purchase a {
  float: right;
  line-height: 30px;
  color: #7347c1;
}

.product--card-small ul.titlebtm > li {
  margin-right: 0;
  display: block;
  overflow: hidden;
}

.product--card-small ul.titlebtm > li .sell {
  float: right;
}

.product--card-small ul.titlebtm > li .sell span {
  color: #0674ec;
}

.product--card-small ul.titlebtm > li .sell span.lnr {
  margin-right: 6px;
}

.product--card-small ul.titlebtm > li .rating {
  float: left;
}

.product--card-small ul.titlebtm .out_of_class_name {
  margin-top: 17px;
}

.product--list {
  overflow: hidden;
  height: 225px;
}

.product--list .product__thumbnail, .product--list .product-desc, .product--list .product-meta, .product--list .product-purchase {
  display: inline-block;
  vertical-align: middle;
  margin-right: -3px;
  border-right: 1px solid #ececec;
}

.product--list .product__thumbnail {
  width: 293px;
  float: left;
}

.product--list .product__thumbnail img {
  width: 100%;
  border-radius: 4px 0 0 4px;
}

.product--list .product__thumbnail .prod_btn .prod_btn__wrap + .prod_btn__wrap {
  margin-top: 15px;
}

.product--list .product__thumbnail .prod_btn .btn--sm {
  min-width: 140px;
}

.product--list .product__details {
  overflow: hidden;
  padding: 24px 0;
}

.product--list .product__details .product-desc, .product--list .product__details .product-meta {
  padding: 0 30px;
}

.product--list .product-desc {
  width: 353px;
}

.product--list .product-desc .titlebtm {
  padding-bottom: 0;
  padding-top: 24px;
}

.product--list .product-purchase {
  border: 0;
  width: 155px;
  text-align: center;
}

.product--list .product-purchase .price_love {
  float: none;
  margin-right: 0;
  padding-bottom: 25px;
}

.product--list .product-purchase .price_love span {
  margin: 0;
}

.product--list .product-purchase .sell {
  float: none;
  display: block;
}

.product--list-small {
  height: 209px;
}

.product--list-small .product__thumbnail {
  width: 230px;
}

.product--list-small .prod_btn a {
  line-height: 35px;
  margin-left: 0;
}

.product--list-small .prod_btn a + a {
  margin-top: 15px;
}

.product--list-small .product__details .product-desc,
.product--list-small .product__details .product-meta {
  padding: 0 20px;
}

.product--list-small .product__details .product-desc {
  width: 255px;
}

.product--list-small .product__details .product-desc .titlebtm {
  padding-top: 18px;
}

.product--list-small .product__details .product-meta {
  width: 180px;
}

.product--list-small .product__details .product-meta .love-comments p + p {
  margin-left: 0;
}

.product--list-small .product__details .product-purchase {
  width: 150px;
  padding: 0;
}

.product-meta {
  width: 310px;
}

.product-meta .author {
  margin-bottom: 25px;
}

.product-meta .author .auth-img {
  display: inline-block;
  border-radius: 50%;
  height: 28px;
  width: 28px;
}

.product-meta .author p {
  display: inline-block;
  margin: 0;
}

.product-meta .author p .by {
  margin-right: 6px;
}

.product-meta .author p a {
  color: #000;
}

.product-meta .love-comments p {
  display: inline-block;
}

.product-meta .love-comments p span.lnr {
  color: #7347c1;
}

.product-meta .love-comments p + p {
  margin-left: 20px;
}

.product-meta .product-tags {
  margin-bottom: 14px;
  display: flex;
  flex-wrap: wrap;
}

.product-meta .product-tags span {
  color: #000;
  font-size: 15px;
  padding-right: 10px;
}

.product-meta .product-tags ul {
  width: calc(100% - 45px);
  flex: 1;
}

.product-meta .product-tags ul li {
  display: inline-block;
}

.product-meta .product-tags ul li:after {
  content: ',';
}

.product-meta .product-tags ul li:last-child:after {
  display: none;
}

/*****************************
	-- SOCIAL
*****************************/
.social, .single_blog_content .share_tags .share .social_share {
  display: inline-block;
}

.social ul li, .single_blog_content .share_tags .share .social_share ul li {
  display: inline-block;
  margin-bottom: 10px;
}

.social ul li a, .single_blog_content .share_tags .share .social_share ul li a {
  display: inline-block;
}

.social ul li a span, .single_blog_content .share_tags .share .social_share ul li a span {
  line-height: 40px;
  width: 40px;
  text-align: center;
}

.social ul li + li, .single_blog_content .share_tags .share .social_share ul li + li {
  margin-left: 8px;
}

.social--color--filled ul li a, .single_blog_content .share_tags .share .social_share ul li a {
  color: #fff;
}

.social--color--filled span, .single_blog_content .share_tags .share .social_share span {
  border-radius: 50%;
}

.social--color--filled span.fa-facebook, .single_blog_content .share_tags .share .social_share span.fa-facebook {
  background: #395799;
}

.social--color--filled span.fa-twitter, .single_blog_content .share_tags .share .social_share span.fa-twitter {
  background: #19b2f5;
}

.social--color--filled span.fa-google-plus, .single_blog_content .share_tags .share .social_share span.fa-google-plus {
  background: #f05b44;
}

.social--color--filled span.fa-pinterest, .single_blog_content .share_tags .share .social_share span.fa-pinterest {
  background: #9c0c1c;
}

.social--color--filled span.fa-linkedin, .single_blog_content .share_tags .share .social_share span.fa-linkedin {
  background: #007bb5;
}

.social--color--filled span.fa-dribbble, .single_blog_content .share_tags .share .social_share span.fa-dribbble {
  background: #ea4c89;
}

.social--color--filled span.fa-instagram, .single_blog_content .share_tags .share .social_share span.fa-instagram {
  background: #bc2a8d;
}

.social--color--filled span.fa-vine, .single_blog_content .share_tags .share .social_share span.fa-vine {
  background: #1ab7ea;
}

.social--color--filled span.fa-flickr, .single_blog_content .share_tags .share .social_share span.fa-flickr {
  background: #ff0084;
}

.social--color--filled span.fa-skype, .single_blog_content .share_tags .share .social_share span.fa-skype {
  background: #1ab7ea;
}

.social--color--filled span.fa-tumblr, .single_blog_content .share_tags .share .social_share span.fa-tumblr {
  background: #2c4762;
}

.social--color--filled span.fa-youtube, .single_blog_content .share_tags .share .social_share span.fa-youtube {
  background: #dc0101;
}

/*****************************
	--- PAGINATION
*****************************/
.pagination-area {
  text-align: center;
}

.pagination-area.categorised_item_pagination {
  text-align: right;
}

.pagination-area .page-numbers {
  line-height: 36px;
  display: inline-block;
  min-width: 48px;
  transition: 0.3s ease;
  background: #fff;
  border-radius: 25px;
  font-weight: 500;
  margin-right: 2px;
  color: #747b86;
  text-align: center;
}

.pagination-area .page-numbers:hover, .pagination-area .page-numbers.current {
  background: #0674ec;
  color: #fff;
}

.pagination-area .prev {
  margin-right: 15px;
}

.pagination-area .next {
  margin-left: 15px;
}

.pagination-area .prev.page-numbers, .pagination-area .next.page-numbers {
  color: #000;
  font-size: 16px;
}

.pagination-area .prev.page-numbers:hover, .pagination-area .next.page-numbers:hover {
  color: #fff;
  background: #7347c1;
}

.pagination-area.pagination-area2 {
  padding: 38px 30px;
}

.pagination-area.pagination-area2.text-right {
  text-align: right;
}

.pagination-area.pagination-area2 .page-numbers {
  background: #eff1f5;
  text-align: center;
  color: #747b86;
}

.pagination-area.pagination-area2 .page-numbers:hover {
  background: #0674ec;
  color: #fff;
}

.pagination-area.pagination-area2 .page-numbers.prev:hover, .pagination-area.pagination-area2 .page-numbers.next:hover {
  background: #7347c1;
}

.pagination-area.pagination-area2 .pagination {
  margin: 0;
}

.pagination-area.pagination--right {
  text-align: right;
}

.pagination-area.pagination--right .page-numbers {
  text-align: center;
}

.pagination-area .pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0 0 0;
  border-radius: 4px;
}

/*****************************
	--- BREADCRUMB
*****************************/
.breadcrumb-area {
  padding-top: 52px !important;
  padding-bottom: 50px;
}

.breadcrumb-area .breadcrumb ul li a {
  line-height: initial;
}

.breadcrumb-area .page-title {
  color: #fff;
  font-weight: 500;
  font-size: 30px;
  text-transform: capitalize;
}

.breadcrumb-area .breadcrumb {
  padding-bottom: 17px;
}

.breadcrumb-area .breadcrumb ul {
  width: 100%;
}

.breadcrumb-area.breadcrumb--center {
  text-align: center;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 27px;
}

.breadcrumb-area.breadcrumb--center .page_title {
  padding-top: 30px;
  padding-bottom: 47px;
}

.breadcrumb-area.breadcrumb--center .page_title h3 {
  color: #fff;
  font-weight: 500;
  font-size: 30px;
  line-height: 50px;
}

.breadcrumb-area.breadcrumb--center .page_title p {
  color: #f3f3f8;
  font-size: 30px;
  line-height: 50px;
  font-weight: 300;
}

.breadcrumb-area.breadcrumb--center .page_title p.subtitle {
  font-size: 20px;
}

.breadcrumb-area.breadcrumb--center .breadcrumb {
  padding-bottom: 0;
}

.breadcrumb {
  padding: 0;
  background: none;
  margin-bottom: 0;
}

.breadcrumb ul li {
  display: inline-block;
}

.breadcrumb ul li:after {
  content: '/';
  color: #fff;
  padding: 0 7px;
}

.breadcrumb ul li a {
  font-size: 15px;
  line-height: 70px;
  color: #fff;
}

.breadcrumb ul li:last-child:after {
  display: none;
}

.breadcrumb ul li.active a {
  color: #bfcad7;
}

.breadcrumb ul {
  width: 100%;
}

/*****************************
	--- ACCORDION
*****************************/
/*****************************
	-- THEME
*****************************/
.accordion .accordion__single, .accordion .panel-default, .panel-group .accordion__single, .panel-group .panel-default {
  border: 1px solid #ececec;
  border-radius: 0;
}

.accordion .accordion__single + .accordion__single, .accordion .panel-default + .accordion__single, .panel-group .accordion__single + .accordion__single, .panel-group .panel-default + .accordion__single {
  margin-top: 20px;
}

.accordion .accordion__single + .panel-default, .accordion .panel-default + .panel-default, .panel-group .accordion__single + .panel-default, .panel-group .panel-default + .panel-default {
  margin-top: 20px;
}

.accordion .accordion__single .panel-heading, .accordion .panel-default .panel-heading, .panel-group .accordion__single .panel-heading, .panel-group .panel-default .panel-heading {
  background: none;
  padding: 0;
}

.accordion .accordion__single .panel-body, .accordion .panel-default .panel-body, .panel-group .accordion__single .panel-body, .panel-group .panel-default .panel-body {
  font-size: 16px;
}

.accordion .accordion__single .panel-body p, .accordion .panel-default .panel-body p, .panel-group .accordion__single .panel-body p, .panel-group .panel-default .panel-body p {
  margin: 0;
  padding: 15px;
}

.accordion .accordion__single h4, .accordion .panel-default h4, .panel-group .accordion__single h4, .panel-group .panel-default h4 {
  font-size: 18px;
}

.accordion .accordion__single h4 a, .accordion .panel-default h4 a, .panel-group .accordion__single h4 a, .panel-group .panel-default h4 a {
  line-height: 26px;
  display: block;
  background: #eff1f5;
  color: #333;
  font-weight: 400;
  padding: 15px 27px;
}

.accordion .accordion__single h4 a i.lnr, .accordion .panel-default h4 a i.lnr, .panel-group .accordion__single h4 a i.lnr, .panel-group .panel-default h4 a i.lnr {
  float: right;
  line-height: inherit;
}

.accordion .accordion__single .single_acco_title, .accordion .accordion__single .panel-title, .accordion .panel-default .single_acco_title, .accordion .panel-default .panel-title, .panel-group .accordion__single .single_acco_title, .panel-group .accordion__single .panel-title, .panel-group .panel-default .single_acco_title, .panel-group .panel-default .panel-title {
  font-size: 22px;
}

.accordion .accordion__single .single_acco_title a, .accordion .accordion__single .panel-title a, .accordion .panel-default .single_acco_title a, .accordion .panel-default .panel-title a, .panel-group .accordion__single .single_acco_title a, .panel-group .accordion__single .panel-title a, .panel-group .panel-default .single_acco_title a, .panel-group .panel-default .panel-title a {
  transition: 0.3s ease;
}

.accordion .accordion__single .single_acco_title a.active, .accordion .accordion__single .panel-title a.active, .accordion .panel-default .single_acco_title a.active, .accordion .panel-default .panel-title a.active, .panel-group .accordion__single .single_acco_title a.active, .panel-group .accordion__single .panel-title a.active, .panel-group .panel-default .single_acco_title a.active, .panel-group .panel-default .panel-title a.active {
  background: #0674ec;
  color: #fff;
}

.accordion .single_accordion_body, .panel-group .single_accordion_body {
  padding: 27px 30px;
}

.accordion .single_accordion_body p, .panel-group .single_accordion_body p {
  margin: 0;
}

/*****************************
	--- MODAL
*****************************/
/*****************************
	-- THEME
*****************************/
.modal .modal-header {
  padding: 30px;
  display: block;
}

.modal .modal-header p {
  margin: 0;
}

.modal .modal-title {
  margin-bottom: 17px;
}

@media (min-width: 768px) {
  .modal .modal-dialog {
    width: 750px;
    margin: 30px auto;
  }
}

.modal .modal-body {
  padding: 30px;
}

.modal .modal_close {
  background: #7347c1;
  line-height: 40px;
  color: #fff;
  margin-left: 10px;
  padding: 0 36px;
}

/*item removal modal */
.item_removal .btns {
  text-align: center;
}

.item_removal .btns a + a {
  margin-left: 20px;
}

.item_removal .modal-body {
  text-align: center;
  padding: 50px 0;
}

.item_removal .modal-body h4 {
  margin-bottom: 20px;
  font-size: 24px;
  margin-bottom: 50px;
}

.item_removal .modal-dialog {
  top: 33%;
}

.item_remove_modal .modal-header {
  position: relative;
  flex-direction: column;
}

.item_remove_modal .modal-header button {
  position: absolute;
  right: 30px;
  top: 45px;
}

/*****************************
	-- TABLE
*****************************/
/*****************************
	-- THEME
*****************************/
.table_module .table_header {
  padding: 33px 25px 30px;
  border-bottom: 1px solid #ececec;
}

.table thead > tr > th {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  padding: 25px 0 25px 28px;
}

.table tbody > tr {
  border-bottom: 1px solid #ececec;
}

.table tbody > tr > td {
  padding: 20px 0 20px 28px;
  font-size: 16px;
  vertical-align: middle;
  color: #333;
}

.table tbody > tr > td.author {
  color: #000;
  font-weight: 500;
}

.table tbody > tr > td.detail a {
  color: #0674ec;
  font-weight: 500;
}

.table tbody > tr > td.type span {
  line-height: 26px;
  border-radius: 200px;
  display: inline-block;
  padding: 0 15px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
}

.table tbody > tr > td.type span.sale {
  background: #77de51;
}

.table tbody > tr > td.type span.purchase {
  background: #feb71d;
}

.table tbody > tr > td.type span.credited {
  background: #58c9e9;
}

.table tbody > tr > td.type span.withdrawal {
  background: #ff6a6d;
}

.table tbody > tr > td.earning {
  color: #0674ec;
  font-weight: 500;
}

.table tbody > tr > td.earning.subtract {
  color: #7347c1;
}

.table tbody > tr > td.action {
  padding: 0;
  text-align: center;
}

.table tbody > tr > td.action a {
  line-height: 34px;
  padding: 0 15px;
  display: inline-block;
  margin-left: -4px;
  text-transform: capitalize;
  font-size: 15px;
  border-radius: 200px;
  background: rgba(115, 71, 193, 0.1);
}

.table tbody > tr > td.action a:hover {
  background: #0674ec;
  color: #fff;
}

.table.withdraw__table .pending > span, .table.withdraw__table .paid > span {
  font-size: 15px;
  line-height: 32px;
  padding: 0 20px;
  font-weight: 500;
  display: inline-block;
  border-radius: 200px;
}

.table.withdraw__table .pending > span {
  background: #0674ec;
  color: #fff;
}

.table.withdraw__table .paid > span {
  background: rgba(6, 116, 236, 0.1);
  color: #0674ec;
}

/*****************************
	-- PROGRESSBAR
*****************************/
.progress_wrapper {
  width: 100%;
  display: inline-block;
  padding: 0 35px;
  vertical-align: middle;
  margin-bottom: 50px;
}

.progress_wrapper .with_close {
  width: calc(100% - 58px);
  display: inline-block;
  vertical-align: middle;
  margin-right: 25px;
}

.progress_wrapper .progress {
  margin-bottom: 0;
  height: 10px;
  background: #eff1f5;
  box-shadow: none;
}

.progress_wrapper .progress .progress-bar {
  border-radius: 200px;
  background-image: linear-gradient(to left, #7347c1 0%, #0674ec 100%);
  /* Fallback (could use .jpg/.png alternatively) */
  background-color: #7347c1;
  /* SVG fallback for IE 9 (could be data URI, or could use filter) */
  background: #0674ec;
  /* Safari 4, Chrome 1-9, iOS 3.2-4.3, Android 2.1-3.0 */
  background-image: -webkit-gradient(linear, left top, right top, from(#7347c1), to(#0674ec));
  /* Safari 5.1, iOS 5.0-6.1, Chrome 10-25, Android 4.0-4.3 */
  background-image: -webkit-linear-gradient(left, #7347c1, #0674ec);
  /* Firefox 3.6 - 15 */
  background-image: -moz-linear-gradient(left, #7347c1, #0674ec);
  /* Opera 11.1 - 12 */
  background-image: -o-linear-gradient(left, #7347c1, #0674ec);
  /* Opera 15+, Chrome 25+, IE 10+, Firefox 16+, Safari 6.1+, iOS 7+, Android 4.4+ */
  background-image: linear-gradient(to right, #7347c1, #0674ec);
}

.progress_wrapper .labels {
  margin-bottom: 10px;
}

.progress_wrapper .labels p {
  color: #555;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  float: left;
}

.progress_wrapper .labels span {
  float: right;
  line-height: 26px;
  font-size: 15px;
  color: #000;
  font-weight: 500;
}

/*****************************
	-- TIMELINE
*****************************/
/*****************************
	-- THEME
*****************************/
/*****************************
	-- THEME
*****************************/
/*****************************
	-- THEME
*****************************/
/*****************************
	-- MIXINS
*****************************/
/* container sizes for various devices */
/* Custom scrollbar */
/*****************************
	-- GENERAL
*****************************/
.preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}

.pcolor {
  color: #0674ec;
}

.scolor {
  color: #7347c1;
}

/* Background Image */
.bgimage {
  position: relative;
}

.bgimage:before {
  z-index: 1;
}

.content_above {
  position: relative;
  z-index: 2;
}

/* Background image holder */
.bg_image_holder {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-size: cover !important;
  background-position: 50% 50% !important;
  z-index: 0;
  transition: opacity .3s linear;
  -webkit-transition: opacity .3s linear;
  opacity: 0;
}

.bg_image_holder img {
  display: none;
}

.or {
  width: 100%;
  background: #ececec;
  height: 1px;
  margin-bottom: 33px;
  position: relative;
}

.or:before {
  content: 'Or';
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  color: #0674ec;
  font-weight: 500;
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.mcolor1 {
  color: #77de51;
}

.mcolor2 {
  color: #feb71d;
}

.mcolor3 {
  color: #58c9e9;
}

.mcolor4 {
  color: #ff6a6d;
}

.pcolorbg {
  background: #0674ec;
}

.scolorbg {
  background: #7347c1;
}

.mcolorbg1 {
  background: #77de51;
}

.mcolorbg2 {
  background: #feb71d;
}

.mcolorbg3 {
  background: #58c9e9;
}

.mcolorbg4 {
  background: #ff6a6d;
}

/* tooltip */
.tooltip {
  display: block;
}

.tooltip .tooltip-inner {
  font-family: "Quicksand", sans-serif;
  font-size: 14px;
  text-align: left;
  display: block;
}

/*.highlighted{
  background: $pcolor;
}*/
.section--padding {
  padding-top: 120px;
  padding-bottom: 120px;
}

.section--padding2 {
  padding: 70px 0 120px;
}

.section--padding3 {
  padding: 70px 0 0;
}

.section-title {
  text-align: center;
  padding-bottom: 53px;
}

.section-title h1 {
  font-weight: 400;
  margin-bottom: 24px;
}

.section-title h1 .highlighted {
  color: #0674ec;
}

.section-title p {
  padding: 0 230px;
  line-height: 30px;
  margin-bottom: 0;
}

.shortcode_wrapper + .shortcode_wrapper {
  margin-top: 80px;
}

/* Input with icons */
.input_with_icon {
  position: relative;
}

.input_with_icon span {
  position: absolute;
  right: 20px;
  top: 50%;
  color: #747b86;
  transform: translateY(-50%);
}

.product-title-area, .shortcode_module_title, .dashboard_contents .dashboard_title_area {
  background: #fff;
  padding: 32px 30px 32px;
  margin-bottom: 30px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-title-area:before, .shortcode_module_title:before, .dashboard_contents .dashboard_title_area:before, .product-title-area:after, .shortcode_module_title:after, .dashboard_contents .dashboard_title_area:after {
  display: table;
  content: "";
  clear: both;
}

.product-title-area .product__slider-nav, .shortcode_module_title .product__slider-nav, .dashboard_contents .dashboard_title_area .product__slider-nav {
  float: right;
  background: #7347c1;
}

.product-title-area .product__slider-nav span, .shortcode_module_title .product__slider-nav span, .dashboard_contents .dashboard_title_area .product__slider-nav span {
  line-height: 45px;
  color: #fff;
  cursor: pointer;
  width: 52px;
  display: inline-block;
  text-align: center;
}

.product-title-area .date_area, .shortcode_module_title .date_area, .dashboard_contents .dashboard_title_area .date_area {
  float: right;
}

.product-title-area .date_area p, .shortcode_module_title .date_area p, .dashboard_contents .dashboard_title_area .date_area p {
  float: left;
}

.product-title-area .date_area .input_with_icon, .shortcode_module_title .date_area .input_with_icon, .dashboard_contents .dashboard_title_area .date_area .input_with_icon {
  max-width: 158px;
}

.product-title-area .date_area .input_with_icon, .shortcode_module_title .date_area .input_with_icon, .dashboard_contents .dashboard_title_area .date_area .input_with_icon, .product-title-area .date_area .select-wrap, .shortcode_module_title .date_area .select-wrap, .dashboard_contents .dashboard_title_area .date_area .select-wrap {
  float: left;
  margin-right: 10px;
}

.product-title-area .date_area .select-wrap select, .shortcode_module_title .date_area .select-wrap select, .dashboard_contents .dashboard_title_area .date_area .select-wrap select {
  height: 47px;
}

.product-title-area .date_area .btn:hover, .shortcode_module_title .date_area .btn:hover, .dashboard_contents .dashboard_title_area .date_area .btn:hover {
  background: #0674ec;
  color: #fff;
}

.product__title {
  float: left;
}

.product__title h2 {
  font-weight: 400;
  line-height: 45px;
}

.filter__menu {
  float: right;
  position: relative;
  margin-top: 10px;
}

.filter__menu p {
  margin-bottom: 0;
  display: inline-block;
  vertical-align: middle;
  color: #747b86;
  margin-right: 15px;
}

.filter__menu .filter__menu_icon {
  display: inline-block;
  vertical-align: middle;
  margin-top: 6px;
  width: 17px;
}

.filter__menu .filter__menu_icon svg {
  height: 16px;
  cursor: pointer;
}

.filter__menu .filter__menu_icon svg:hover circle {
  fill: #0673ec;
}

.filter__menu .filter__menu_icon svg circle {
  fill: #a3b1c4;
}

.filter__menu .filter__menu_icon .filter_dropdown {
  position: absolute;
  min-width: 183px;
  padding: 20px 27px;
  top: 45px !important;
  z-index: 3;
  background: #fff;
  right: -15px;
  left: auto !important;
  box-shadow: 0 5px 40px rgba(82, 85, 90, 0.2);
  transform: translate3d(0, 0, 0) !important;
}

.filter__menu .filter__menu_icon .filter_dropdown.active {
  display: block;
}

.filter__menu .filter__menu_icon .filter_dropdown:before {
  position: absolute;
  content: "";
  border-bottom: 10px solid #fff;
  border-left: 10px solid transparent;
  right: 15px;
  bottom: 100%;
  border-right: 10px solid transparent;
}

.filter__menu .filter__menu_icon .filter_dropdown li a {
  color: #747b86;
  display: block;
  transition: 0.3s ease;
  line-height: 28px;
}

.filter__menu .filter__menu_icon .filter_dropdown li a:hover {
  color: #0674ec;
}

.transparent {
  background: transparent;
}

.rounded, .credit_modules .modules__content .amounts ul li p, .shortcode_modules .modules__content .amounts ul li p, .upload_modules .modules__content .amounts ul li p, .withdraw_module .modules__content .amounts ul li p {
  border-radius: 200px;
}

.tags ul > li {
  display: inline-block;
}

.tags--round ul > li a {
  color: #868c96;
  line-height: 28px;
  background: #f1f3f6;
  display: inline-block;
  padding: 0 17px;
  margin-right: 4px;
  border-radius: 50px;
  transition: 0.3s ease;
}

.tags--round ul > li a:hover {
  background: #7347c1;
  color: #fff;
}

.bgcolor {
  background: #eff1f5;
}

.bgcolor2 {
  background: #f6f7fb;
}

.range-slider.ui-slider {
  height: 6px;
  background: #efedf3;
  border-radius: 6px;
}

.range-slider.ui-slider .ui-slider-range {
  background: #7347c1;
}

.range-slider.ui-slider .ui-slider-handle {
  height: 18px;
  width: 18px;
  background: #0674ec;
  border-radius: 50px;
  top: -6px;
  z-index: 2;
  cursor: pointer;
}

.range-slider.ui-slider .ui-slider-handle:focus {
  outline: 0;
}

.range-slider.ui-slider .ui-slider-handle:before {
  content: '';
  position: absolute;
  height: calc(100% - 8px);
  width: calc(100% - 8px);
  background: #fff;
  left: 4px;
  top: 4px;
  z-index: -1;
  border-radius: 50%;
}

.price-ranges {
  padding-top: 34px;
  text-align: center;
}

.price-ranges span {
  line-height: 32px;
  display: inline-block;
  background: rgba(115, 71, 193, 0.1);
  width: 80px;
  color: #7347c1;
}

.rating {
  display: inline-block;
  vertical-align: middle;
}

.rating ul {
  float: left;
  padding-bottom: 0;
}

.rating ul li {
  float: left;
  margin-right: 3px;
}

.rating ul li span.fa-star, .rating ul li span.fa-star-half-o {
  color: #ffc000;
}

.rating ul li span.fa-star-o {
  color: #7e7e7e;
}

.rating .rating__count {
  display: inline-block;
  color: #6f7d8d;
  vertical-align: middle;
  margin-left: 5px;
}

.custom-checkbox2 input[type='checkbox'], .custom-radio input[type='checkbox'] {
  display: none;
}

.custom-checkbox2 input[type='checkbox']:checked + label span:before, .custom-radio input[type='checkbox']:checked + label span:before, .custom-checkbox2 .form-group input[type='checkbox']:checked + p.label span:before, .form-group .custom-checkbox2 input[type='checkbox']:checked + p.label span:before, .custom-radio .form-group input[type='checkbox']:checked + p.label span:before, .form-group .custom-radio input[type='checkbox']:checked + p.label span:before {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.custom-checkbox2 label, .custom-radio label, .custom-checkbox2 .form-group p.label, .form-group .custom-checkbox2 p.label, .custom-radio .form-group p.label, .form-group .custom-radio p.label {
  font-size: 15px;
  font-weight: 400;
  margin: 0;
  line-height: 36px;
  cursor: pointer;
  text-transform: none;
}

.custom-checkbox2 label span.circle, .custom-radio label span.circle, .custom-checkbox2 .form-group p.label span.circle, .form-group .custom-checkbox2 p.label span.circle, .custom-radio .form-group p.label span.circle, .form-group .custom-radio p.label span.circle {
  height: 20px;
  margin-right: 12px;
  vertical-align: middle;
  width: 20px;
  background: #fff;
  display: inline-block;
  border: 4px solid #e6e9ed;
  border-radius: 50%;
  position: relative;
}

.custom-checkbox2 label span.circle:before, .custom-radio label span.circle:before, .custom-checkbox2 .form-group p.label span.circle:before, .form-group .custom-checkbox2 p.label span.circle:before, .custom-radio .form-group p.label span.circle:before, .form-group .custom-radio p.label span.circle:before {
  content: '\f00c';
  font-family: 'FontAwesome',sans-serif;
  position: absolute;
  height: 20px;
  width: 20px;
  text-align: center;
  font-size: 12px;
  bottom: -4px;
  transition: 0.2s;
  left: -4px;
  opacity: 0;
  transform: scale(0.5);
  border-radius: 50%;
  background: #0674ec;
  color: #fff;
  line-height: 20px;
}

.custom-radio input[type='radio'] {
  display: none;
}

.custom-radio input[type='radio']:checked + label span:before, .custom-radio .form-group input[type='radio']:checked + p.label span:before, .form-group .custom-radio input[type='radio']:checked + p.label span:before {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.custom-radio label span.circle:before, .custom-radio .form-group p.label span.circle:before, .form-group .custom-radio p.label span.circle:before {
  content: "";
  background: #fff;
  border: 4px solid #0674ec;
}

.custom-radio.custom_radio--big span.circle {
  height: 30px;
  width: 30px;
  border-width: 5px;
}

.custom-radio.custom_radio--big span.circle:before {
  width: 30px;
  height: 30px;
  font-size: 16px;
  line-height: 30px;
  border-width: 5px;
}

.radio-group .label {
  display: block;
  text-align: left;
}

.radio-group .custom-radio {
  display: inline-block;
}

.radio-group .custom-radio + .custom-radio {
  margin-left: 30px;
}

/* custom checkbox area css*/
.custom_checkbox {
  position: relative;
  margin-top: 10px;
}

.custom_checkbox input[type='checkbox'] {
  display: none;
}

.custom_checkbox input[type='checkbox']:checked + label .shadow_checkbox, .custom_checkbox .form-group input[type='checkbox']:checked + p.label .shadow_checkbox, .form-group .custom_checkbox input[type='checkbox']:checked + p.label .shadow_checkbox {
  border: transparent;
}

.custom_checkbox input[type='checkbox']:checked + label .shadow_checkbox:before, .custom_checkbox .form-group input[type='checkbox']:checked + p.label .shadow_checkbox:before, .form-group .custom_checkbox input[type='checkbox']:checked + p.label .shadow_checkbox:before {
  visibility: visible;
}

.custom_checkbox label, .custom_checkbox .form-group p.label, .form-group .custom_checkbox p.label {
  margin-bottom: 0;
}

.custom_checkbox label .label_text, .custom_checkbox .form-group p.label .label_text, .form-group .custom_checkbox p.label .label_text {
  font-weight: 400;
  color: #555;
  display: inline-block;
  vertical-align: middle;
  margin-left: 11px;
}

.custom_checkbox label .shadow_checkbox, .custom_checkbox .form-group p.label .shadow_checkbox, .form-group .custom_checkbox p.label .shadow_checkbox {
  height: 18px;
  width: 18px;
  top: 0;
  border: 1px solid #d3d3d3;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  border-radius: 2px;
}

.custom_checkbox label .shadow_checkbox:before, .custom_checkbox .form-group p.label .shadow_checkbox:before, .form-group .custom_checkbox p.label .shadow_checkbox:before {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  content: '\f00c';
  font-size: 12px;
  visibility: hidden;
  text-align: center;
  color: #fff;
  line-height: 18px;
  font-family: FontAwesome, sans-serif;
  background: #0674ec;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

/* custom upload field */
.custom_upload p {
  font-weight: 500;
  font-size: 16px;
  color: #000;
  margin-bottom: 12px;
}

.custom_upload p span {
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
  color: #555;
}

.custom_upload input[type='file'] {
  display: none;
}

/* cardify any area */
.cardify, .content_title, .table_module, .card_style1, .card_style2, .credit_modules, .shortcode_modules, .upload_modules, .withdraw_module, .statement_info_card, .statement_table, .thread_sort_area, .author_info, .comment_area, .contact_tile, .featured_event, .google_map .location_address .addres_module {
  background: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  -moz-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
}

/* common toggle module css */
.toggle_title {
  display: block;
  padding: 30px 30px 25px;
}

.toggle_title span.lnr {
  float: right;
  line-height: 20px;
  font-size: 14px;
}

.toggle_title h4 {
  font-size: 22px;
}

.social_icon {
  display: inline-block;
}

.social_icon span {
  display: inline-block;
  line-height: 45px;
  width: 45px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  text-align: center;
  border-radius: 50%;
  font-size: 16px;
  color: #fff;
}

.social_icon span.fa-facebook {
  background: #395799;
}

.social_icon span.fa-twitter {
  background: #19b2f5;
}

.social_icon span.fa-google-plus {
  background: #f05b44;
}

.social_icon span.fa-behance {
  background: #0057ff;
}

.social_icon span.fa-dribbble {
  background: #ea4c89;
}

.input-group .input-group-addon {
  background: none;
  border: none;
  font-size: 18px;
  color: #555;
  padding-left: 0;
}

.input-group.with--addon {
  align-items: center;
}

.input-group.with--addon .text_field {
  width: calc(100% - 25px);
  margin-left: 14px;
}

/* content area title */
.content_title {
  padding: 30px;
  border-radius: 4px;
  margin-bottom: 30px;
}

/* video modal css */
.video_modal .modal-content iframe {
  width: 100%;
  height: 450px;
}

.messages::-webkit-scrollbar-track {
  background-color: transparent;
}

.messages::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.messages::-webkit-scrollbar-thumb {
  background-color: #e9eef2;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.offcanvas-menu::-webkit-scrollbar-track {
  background-color: transparent;
}

.offcanvas-menu::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.offcanvas-menu::-webkit-scrollbar-thumb {
  background-color: #e9eef2;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.timeline:before {
  top: 0;
}

.timeline:before, .timeline:after {
  position: absolute;
  content: '';
  height: 19px;
  width: 19px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  border-radius: 50%;
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  background: #7347c1;
  border: 4px solid #fff;
}

.timeline:after {
  background: #0674ec;
  bottom: 0;
}

.timeline .happening:before, .timeline .happening:after {
  content: '';
  display: table;
  transition: 0.3s ease;
  clear: both;
}

.timeline .happening .happening--period {
  width: 50%;
  padding: 60px 54px 0 0;
  float: left;
  vertical-align: top;
  text-align: right;
  position: relative;
}

.timeline .happening .happening--period p {
  color: #898f96;
  font-size: 18px;
  font-weight: 500;
  transition: 0.3s ease;
  margin-bottom: 0;
}

.timeline .happening .happening--period:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background: #fff;
  border-radius: 50%;
  border: 3px solid #c4cdd8;
  right: 0;
  transition: 0.3s ease;
  transform: translateX(50%);
}

.timeline .happening .happening--detail {
  width: 50%;
  float: right;
  padding: 60px  0 0 50px;
  position: relative;
}

.timeline .happening .happening--detail h4 {
  font-size: 24px;
  color: #000;
  margin-bottom: 20px;
}

.timeline .happening .happening--detail p {
  font-size: 16px;
  line-height: 30px;
}

.timeline .happening .happening--detail:before {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background: #ebecf1;
  z-index: -1;
  transform: translateX(-50%);
}

.timeline .happening:nth-child(2n+2) .happening--period {
  float: right;
  text-align: left;
  padding-right: 0;
  padding-left: 54px;
}

.timeline .happening:nth-child(2n+2) .happening--period:before {
  left: 0;
  transform: translateX(-50%);
}

.timeline .happening:nth-child(2n+2) .happening--detail {
  text-align: right;
  padding: 40px 50px 0 0;
}

.timeline .happening:nth-child(2n+2) .happening--detail:before {
  left: 100%;
}

.timeline .happening:hover .happening--period:before {
  background: #0674ec;
  border-color: #fff;
}

.timeline .happening:hover .happening--period p {
  color: #0674ec;
}

/*****************************
	-- TEAM
*****************************/
/*****************************
	-- THEME
*****************************/
.team_area {
  background: #f6f7fb;
}

.single_team_member {
  box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  margin-bottom: 30px;
}

.single_team_member figure img {
  width: 100%;
  border-radius: 4px 4px 0 0;
}

.single_team_member figure figcaption {
  overflow: hidden;
  text-align: center;
  background: #fff;
  position: relative;
  border-radius: 0 0 4px 4px;
}

.single_team_member figure figcaption .name_desig {
  padding: 20px 0;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.single_team_member figure figcaption .name_desig h4 {
  color: #000;
  font-size: 20px;
  line-height: 28px;
}

.single_team_member figure figcaption .name_desig p {
  font-size: 16px;
  margin-bottom: 0;
}

.single_team_member figure .social, .single_team_member figure .single_blog_content .share_tags .share .social_share, .single_blog_content .share_tags .share .single_team_member figure .social_share {
  transition: 0.3s;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  opacity: 0;
  visibility: hidden;
  top: 0;
  background-image: linear-gradient(to left, #7347c1 0%, #0674ec 100%);
  /* Fallback (could use .jpg/.png alternatively) */
  background-color: #7347c1;
  /* SVG fallback for IE 9 (could be data URI, or could use filter) */
  background: #0674ec;
  /* Safari 4, Chrome 1-9, iOS 3.2-4.3, Android 2.1-3.0 */
  background-image: -webkit-gradient(linear, left top, right top, from(#7347c1), to(#0674ec));
  /* Safari 5.1, iOS 5.0-6.1, Chrome 10-25, Android 4.0-4.3 */
  background-image: -webkit-linear-gradient(left, #7347c1, #0674ec);
  /* Firefox 3.6 - 15 */
  background-image: -moz-linear-gradient(left, #7347c1, #0674ec);
  /* Opera 11.1 - 12 */
  background-image: -o-linear-gradient(left, #7347c1, #0674ec);
  /* Opera 15+, Chrome 25+, IE 10+, Firefox 16+, Safari 6.1+, iOS 7+, Android 4.4+ */
  background-image: linear-gradient(to right, #7347c1, #0674ec);
  transform: scale(1.2);
}

.single_team_member figure .social ul, .single_team_member figure .single_blog_content .share_tags .share .social_share ul, .single_blog_content .share_tags .share .single_team_member figure .social_share ul {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.single_team_member figure .social ul li a, .single_team_member figure .single_blog_content .share_tags .share .social_share ul li a, .single_blog_content .share_tags .share .single_team_member figure .social_share ul li a {
  border-radius: 50%;
  background: #fff;
  font-size: 16px;
  transition: 0.3s ease;
}

.single_team_member figure .social ul li a span.fa-facebook, .single_team_member figure .single_blog_content .share_tags .share .social_share ul li a span.fa-facebook, .single_blog_content .share_tags .share .single_team_member figure .social_share ul li a span.fa-facebook {
  color: #395799;
}

.single_team_member figure .social ul li a span.fa-twitter, .single_team_member figure .single_blog_content .share_tags .share .social_share ul li a span.fa-twitter, .single_blog_content .share_tags .share .single_team_member figure .social_share ul li a span.fa-twitter {
  color: #19b2f5;
}

.single_team_member figure .social ul li a span.fa-google-plus, .single_team_member figure .single_blog_content .share_tags .share .social_share ul li a span.fa-google-plus, .single_blog_content .share_tags .share .single_team_member figure .social_share ul li a span.fa-google-plus {
  color: #f05b44;
}

.single_team_member figure .social ul li a span.fa-dribbble, .single_team_member figure .single_blog_content .share_tags .share .social_share ul li a span.fa-dribbble, .single_blog_content .share_tags .share .single_team_member figure .social_share ul li a span.fa-dribbble {
  color: #ea4c89;
}

.single_team_member figure .social ul li a:hover span, .single_team_member figure .single_blog_content .share_tags .share .social_share ul li a:hover span, .single_blog_content .share_tags .share .single_team_member figure .social_share ul li a:hover span {
  color: #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: none;
}

.single_team_member figure .social ul li a:hover span.fa-facebook, .single_team_member figure .single_blog_content .share_tags .share .social_share ul li a:hover span.fa-facebook, .single_blog_content .share_tags .share .single_team_member figure .social_share ul li a:hover span.fa-facebook {
  background: #395799;
}

.single_team_member figure .social ul li a:hover span.fa-twitter, .single_team_member figure .single_blog_content .share_tags .share .social_share ul li a:hover span.fa-twitter, .single_blog_content .share_tags .share .single_team_member figure .social_share ul li a:hover span.fa-twitter {
  background: #19b2f5;
}

.single_team_member figure .social ul li a:hover span.fa-google-plus, .single_team_member figure .single_blog_content .share_tags .share .social_share ul li a:hover span.fa-google-plus, .single_blog_content .share_tags .share .single_team_member figure .social_share ul li a:hover span.fa-google-plus {
  background: #f05b44;
}

.single_team_member figure .social ul li a:hover span.fa-dribbble, .single_team_member figure .single_blog_content .share_tags .share .social_share ul li a:hover span.fa-dribbble, .single_blog_content .share_tags .share .single_team_member figure .social_share ul li a:hover span.fa-dribbble {
  background: #ea4c89;
}

.single_team_member figure:hover figcaption .name_desig {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0;
  visibility: hidden;
}

.single_team_member figure:hover figcaption .social, .single_team_member figure:hover figcaption .single_blog_content .share_tags .share .social_share, .single_blog_content .share_tags .share .single_team_member figure:hover figcaption .social_share {
  transform: scale(1);
  visibility: visible;
  opacity: 1;
}

/*****************************
	--- PRICING
*****************************/
.pricing {
  padding: 30px;
  background: #fff;
  position: relative;
  border-radius: 4px;
}

.pricing:before {
  content: "";
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 4px 4px 0 0;
  background: #ff6a6d;
}

.pricing .pricing--title {
  text-align: center;
  font-size: 24px;
  border-bottom: 1px solid #ececec;
  padding-bottom: 23px;
  line-height: 32px;
  font-weight: 500;
}

.pricing .pricing--price {
  text-align: center;
  padding-top: 26px;
  margin-bottom: 36px;
}

.pricing .pricing--price sup {
  font-size: 18px;
  color: #000;
  margin-right: -4px;
}

.pricing .pricing--price .ammount {
  font-size: 36px;
  color: #000;
  line-height: 32px;
}

.pricing .pricing--features ul li {
  position: relative;
  padding-left: 22px;
  font-size: 16px;
  line-height: 36px;
}

.pricing .pricing--features ul li span.lnr {
  font-size: 18px;
  color: #555;
  margin-left: 5px;
}

.pricing .pricing--features ul li:before {
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 7px;
  height: 7px;
  background: #c5cad4;
  border-radius: 200px;
  position: absolute;
}

.pricing .pricing--btn {
  display: block;
  text-align: center;
  line-height: 50px;
  border-radius: 4px;
  color: #fff;
  font-size: 18px;
  margin-top: 33px;
  font-weight: 500;
  position: relative;
}

.pricing .pricing--btn:before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  background: #000;
  left: 0;
  transition: 0.3s ease;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.pricing .pricing--btn:hover:before {
  visibility: visible;
  opacity: 0.05;
}

.pricing.red .pricing--btn {
  background: #ff6a6d;
}

.pricing.red:before {
  background: #ff6a6d;
}

.pricing.blue .pricing--btn {
  background: #0674ec;
}

.pricing.blue:before {
  background: #0674ec;
}

.pricing.yellow .pricing--btn {
  background: #feb71d;
}

.pricing.yellow:before {
  background: #feb71d;
}

/*****************************
	-- THEME
*****************************/
/*****************************
	-- THEME
*****************************/
/*****************************
	-- THEME
*****************************/
/*****************************
	-- MIXINS
*****************************/
/* container sizes for various devices */
/* Custom scrollbar */
/*****************************
	-- GENERAL
*****************************/
.preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}

.pcolor {
  color: #0674ec;
}

.scolor {
  color: #7347c1;
}

/* Background Image */
.bgimage {
  position: relative;
}

.bgimage:before {
  z-index: 1;
}

.content_above {
  position: relative;
  z-index: 2;
}

/* Background image holder */
.bg_image_holder {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-size: cover !important;
  background-position: 50% 50% !important;
  z-index: 0;
  transition: opacity .3s linear;
  -webkit-transition: opacity .3s linear;
  opacity: 0;
}

.bg_image_holder img {
  display: none;
}

.or {
  width: 100%;
  background: #ececec;
  height: 1px;
  margin-bottom: 33px;
  position: relative;
}

.or:before {
  content: 'Or';
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  color: #0674ec;
  font-weight: 500;
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.mcolor1 {
  color: #77de51;
}

.mcolor2 {
  color: #feb71d;
}

.mcolor3 {
  color: #58c9e9;
}

.mcolor4 {
  color: #ff6a6d;
}

.pcolorbg {
  background: #0674ec;
}

.scolorbg {
  background: #7347c1;
}

.mcolorbg1 {
  background: #77de51;
}

.mcolorbg2 {
  background: #feb71d;
}

.mcolorbg3 {
  background: #58c9e9;
}

.mcolorbg4 {
  background: #ff6a6d;
}

/* tooltip */
.tooltip {
  display: block;
}

.tooltip .tooltip-inner {
  font-family: "Quicksand", sans-serif;
  font-size: 14px;
  text-align: left;
  display: block;
}

/*.highlighted{
  background: $pcolor;
}*/
.section--padding {
  padding-top: 120px;
  padding-bottom: 120px;
}

.section--padding2 {
  padding: 70px 0 120px;
}

.section--padding3 {
  padding: 70px 0 0;
}

.section-title {
  text-align: center;
  padding-bottom: 53px;
}

.section-title h1 {
  font-weight: 400;
  margin-bottom: 24px;
}

.section-title h1 .highlighted {
  color: #0674ec;
}

.section-title p {
  padding: 0 230px;
  line-height: 30px;
  margin-bottom: 0;
}

.shortcode_wrapper + .shortcode_wrapper {
  margin-top: 80px;
}

/* Input with icons */
.input_with_icon {
  position: relative;
}

.input_with_icon span {
  position: absolute;
  right: 20px;
  top: 50%;
  color: #747b86;
  transform: translateY(-50%);
}

.product-title-area, .shortcode_module_title, .dashboard_contents .dashboard_title_area {
  background: #fff;
  padding: 32px 30px 32px;
  margin-bottom: 30px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-title-area:before, .shortcode_module_title:before, .dashboard_contents .dashboard_title_area:before, .product-title-area:after, .shortcode_module_title:after, .dashboard_contents .dashboard_title_area:after {
  display: table;
  content: "";
  clear: both;
}

.product-title-area .product__slider-nav, .shortcode_module_title .product__slider-nav, .dashboard_contents .dashboard_title_area .product__slider-nav {
  float: right;
  background: #7347c1;
}

.product-title-area .product__slider-nav span, .shortcode_module_title .product__slider-nav span, .dashboard_contents .dashboard_title_area .product__slider-nav span {
  line-height: 45px;
  color: #fff;
  cursor: pointer;
  width: 52px;
  display: inline-block;
  text-align: center;
}

.product-title-area .date_area, .shortcode_module_title .date_area, .dashboard_contents .dashboard_title_area .date_area {
  float: right;
}

.product-title-area .date_area p, .shortcode_module_title .date_area p, .dashboard_contents .dashboard_title_area .date_area p {
  float: left;
}

.product-title-area .date_area .input_with_icon, .shortcode_module_title .date_area .input_with_icon, .dashboard_contents .dashboard_title_area .date_area .input_with_icon {
  max-width: 158px;
}

.product-title-area .date_area .input_with_icon, .shortcode_module_title .date_area .input_with_icon, .dashboard_contents .dashboard_title_area .date_area .input_with_icon, .product-title-area .date_area .select-wrap, .shortcode_module_title .date_area .select-wrap, .dashboard_contents .dashboard_title_area .date_area .select-wrap {
  float: left;
  margin-right: 10px;
}

.product-title-area .date_area .select-wrap select, .shortcode_module_title .date_area .select-wrap select, .dashboard_contents .dashboard_title_area .date_area .select-wrap select {
  height: 47px;
}

.product-title-area .date_area .btn:hover, .shortcode_module_title .date_area .btn:hover, .dashboard_contents .dashboard_title_area .date_area .btn:hover {
  background: #0674ec;
  color: #fff;
}

.product__title {
  float: left;
}

.product__title h2 {
  font-weight: 400;
  line-height: 45px;
}

.filter__menu {
  float: right;
  position: relative;
  margin-top: 10px;
}

.filter__menu p {
  margin-bottom: 0;
  display: inline-block;
  vertical-align: middle;
  color: #747b86;
  margin-right: 15px;
}

.filter__menu .filter__menu_icon {
  display: inline-block;
  vertical-align: middle;
  margin-top: 6px;
  width: 17px;
}

.filter__menu .filter__menu_icon svg {
  height: 16px;
  cursor: pointer;
}

.filter__menu .filter__menu_icon svg:hover circle {
  fill: #0673ec;
}

.filter__menu .filter__menu_icon svg circle {
  fill: #a3b1c4;
}

.filter__menu .filter__menu_icon .filter_dropdown {
  position: absolute;
  min-width: 183px;
  padding: 20px 27px;
  top: 45px !important;
  z-index: 3;
  background: #fff;
  right: -15px;
  left: auto !important;
  box-shadow: 0 5px 40px rgba(82, 85, 90, 0.2);
  transform: translate3d(0, 0, 0) !important;
}

.filter__menu .filter__menu_icon .filter_dropdown.active {
  display: block;
}

.filter__menu .filter__menu_icon .filter_dropdown:before {
  position: absolute;
  content: "";
  border-bottom: 10px solid #fff;
  border-left: 10px solid transparent;
  right: 15px;
  bottom: 100%;
  border-right: 10px solid transparent;
}

.filter__menu .filter__menu_icon .filter_dropdown li a {
  color: #747b86;
  display: block;
  transition: 0.3s ease;
  line-height: 28px;
}

.filter__menu .filter__menu_icon .filter_dropdown li a:hover {
  color: #0674ec;
}

.transparent {
  background: transparent;
}

.rounded, .credit_modules .modules__content .amounts ul li p, .shortcode_modules .modules__content .amounts ul li p, .upload_modules .modules__content .amounts ul li p, .withdraw_module .modules__content .amounts ul li p {
  border-radius: 200px;
}

.tags ul > li {
  display: inline-block;
}

.tags--round ul > li a {
  color: #868c96;
  line-height: 28px;
  background: #f1f3f6;
  display: inline-block;
  padding: 0 17px;
  margin-right: 4px;
  border-radius: 50px;
  transition: 0.3s ease;
}

.tags--round ul > li a:hover {
  background: #7347c1;
  color: #fff;
}

.bgcolor {
  background: #eff1f5;
}

.bgcolor2 {
  background: #f6f7fb;
}

.range-slider.ui-slider {
  height: 6px;
  background: #efedf3;
  border-radius: 6px;
}

.range-slider.ui-slider .ui-slider-range {
  background: #7347c1;
}

.range-slider.ui-slider .ui-slider-handle {
  height: 18px;
  width: 18px;
  background: #0674ec;
  border-radius: 50px;
  top: -6px;
  z-index: 2;
  cursor: pointer;
}

.range-slider.ui-slider .ui-slider-handle:focus {
  outline: 0;
}

.range-slider.ui-slider .ui-slider-handle:before {
  content: '';
  position: absolute;
  height: calc(100% - 8px);
  width: calc(100% - 8px);
  background: #fff;
  left: 4px;
  top: 4px;
  z-index: -1;
  border-radius: 50%;
}

.price-ranges {
  padding-top: 34px;
  text-align: center;
}

.price-ranges span {
  line-height: 32px;
  display: inline-block;
  background: rgba(115, 71, 193, 0.1);
  width: 80px;
  color: #7347c1;
}

.rating {
  display: inline-block;
  vertical-align: middle;
}

.rating ul {
  float: left;
  padding-bottom: 0;
}

.rating ul li {
  float: left;
  margin-right: 3px;
}

.rating ul li span.fa-star, .rating ul li span.fa-star-half-o {
  color: #ffc000;
}

.rating ul li span.fa-star-o {
  color: #7e7e7e;
}

.rating .rating__count {
  display: inline-block;
  color: #6f7d8d;
  vertical-align: middle;
  margin-left: 5px;
}

.custom-checkbox2 input[type='checkbox'], .custom-radio input[type='checkbox'] {
  display: none;
}

.custom-checkbox2 input[type='checkbox']:checked + label span:before, .custom-radio input[type='checkbox']:checked + label span:before, .custom-checkbox2 .form-group input[type='checkbox']:checked + p.label span:before, .form-group .custom-checkbox2 input[type='checkbox']:checked + p.label span:before, .custom-radio .form-group input[type='checkbox']:checked + p.label span:before, .form-group .custom-radio input[type='checkbox']:checked + p.label span:before {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.custom-checkbox2 label, .custom-radio label, .custom-checkbox2 .form-group p.label, .form-group .custom-checkbox2 p.label, .custom-radio .form-group p.label, .form-group .custom-radio p.label {
  font-size: 15px;
  font-weight: 400;
  margin: 0;
  line-height: 36px;
  cursor: pointer;
  text-transform: none;
}

.custom-checkbox2 label span.circle, .custom-radio label span.circle, .custom-checkbox2 .form-group p.label span.circle, .form-group .custom-checkbox2 p.label span.circle, .custom-radio .form-group p.label span.circle, .form-group .custom-radio p.label span.circle {
  height: 20px;
  margin-right: 12px;
  vertical-align: middle;
  width: 20px;
  background: #fff;
  display: inline-block;
  border: 4px solid #e6e9ed;
  border-radius: 50%;
  position: relative;
}

.custom-checkbox2 label span.circle:before, .custom-radio label span.circle:before, .custom-checkbox2 .form-group p.label span.circle:before, .form-group .custom-checkbox2 p.label span.circle:before, .custom-radio .form-group p.label span.circle:before, .form-group .custom-radio p.label span.circle:before {
  content: '\f00c';
  font-family: 'FontAwesome',sans-serif;
  position: absolute;
  height: 20px;
  width: 20px;
  text-align: center;
  font-size: 12px;
  bottom: -4px;
  transition: 0.2s;
  left: -4px;
  opacity: 0;
  transform: scale(0.5);
  border-radius: 50%;
  background: #0674ec;
  color: #fff;
  line-height: 20px;
}

.custom-radio input[type='radio'] {
  display: none;
}

.custom-radio input[type='radio']:checked + label span:before, .custom-radio .form-group input[type='radio']:checked + p.label span:before, .form-group .custom-radio input[type='radio']:checked + p.label span:before {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.custom-radio label span.circle:before, .custom-radio .form-group p.label span.circle:before, .form-group .custom-radio p.label span.circle:before {
  content: "";
  background: #fff;
  border: 4px solid #0674ec;
}

.custom-radio.custom_radio--big span.circle {
  height: 30px;
  width: 30px;
  border-width: 5px;
}

.custom-radio.custom_radio--big span.circle:before {
  width: 30px;
  height: 30px;
  font-size: 16px;
  line-height: 30px;
  border-width: 5px;
}

.radio-group .label {
  display: block;
  text-align: left;
}

.radio-group .custom-radio {
  display: inline-block;
}

.radio-group .custom-radio + .custom-radio {
  margin-left: 30px;
}

/* custom checkbox area css*/
.custom_checkbox {
  position: relative;
  margin-top: 10px;
}

.custom_checkbox input[type='checkbox'] {
  display: none;
}

.custom_checkbox input[type='checkbox']:checked + label .shadow_checkbox, .custom_checkbox .form-group input[type='checkbox']:checked + p.label .shadow_checkbox, .form-group .custom_checkbox input[type='checkbox']:checked + p.label .shadow_checkbox {
  border: transparent;
}

.custom_checkbox input[type='checkbox']:checked + label .shadow_checkbox:before, .custom_checkbox .form-group input[type='checkbox']:checked + p.label .shadow_checkbox:before, .form-group .custom_checkbox input[type='checkbox']:checked + p.label .shadow_checkbox:before {
  visibility: visible;
}

.custom_checkbox label, .custom_checkbox .form-group p.label, .form-group .custom_checkbox p.label {
  margin-bottom: 0;
}

.custom_checkbox label .label_text, .custom_checkbox .form-group p.label .label_text, .form-group .custom_checkbox p.label .label_text {
  font-weight: 400;
  color: #555;
  display: inline-block;
  vertical-align: middle;
  margin-left: 11px;
}

.custom_checkbox label .shadow_checkbox, .custom_checkbox .form-group p.label .shadow_checkbox, .form-group .custom_checkbox p.label .shadow_checkbox {
  height: 18px;
  width: 18px;
  top: 0;
  border: 1px solid #d3d3d3;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  border-radius: 2px;
}

.custom_checkbox label .shadow_checkbox:before, .custom_checkbox .form-group p.label .shadow_checkbox:before, .form-group .custom_checkbox p.label .shadow_checkbox:before {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  content: '\f00c';
  font-size: 12px;
  visibility: hidden;
  text-align: center;
  color: #fff;
  line-height: 18px;
  font-family: FontAwesome, sans-serif;
  background: #0674ec;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

/* custom upload field */
.custom_upload p {
  font-weight: 500;
  font-size: 16px;
  color: #000;
  margin-bottom: 12px;
}

.custom_upload p span {
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
  color: #555;
}

.custom_upload input[type='file'] {
  display: none;
}

/* cardify any area */
.cardify, .content_title, .table_module, .card_style1, .card_style2, .credit_modules, .shortcode_modules, .upload_modules, .withdraw_module, .statement_info_card, .statement_table, .thread_sort_area, .author_info, .comment_area, .contact_tile, .featured_event, .google_map .location_address .addres_module {
  background: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  -moz-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
}

/* common toggle module css */
.toggle_title {
  display: block;
  padding: 30px 30px 25px;
}

.toggle_title span.lnr {
  float: right;
  line-height: 20px;
  font-size: 14px;
}

.toggle_title h4 {
  font-size: 22px;
}

.social_icon {
  display: inline-block;
}

.social_icon span {
  display: inline-block;
  line-height: 45px;
  width: 45px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  text-align: center;
  border-radius: 50%;
  font-size: 16px;
  color: #fff;
}

.social_icon span.fa-facebook {
  background: #395799;
}

.social_icon span.fa-twitter {
  background: #19b2f5;
}

.social_icon span.fa-google-plus {
  background: #f05b44;
}

.social_icon span.fa-behance {
  background: #0057ff;
}

.social_icon span.fa-dribbble {
  background: #ea4c89;
}

.input-group .input-group-addon {
  background: none;
  border: none;
  font-size: 18px;
  color: #555;
  padding-left: 0;
}

.input-group.with--addon {
  align-items: center;
}

.input-group.with--addon .text_field {
  width: calc(100% - 25px);
  margin-left: 14px;
}

/* content area title */
.content_title {
  padding: 30px;
  border-radius: 4px;
  margin-bottom: 30px;
}

/* video modal css */
.video_modal .modal-content iframe {
  width: 100%;
  height: 450px;
}

.messages::-webkit-scrollbar-track {
  background-color: transparent;
}

.messages::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.messages::-webkit-scrollbar-thumb {
  background-color: #e9eef2;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.offcanvas-menu::-webkit-scrollbar-track {
  background-color: transparent;
}

.offcanvas-menu::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.offcanvas-menu::-webkit-scrollbar-thumb {
  background-color: #e9eef2;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

/*****************************
	--- DROPDOWN
*****************************/
.dropdowns {
  position: absolute;
  min-width: 271px;
  background: #fff;
  padding: 19px 30px;
  z-index: 3;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease;
  border-top: 1px solid #0674ec;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 5px 40px rgba(82, 85, 90, 0.2);
  /* dropdown menu */
}

.dropdowns.active {
  opacity: 1;
  visibility: visible;
}

.dropdowns li a {
  font-size: 15px;
  line-height: 32px;
  display: block;
  color: #747b86;
  padding: 0;
}

.dropdowns li a:hover {
  color: #0674ec;
  background: none;
}

.dropdowns li a span {
  float: right;
}

.dropdowns:before {
  content: '';
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #0674ec;
  bottom: 100%;
}

.dropdowns.notification--dropdown {
  width: 370px;
  padding: 0;
  left: -15px;
}

.dropdowns.notification--dropdown:before {
  bottom: 100%;
  left: 30px;
}

.dropdowns.notification--dropdown .notifications_module .notification {
  padding: 20px 25px 15px;
}

.dropdowns.notification--dropdown .notifications_module .notification .notification__info .info_avatar {
  margin-right: 12px;
  width: 40px;
  vertical-align: top;
  height: 40px;
}

.dropdowns.notification--dropdown .notifications_module .notification .notification__info .info {
  width: calc(100% - 57px);
}

.dropdowns.notification--dropdown .notifications_module .notification .notification__info .info .time {
  margin-top: 0;
}

.dropdowns.notification--dropdown .notifications_module .notification .notification__info .info p a {
  display: block;
}

.dropdowns.notification--dropdown .notifications_module .notification .notification__icons .noti_icon {
  margin-right: 0;
  line-height: 30px;
  width: 30px;
  font-size: 15px;
}

.dropdowns.messaging--dropdown {
  padding: 0;
  width: 370px;
  right: -15px;
}

.dropdowns.messaging--dropdown li a {
  color: #54667a;
}

.dropdowns.messaging--dropdown li a span {
  float: none;
}

.dropdowns.messaging--dropdown li a:hover {
  color: #0674ec;
}

.dropdowns.messaging--dropdown:before {
  right: 30px;
  bottom: 100%;
}

.dropdowns.messaging--dropdown .message {
  padding: 20px;
  border-bottom: 1px solid #ececec;
  display: block;
}

.dropdowns.messaging--dropdown .message .message__actions_avatar {
  display: inline-block;
  vertical-align: middle;
}

.dropdowns.messaging--dropdown .message .message__actions_avatar .avatar {
  height: 40px;
  width: 40px;
}

.dropdowns.messaging--dropdown .message .message__actions_avatar .avatar img {
  width: 100%;
}

.dropdowns.messaging--dropdown .message .message_data {
  display: inline-block;
  vertical-align: middle;
}

.dropdowns.messaging--dropdown .message .message_data .name_time {
  margin-left: 13px;
}

.dropdowns.messaging--dropdown .message .message_data .name_time .name {
  float: left;
}

.dropdowns.messaging--dropdown .message .message_data .name_time .name p {
  display: inline-block;
  margin-bottom: 6px;
  font-weight: 500;
  color: #333333;
}

.dropdowns.messaging--dropdown .message .message_data .name_time .name span {
  display: inline;
  vertical-align: top;
  font-size: 15px;
  color: #0674ec;
  margin-left: 5px;
}

.dropdowns.messaging--dropdown .message .message_data .name_time .time {
  float: right;
  font-size: 14px;
}

.dropdowns.messaging--dropdown .message .message_data .name_time p {
  clear: both;
  display: block;
}

.dropdowns.messaging--dropdown .message.recent .time {
  color: #0674ec;
}

.dropdowns.messaging_dropdown li a span {
  float: none;
  margin-right: 10px;
}

.dropdowns.dropdown--author {
  padding: 20px;
  right: 0;
}

.dropdowns.dropdown--author:before {
  right: 30px;
}

.dropdowns.dropdown--author ul li a {
  color: #54667a;
  display: block;
  font-size: 15px;
  border-radius: 4px;
  padding: 0 10px;
  line-height: 40px;
  transition: 0.3s ease;
}

.dropdowns.dropdown--author ul li a:hover {
  background: #0674ec;
  color: #fff;
}

.dropdowns.dropdown--author ul li a span {
  float: none;
  margin-right: 15px;
}

.dropdowns.dropdown--cart {
  width: 400px;
  padding: 0;
  right: -15px;
}

.dropdowns.dropdown--cart:before {
  right: 30px;
}

.dropdowns.dropdown--cart .cart_area .cart_product {
  padding: 25px;
  border-bottom: 1px solid #ececec;
}

.dropdowns.dropdown--cart .cart_area .cart_product .product__info {
  width: calc(100% - 52px);
  display: inline-block;
}

.dropdowns.dropdown--cart .cart_area .cart_product .product__info .thumbn {
  display: inline-block;
  height: 70px;
  width: 80px;
  vertical-align: middle;
}

.dropdowns.dropdown--cart .cart_area .cart_product .product__info .info {
  width: calc(100% - 87px);
  display: inline-block;
  vertical-align: middle;
  padding-left: 10px;
}

.dropdowns.dropdown--cart .cart_area .cart_product .product__info .info .title {
  font-weight: 500;
  line-height: 22px;
  color: #000;
  margin-bottom: 9px;
  display: inline-block;
}

.dropdowns.dropdown--cart .cart_area .cart_product .product__info .info .title:hover {
  color: #7347c1;
}

.dropdowns.dropdown--cart .cart_area .cart_product .product__info .info .cat a {
  color: #7347c1;
}

.dropdowns.dropdown--cart .cart_area .cart_product .product__action {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.dropdowns.dropdown--cart .cart_area .cart_product .product__action a:hover span {
  color: #fff;
  background: #ff6a6d;
}

.dropdowns.dropdown--cart .cart_area .cart_product .product__action span {
  width: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 15px;
  display: inline-block;
  color: #0674ec;
  background: rgba(6, 116, 236, 0.1);
  border-radius: 50%;
}

.dropdowns.dropdown--cart .cart_area .cart_product .product__action p {
  background: rgba(6, 116, 236, 0.1);
  color: #0674ec;
  line-height: 30px;
  padding: 0 10px;
  margin-bottom: 0;
  border-radius: 200px;
  margin-top: 10px;
  font-weight: 500;
}

.dropdowns.dropdown--cart .cart_area .total {
  text-align: right;
  padding: 20px 25px;
}

.dropdowns.dropdown--cart .cart_area .total p {
  font-weight: 500;
  color: #000;
  font-size: 18px;
}

.dropdowns.dropdown--cart .cart_area .total p span {
  margin-right: 30px;
}

.dropdowns.dropdown--cart .cart_area .cart_action a {
  width: 50%;
  float: left;
  display: block;
  text-align: center;
  padding: 23px 25px;
  color: #fff;
}

.dropdowns.dropdown--cart .cart_area .cart_action .go_cart {
  background: #7347c1;
}

.dropdowns.dropdown--cart .cart_area .cart_action .go_checkout {
  background: #0674ec;
}

.dropdowns .notification:hover, .dropdowns .message:hover {
  background: rgba(6, 116, 236, 0.05);
}

.dropdowns .dropdown_module_header {
  overflow: hidden;
  padding: 20px 25px;
  border-bottom: 1px solid #ececec;
}

.dropdowns .dropdown_module_header h4 {
  float: left;
}

.dropdowns .dropdown_module_header a {
  float: right;
}

.dropdowns.dropdown--menu {
  padding: 20px;
}

.dropdowns.dropdown--menu:before {
  display: none;
}

.dropdowns.dropdown--menu ul li {
  padding: 0;
  margin: 0;
  display: block;
}

.dropdowns.dropdown--menu ul li a {
  padding: 0 10px;
  text-transform: capitalize;
  font-weight: 400;
  color: #54667a;
  line-height: 44px;
  border-radius: 3px;
  transition: 0.2s;
}

.dropdowns.dropdown--menu ul li a:hover {
  background: #0674ec;
  color: #fff;
}

.dropdowns.dropdown-menu {
  display: inline-block;
}

.open > .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.has_megamenu {
  position: static !important;
}

.has_megamenu:hover .dropdown_megamenu {
  visibility: visible;
  opacity: 1;
  top: 100%;
}

.has_megamenu .dropdown_megamenu {
  background: #fff;
  transition: 0.3s ease;
  left: 0;
  position: absolute;
  width: 1110px;
  visibility: hidden;
  opacity: 0;
  top: calc(100% + 20px);
  padding: 20px;
  z-index: 222;
  box-shadow: 0 5px 40px rgba(82, 85, 90, 0.2);
  border-radius: 0 0 4px 4px;
  border-top: 1px solid #0674ec;
}

.has_megamenu .dropdown_megamenu.contained {
  width: auto;
}

.badge {
  position: absolute;
  top: 5px;
  left: 90%;
  background: #0674ec;
  font-size: 10px;
  color: #fff;
}

.megamnu_module {
  float: left;
}

.megamnu_module + .megamnu_module {
  margin-left: 40px;
}

.megamnu_module .menu_items {
  overflow: hidden;
}

.megamnu_module .menu_items .menu_column {
  min-width: 200px;
  float: left;
}

.megamnu_module .menu_items .menu_column ul li {
  position: relative;
}

.megamnu_module .menu_items .menu_column ul li.has_badge {
  display: inline-block;
}

.megamnu_module .menu_items .menu_column .title {
  font-weight: 500;
  font-size: 17px;
  padding: 10px 10px 15px;
  color: #000;
  display: inline-block;
}

.megamnu_module .menu_items .menu_column + ul {
  margin-left: 20px;
}

.megamnu_module .menu_items .menu_column li a {
  color: #54667a;
  font-size: 15px;
  line-height: 36px;
  padding: 0 10px;
  display: block;
  border-radius: 4px;
  transition: #0674ec;
}

.megamnu_module .menu_items .menu_column li a:hover {
  color: #0674ec;
}

.custom_dropdown {
  padding: 19px 30px;
  min-width: 271px;
  box-shadow: 0 5px 40px rgba(82, 85, 90, 0.2);
  border: 0;
  border-top: 1px solid #0674ec;
  border-radius: 0;
  position: relative;
  top: 10px !important;
}

.custom_dropdown:before {
  content: '';
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #5867dd;
  bottom: 100%;
}

.custom_dropdown li a {
  font-size: 15px;
  line-height: 32px;
  display: block;
  color: #747b86;
}

.custom_dropdown li a span {
  padding-right: 10px;
}

.custom_dropdown li a:hover {
  color: #0674ec;
}

.custom_dropdown.custom_drop2 li a span {
  float: right;
  padding-right: 0;
}

.dropdown-toggle:after {
  content: none;
}

.dropdown-menu {
  animation-name: slidenavAnimation;
  animation-duration: .3s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  -webkit-animation-name: slidenavAnimation;
  -webkit-animation-duration: .3s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: ease;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-name: slidenavAnimation;
  -moz-animation-duration: .3s;
  -moz-animation-iteration-count: 1;
  -moz-animation-timing-function: ease;
  -moz-animation-fill-mode: forwards;
}

@keyframes slidenavAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes slidenavAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.custom_dropdown {
  padding: 19px 30px;
  min-width: 271px;
  box-shadow: 0 5px 40px rgba(82, 85, 90, 0.2);
  border: 0;
  border-top: 1px solid #0674ec;
  border-radius: 0;
  position: relative;
  top: 10px !important;
}

.custom_dropdown:before {
  content: '';
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #5867dd;
  bottom: 100%;
}

.custom_dropdown li a {
  font-size: 15px;
  line-height: 32px;
  display: block;
  color: #747b86;
}

.custom_dropdown li a span {
  padding-right: 10px;
}

.custom_dropdown li a:hover {
  color: #0674ec;
}

.custom_dropdown.custom_drop2 li a span {
  float: right;
  padding-right: 0;
}

/*****************************
	-- THEME
*****************************/
/*****************************
	--- DATE PICKER
*****************************/
div#ui-datepicker-div {
  background: #fff;
  box-shadow: 0 5px 40px rgba(82, 85, 90, 0.2);
}

div#ui-datepicker-div .ui-datepicker-title {
  font-size: 18px;
  color: #fff;
  font-weight: 500;
}

div#ui-datepicker-div .ui-datepicker-header {
  background: #0674ec;
  color: #fff;
  padding: 6px 0;
}

div#ui-datepicker-div .ui-datepicker-prev {
  left: 10px;
}

div#ui-datepicker-div .ui-datepicker-prev:before {
  content: '\e875';
}

div#ui-datepicker-div .ui-datepicker-next {
  text-align: right;
  right: 10px;
}

div#ui-datepicker-div .ui-datepicker-next:before {
  content: "\e876";
}

div#ui-datepicker-div .ui-datepicker-prev,
div#ui-datepicker-div .ui-datepicker-next {
  cursor: pointer;
  top: 46%;
  transform: translateY(-50%);
}

div#ui-datepicker-div .ui-datepicker-prev.ui-datepicker-prev-hover, div#ui-datepicker-div .ui-datepicker-prev.ui-datepicker-next-hover,
div#ui-datepicker-div .ui-datepicker-next.ui-datepicker-prev-hover,
div#ui-datepicker-div .ui-datepicker-next.ui-datepicker-next-hover {
  top: 46%;
  transform: translateY(-50%);
}

div#ui-datepicker-div .ui-datepicker-prev:before,
div#ui-datepicker-div .ui-datepicker-next:before {
  font-family: 'Linearicons-Free', sans-serif;
  color: #fff;
  line-height: 30px;
}

div#ui-datepicker-div table {
  margin: 0;
}

div#ui-datepicker-div thead tr th span {
  color: #555555;
}

div#ui-datepicker-div tbody tr {
  border-top: 1px solid #ececec;
}

div#ui-datepicker-div tbody tr td + td {
  border-left: 1px solid #ececec;
}

div#ui-datepicker-div tbody tr td a {
  color: #54667a;
  margin: 0;
  text-align: center;
}

div#ui-datepicker-div tbody tr td a:hover {
  background: rgba(88, 201, 233, 0.2);
  color: #0674ec;
}

/*****************************
	-- THEME
*****************************/
.alert {
  padding: 18px 30px;
  border-radius: 3px;
  font-size: 16px;
  margin-bottom: 30px;
}

.alert .alert_icon {
  margin-right: 15px;
  font-size: 16px;
}

.alert.alert-default {
  color: #0674ec;
  background: rgba(6, 116, 236, 0.1);
}

.alert.alert-default .close {
  color: #0674ec;
}

.alert.alert-success {
  color: #77de51;
  background: rgba(119, 222, 81, 0.1);
  border-color: rgba(119, 222, 81, 0.1);
}

.alert.alert-info {
  color: #58c9e9;
  background: rgba(88, 201, 233, 0.1);
  border-color: rgba(88, 201, 233, 0.1);
}

.alert.alert-warning {
  color: #feb71d;
  background: rgba(254, 183, 29, 0.1);
  border-color: rgba(254, 183, 29, 0.1);
}

.alert.alert-danger {
  color: #ff6a6d;
  background: rgba(255, 106, 109, 0.1);
  border-color: rgba(255, 106, 109, 0.1);
}

.alert .close {
  font-size: 16px;
  opacity: .3;
}

.alert .close:hover {
  opacity: .5;
}

/*****************************
	-- THEME
*****************************/
/*****************************
	-- THEME
*****************************/
/*****************************
	-- THEME
*****************************/
/*****************************
	-- MIXINS
*****************************/
/* container sizes for various devices */
/* Custom scrollbar */
/*****************************
	-- GENERAL
*****************************/
.preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}

.pcolor {
  color: #0674ec;
}

.scolor {
  color: #7347c1;
}

/* Background Image */
.bgimage {
  position: relative;
}

.bgimage:before {
  z-index: 1;
}

.content_above {
  position: relative;
  z-index: 2;
}

/* Background image holder */
.bg_image_holder {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-size: cover !important;
  background-position: 50% 50% !important;
  z-index: 0;
  transition: opacity .3s linear;
  -webkit-transition: opacity .3s linear;
  opacity: 0;
}

.bg_image_holder img {
  display: none;
}

.or {
  width: 100%;
  background: #ececec;
  height: 1px;
  margin-bottom: 33px;
  position: relative;
}

.or:before {
  content: 'Or';
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  color: #0674ec;
  font-weight: 500;
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.mcolor1 {
  color: #77de51;
}

.mcolor2 {
  color: #feb71d;
}

.mcolor3 {
  color: #58c9e9;
}

.mcolor4 {
  color: #ff6a6d;
}

.pcolorbg {
  background: #0674ec;
}

.scolorbg {
  background: #7347c1;
}

.mcolorbg1 {
  background: #77de51;
}

.mcolorbg2 {
  background: #feb71d;
}

.mcolorbg3 {
  background: #58c9e9;
}

.mcolorbg4 {
  background: #ff6a6d;
}

/* tooltip */
.tooltip {
  display: block;
}

.tooltip .tooltip-inner {
  font-family: "Quicksand", sans-serif;
  font-size: 14px;
  text-align: left;
  display: block;
}

/*.highlighted{
  background: $pcolor;
}*/
.section--padding {
  padding-top: 120px;
  padding-bottom: 120px;
}

.section--padding2 {
  padding: 70px 0 120px;
}

.section--padding3 {
  padding: 70px 0 0;
}

.section-title {
  text-align: center;
  padding-bottom: 53px;
}

.section-title h1 {
  font-weight: 400;
  margin-bottom: 24px;
}

.section-title h1 .highlighted {
  color: #0674ec;
}

.section-title p {
  padding: 0 230px;
  line-height: 30px;
  margin-bottom: 0;
}

.shortcode_wrapper + .shortcode_wrapper {
  margin-top: 80px;
}

/* Input with icons */
.input_with_icon {
  position: relative;
}

.input_with_icon span {
  position: absolute;
  right: 20px;
  top: 50%;
  color: #747b86;
  transform: translateY(-50%);
}

.product-title-area, .shortcode_module_title, .dashboard_contents .dashboard_title_area {
  background: #fff;
  padding: 32px 30px 32px;
  margin-bottom: 30px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-title-area:before, .shortcode_module_title:before, .dashboard_contents .dashboard_title_area:before, .product-title-area:after, .shortcode_module_title:after, .dashboard_contents .dashboard_title_area:after {
  display: table;
  content: "";
  clear: both;
}

.product-title-area .product__slider-nav, .shortcode_module_title .product__slider-nav, .dashboard_contents .dashboard_title_area .product__slider-nav {
  float: right;
  background: #7347c1;
}

.product-title-area .product__slider-nav span, .shortcode_module_title .product__slider-nav span, .dashboard_contents .dashboard_title_area .product__slider-nav span {
  line-height: 45px;
  color: #fff;
  cursor: pointer;
  width: 52px;
  display: inline-block;
  text-align: center;
}

.product-title-area .date_area, .shortcode_module_title .date_area, .dashboard_contents .dashboard_title_area .date_area {
  float: right;
}

.product-title-area .date_area p, .shortcode_module_title .date_area p, .dashboard_contents .dashboard_title_area .date_area p {
  float: left;
}

.product-title-area .date_area .input_with_icon, .shortcode_module_title .date_area .input_with_icon, .dashboard_contents .dashboard_title_area .date_area .input_with_icon {
  max-width: 158px;
}

.product-title-area .date_area .input_with_icon, .shortcode_module_title .date_area .input_with_icon, .dashboard_contents .dashboard_title_area .date_area .input_with_icon, .product-title-area .date_area .select-wrap, .shortcode_module_title .date_area .select-wrap, .dashboard_contents .dashboard_title_area .date_area .select-wrap {
  float: left;
  margin-right: 10px;
}

.product-title-area .date_area .select-wrap select, .shortcode_module_title .date_area .select-wrap select, .dashboard_contents .dashboard_title_area .date_area .select-wrap select {
  height: 47px;
}

.product-title-area .date_area .btn:hover, .shortcode_module_title .date_area .btn:hover, .dashboard_contents .dashboard_title_area .date_area .btn:hover {
  background: #0674ec;
  color: #fff;
}

.product__title {
  float: left;
}

.product__title h2 {
  font-weight: 400;
  line-height: 45px;
}

.filter__menu {
  float: right;
  position: relative;
  margin-top: 10px;
}

.filter__menu p {
  margin-bottom: 0;
  display: inline-block;
  vertical-align: middle;
  color: #747b86;
  margin-right: 15px;
}

.filter__menu .filter__menu_icon {
  display: inline-block;
  vertical-align: middle;
  margin-top: 6px;
  width: 17px;
}

.filter__menu .filter__menu_icon svg {
  height: 16px;
  cursor: pointer;
}

.filter__menu .filter__menu_icon svg:hover circle {
  fill: #0673ec;
}

.filter__menu .filter__menu_icon svg circle {
  fill: #a3b1c4;
}

.filter__menu .filter__menu_icon .filter_dropdown {
  position: absolute;
  min-width: 183px;
  padding: 20px 27px;
  top: 45px !important;
  z-index: 3;
  background: #fff;
  right: -15px;
  left: auto !important;
  box-shadow: 0 5px 40px rgba(82, 85, 90, 0.2);
  transform: translate3d(0, 0, 0) !important;
}

.filter__menu .filter__menu_icon .filter_dropdown.active {
  display: block;
}

.filter__menu .filter__menu_icon .filter_dropdown:before {
  position: absolute;
  content: "";
  border-bottom: 10px solid #fff;
  border-left: 10px solid transparent;
  right: 15px;
  bottom: 100%;
  border-right: 10px solid transparent;
}

.filter__menu .filter__menu_icon .filter_dropdown li a {
  color: #747b86;
  display: block;
  transition: 0.3s ease;
  line-height: 28px;
}

.filter__menu .filter__menu_icon .filter_dropdown li a:hover {
  color: #0674ec;
}

.transparent {
  background: transparent;
}

.rounded, .credit_modules .modules__content .amounts ul li p, .shortcode_modules .modules__content .amounts ul li p, .upload_modules .modules__content .amounts ul li p, .withdraw_module .modules__content .amounts ul li p {
  border-radius: 200px;
}

.tags ul > li {
  display: inline-block;
}

.tags--round ul > li a {
  color: #868c96;
  line-height: 28px;
  background: #f1f3f6;
  display: inline-block;
  padding: 0 17px;
  margin-right: 4px;
  border-radius: 50px;
  transition: 0.3s ease;
}

.tags--round ul > li a:hover {
  background: #7347c1;
  color: #fff;
}

.bgcolor {
  background: #eff1f5;
}

.bgcolor2 {
  background: #f6f7fb;
}

.range-slider.ui-slider {
  height: 6px;
  background: #efedf3;
  border-radius: 6px;
}

.range-slider.ui-slider .ui-slider-range {
  background: #7347c1;
}

.range-slider.ui-slider .ui-slider-handle {
  height: 18px;
  width: 18px;
  background: #0674ec;
  border-radius: 50px;
  top: -6px;
  z-index: 2;
  cursor: pointer;
}

.range-slider.ui-slider .ui-slider-handle:focus {
  outline: 0;
}

.range-slider.ui-slider .ui-slider-handle:before {
  content: '';
  position: absolute;
  height: calc(100% - 8px);
  width: calc(100% - 8px);
  background: #fff;
  left: 4px;
  top: 4px;
  z-index: -1;
  border-radius: 50%;
}

.price-ranges {
  padding-top: 34px;
  text-align: center;
}

.price-ranges span {
  line-height: 32px;
  display: inline-block;
  background: rgba(115, 71, 193, 0.1);
  width: 80px;
  color: #7347c1;
}

.rating {
  display: inline-block;
  vertical-align: middle;
}

.rating ul {
  float: left;
  padding-bottom: 0;
}

.rating ul li {
  float: left;
  margin-right: 3px;
}

.rating ul li span.fa-star, .rating ul li span.fa-star-half-o {
  color: #ffc000;
}

.rating ul li span.fa-star-o {
  color: #7e7e7e;
}

.rating .rating__count {
  display: inline-block;
  color: #6f7d8d;
  vertical-align: middle;
  margin-left: 5px;
}

.custom-checkbox2 input[type='checkbox'], .custom-radio input[type='checkbox'] {
  display: none;
}

.custom-checkbox2 input[type='checkbox']:checked + label span:before, .custom-radio input[type='checkbox']:checked + label span:before, .custom-checkbox2 .form-group input[type='checkbox']:checked + p.label span:before, .form-group .custom-checkbox2 input[type='checkbox']:checked + p.label span:before, .custom-radio .form-group input[type='checkbox']:checked + p.label span:before, .form-group .custom-radio input[type='checkbox']:checked + p.label span:before {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.custom-checkbox2 label, .custom-radio label, .custom-checkbox2 .form-group p.label, .form-group .custom-checkbox2 p.label, .custom-radio .form-group p.label, .form-group .custom-radio p.label {
  font-size: 15px;
  font-weight: 400;
  margin: 0;
  line-height: 36px;
  cursor: pointer;
  text-transform: none;
}

.custom-checkbox2 label span.circle, .custom-radio label span.circle, .custom-checkbox2 .form-group p.label span.circle, .form-group .custom-checkbox2 p.label span.circle, .custom-radio .form-group p.label span.circle, .form-group .custom-radio p.label span.circle {
  height: 20px;
  margin-right: 12px;
  vertical-align: middle;
  width: 20px;
  background: #fff;
  display: inline-block;
  border: 4px solid #e6e9ed;
  border-radius: 50%;
  position: relative;
}

.custom-checkbox2 label span.circle:before, .custom-radio label span.circle:before, .custom-checkbox2 .form-group p.label span.circle:before, .form-group .custom-checkbox2 p.label span.circle:before, .custom-radio .form-group p.label span.circle:before, .form-group .custom-radio p.label span.circle:before {
  content: '\f00c';
  font-family: 'FontAwesome',sans-serif;
  position: absolute;
  height: 20px;
  width: 20px;
  text-align: center;
  font-size: 12px;
  bottom: -4px;
  transition: 0.2s;
  left: -4px;
  opacity: 0;
  transform: scale(0.5);
  border-radius: 50%;
  background: #0674ec;
  color: #fff;
  line-height: 20px;
}

.custom-radio input[type='radio'] {
  display: none;
}

.custom-radio input[type='radio']:checked + label span:before, .custom-radio .form-group input[type='radio']:checked + p.label span:before, .form-group .custom-radio input[type='radio']:checked + p.label span:before {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.custom-radio label span.circle:before, .custom-radio .form-group p.label span.circle:before, .form-group .custom-radio p.label span.circle:before {
  content: "";
  background: #fff;
  border: 4px solid #0674ec;
}

.custom-radio.custom_radio--big span.circle {
  height: 30px;
  width: 30px;
  border-width: 5px;
}

.custom-radio.custom_radio--big span.circle:before {
  width: 30px;
  height: 30px;
  font-size: 16px;
  line-height: 30px;
  border-width: 5px;
}

.radio-group .label {
  display: block;
  text-align: left;
}

.radio-group .custom-radio {
  display: inline-block;
}

.radio-group .custom-radio + .custom-radio {
  margin-left: 30px;
}

/* custom checkbox area css*/
.custom_checkbox {
  position: relative;
  margin-top: 10px;
}

.custom_checkbox input[type='checkbox'] {
  display: none;
}

.custom_checkbox input[type='checkbox']:checked + label .shadow_checkbox, .custom_checkbox .form-group input[type='checkbox']:checked + p.label .shadow_checkbox, .form-group .custom_checkbox input[type='checkbox']:checked + p.label .shadow_checkbox {
  border: transparent;
}

.custom_checkbox input[type='checkbox']:checked + label .shadow_checkbox:before, .custom_checkbox .form-group input[type='checkbox']:checked + p.label .shadow_checkbox:before, .form-group .custom_checkbox input[type='checkbox']:checked + p.label .shadow_checkbox:before {
  visibility: visible;
}

.custom_checkbox label, .custom_checkbox .form-group p.label, .form-group .custom_checkbox p.label {
  margin-bottom: 0;
}

.custom_checkbox label .label_text, .custom_checkbox .form-group p.label .label_text, .form-group .custom_checkbox p.label .label_text {
  font-weight: 400;
  color: #555;
  display: inline-block;
  vertical-align: middle;
  margin-left: 11px;
}

.custom_checkbox label .shadow_checkbox, .custom_checkbox .form-group p.label .shadow_checkbox, .form-group .custom_checkbox p.label .shadow_checkbox {
  height: 18px;
  width: 18px;
  top: 0;
  border: 1px solid #d3d3d3;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  border-radius: 2px;
}

.custom_checkbox label .shadow_checkbox:before, .custom_checkbox .form-group p.label .shadow_checkbox:before, .form-group .custom_checkbox p.label .shadow_checkbox:before {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  content: '\f00c';
  font-size: 12px;
  visibility: hidden;
  text-align: center;
  color: #fff;
  line-height: 18px;
  font-family: FontAwesome, sans-serif;
  background: #0674ec;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

/* custom upload field */
.custom_upload p {
  font-weight: 500;
  font-size: 16px;
  color: #000;
  margin-bottom: 12px;
}

.custom_upload p span {
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
  color: #555;
}

.custom_upload input[type='file'] {
  display: none;
}

/* cardify any area */
.cardify, .content_title, .table_module, .card_style1, .card_style2, .credit_modules, .shortcode_modules, .upload_modules, .withdraw_module, .statement_info_card, .statement_table, .thread_sort_area, .author_info, .comment_area, .contact_tile, .featured_event, .google_map .location_address .addres_module {
  background: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  -moz-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
}

/* common toggle module css */
.toggle_title {
  display: block;
  padding: 30px 30px 25px;
}

.toggle_title span.lnr {
  float: right;
  line-height: 20px;
  font-size: 14px;
}

.toggle_title h4 {
  font-size: 22px;
}

.social_icon {
  display: inline-block;
}

.social_icon span {
  display: inline-block;
  line-height: 45px;
  width: 45px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  text-align: center;
  border-radius: 50%;
  font-size: 16px;
  color: #fff;
}

.social_icon span.fa-facebook {
  background: #395799;
}

.social_icon span.fa-twitter {
  background: #19b2f5;
}

.social_icon span.fa-google-plus {
  background: #f05b44;
}

.social_icon span.fa-behance {
  background: #0057ff;
}

.social_icon span.fa-dribbble {
  background: #ea4c89;
}

.input-group .input-group-addon {
  background: none;
  border: none;
  font-size: 18px;
  color: #555;
  padding-left: 0;
}

.input-group.with--addon {
  align-items: center;
}

.input-group.with--addon .text_field {
  width: calc(100% - 25px);
  margin-left: 14px;
}

/* content area title */
.content_title {
  padding: 30px;
  border-radius: 4px;
  margin-bottom: 30px;
}

/* video modal css */
.video_modal .modal-content iframe {
  width: 100%;
  height: 450px;
}

.messages::-webkit-scrollbar-track {
  background-color: transparent;
}

.messages::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.messages::-webkit-scrollbar-thumb {
  background-color: #e9eef2;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.offcanvas-menu::-webkit-scrollbar-track {
  background-color: transparent;
}

.offcanvas-menu::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.offcanvas-menu::-webkit-scrollbar-thumb {
  background-color: #e9eef2;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

/*****************************
	START FEATURE AREA CSS
*****************************/
.feature_title {
  border-bottom: 1px solid #ececec;
  padding-bottom: 20px;
  margin-bottom: 65px;
}

/*--- feature 1 ---*/
.feature {
  text-align: center;
  padding: 40px 25px;
}

.feature .feature__title {
  padding-top: 30px;
  padding-bottom: 24px;
}

.feature:hover {
  box-shadow: 0 2px 50px rgba(0, 0, 0, 0.08);
  transition: 0.3s ease;
}

/*--- feature 2 ---*/
.feature2 {
  box-shadow: 0 2px 50px rgba(0, 0, 0, 0.08);
  position: relative;
  margin-bottom: 30px;
  background: #fff;
  height: 360px;
  display: table;
}

.feature2 .feature2__count {
  font-size: 48px;
  position: absolute;
  color: rgba(92, 135, 248, 0.2);
  left: 18px;
  top: 4px;
}

.feature2 .feature2__content {
  text-align: center;
  padding: 50px 30px 48px;
}

.feature2 .feature2__content span.lnr {
  font-size: 60px;
}

.feature2 .feature2__content h3 {
  padding: 38px 0 25px;
}

.feature2 .feature2__content p {
  margin: 0;
}

/*--- feature 2 ---*/
.single_feature {
  margin-bottom: 50px;
}

.single_feature .feature__icon {
  display: inline-block;
  vertical-align: 60px;
}

.single_feature .feature__icon span {
  font-size: 48px;
  color: #0674ec;
}

.single_feature .feature__content {
  display: inline-block;
  width: calc(100% - 70px);
  padding-left: 33px;
}

.single_feature .feature__content h3 {
  font-size: 24px;
  line-height: 40px;
}

.single_feature .feature__content p {
  line-height: 30px;
}

/*****************************
	END FEATURE AREA CSS
*****************************/
.content-block-page [class^="content_block"] {
  margin-bottom: 50px !important;
}

/***************************************
   START ABOUT AND MISSION AREA
****************************************/
.content_block1, .content_block2 {
  min-height: 550px;
  display: table;
  margin: 0 auto;
  position: relative;
  width: 100%;
}

.content_block1 .content_area, .content_block2 .content_area, .content_block2 .content_area2 {
  padding: 25% 0;
}

.content_block1 .content_area .content_area--title, .content_block2 .content_area .content_area--title, .content_block2 .content_area2 .content_area--title, .content_block2 .content_area2 .content_area2--title {
  font-size: 36px;
  line-height: 46px;
  margin-bottom: 35px;
  color: #000;
}

.content_block1 .content_area .content_area--title .highlight, .content_block2 .content_area .content_area--title .highlight, .content_block2 .content_area2 .content_area--title .highlight, .content_block2 .content_area2 .content_area2--title .highlight {
  color: #0674ec;
}

.content_block1 .content_image, .content_block2 .content_image, .content_block2 .content_image2 {
  position: absolute;
  right: 0;
  height: 100%;
  width: 50%;
  top: 0;
}

.content_block2 {
  background: #f6f7fb;
}

.content_block2 .content_image2 {
  left: 0;
}

.content_block3 h2 {
  font-size: 30px;
  line-height: 46px;
  color: #000000;
  padding-bottom: 30px;
}

.content_block3 p {
  margin-bottom: 20px;
}

/***************************************
   END ABOUT AND MISSION AREA
****************************************/
/***************************************
     START CARD AREA
****************************************/
/* card style1 */
.card_style1 {
  margin-bottom: 30px;
}

.card_style1 .card_style1__info img {
  width: 100%;
  border-radius: 6px 6px  0 0;
}

.card_style1 .card_style1__info .date_place li {
  margin-bottom: 17px;
}

.card_style1 .card_style1__info .date_place li span {
  color: #0674ec;
  display: inline-block;
  font-size: 20px;
}

.card_style1 .card_style1__info .date_place li span, .card_style1 .card_style1__info .date_place li p {
  display: inline-block;
  vertical-align: middle;
  margin-right: -3px;
}

.card_style1 .card_style1__info .date_place li p {
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  margin-left: 13px;
  margin-bottom: 0;
}

.card_style1 .card_style1__info figcaption {
  padding: 36px 40px;
}

.card_style1 .card_style1__info figcaption a:hover h3 {
  color: #0674ec;
}

.card_style1 .card_style1__info figcaption h3 {
  transition: 0.3s;
  font-weight: 500;
  line-height: 36px;
  font-size: 23px;
  margin-bottom: 17px;
}

/* card style 2 */
.card_style2 {
  padding: 40px;
  margin-bottom: 30px;
}

.card_style2 .card_style2__title {
  color: #000;
  font-size: 24px;
  margin-bottom: 25px;
  line-height: 30px;
}

.card_style2 .card_style2__location_type {
  margin-bottom: 20px;
}

.card_style2 .card_style2__location_type p, .card_style2 .card_style2__location_type .type {
  display: inline-block;
  margin-bottom: 0;
}

.card_style2 .card_style2__location_type p {
  color: #5a6671;
  font-size: 16px;
  line-height: 30px;
  margin-right: 17px;
}

.card_style2 .card_style2__location_type p span {
  color: #0674ec;
  font-size: 20px;
  margin-right: 8px;
  vertical-align: -3px;
}

.card_style2 .card_style2__location_type .type {
  color: #fff;
  line-height: 28px;
  font-weight: 500;
  border-radius: 200px;
  padding: 0 17px;
}

.card_style2 a {
  font-size: 16px;
  line-height: 30px;
  color: #0674ec;
  font-weight: 500;
}

/***************************************
     END CARD AREA
****************************************/
/*****************************
	--- LISTS STYLE
*****************************/
ul.nav-tabs {
  border: 0;
}

ul.nav-tabs li {
  position: relative;
  border-bottom: 1px solid #ececec;
  display: inline-block;
  margin-right: -1px;
}

ul.nav-tabs li + li {
  border-left: 1px solid #ececec;
}

ul.nav-tabs li a {
  color: #000;
  font-size: 17px;
  text-transform: capitalize;
  padding: 0 28px;
  display: block;
  margin-right: 0;
  line-height: 70px;
  border: none;
  transition: 0.3s;
}

ul.nav-tabs li a:hover {
  border: none;
  background: none;
  color: #0674ec;
}

ul.nav-tabs li a span {
  font-weight: 500;
}

ul.nav-tabs li a:before {
  content: "";
  position: absolute;
  height: 3px;
  width: 100%;
  background: #0674ec;
  opacity: 0;
  left: 0;
  transition: 0.3s ease;
  visibility: hidden;
}

ul.nav-tabs li a.active {
  border-bottom: 0;
  border: 0;
  background: none;
  color: #0674ec;
}

ul.nav-tabs li a.active:before {
  opacity: 1;
  visibility: visible;
}

ul.nav-tabs li:last-child > a {
  padding-right: 60px;
}

ul.nav-tabs.nav--tabs2 {
  display: inline-block;
  margin-bottom: -4px;
}

ul.nav-tabs.nav--tabs2 li {
  border: none;
}

ul.nav-tabs.nav--tabs2 li a {
  padding-right: 30px;
}

ul.nav-tabs.nav--tabs2 li a::before {
  bottom: 0;
}

.tab .tab-content {
  margin-top: 30px;
}

.tab2, .tab3 {
  padding: 0 30px 30px;
}

.tab2 .item-navigation, .tab3 .item-navigation {
  text-align: center;
  border-bottom: 1px solid #ececec;
}

.tab3 .item-navigation {
  text-align: left;
}

.tab3 ul.nav-tabs li a {
  padding: 0 50px;
}

.tab4 {
  padding: 30px;
  overflow: hidden;
}

.tab4 .item-navigation {
  float: left;
  width: 20%;
  border-bottom: 0;
  border-left: 1px solid #ececec;
}

.tab4 .tab-content {
  width: 80%;
  float: left;
}

.tab4 ul.nav-tabs li:before {
  height: 100%;
  width: 3px;
}

/*****************************
	START MENU AREA
*****************************/
.top-menu-area {
  background: #eef1f3;
}

.top-menu-area .top__menu ul li {
  display: inline-block;
}

.top-menu-area .top__menu ul li a {
  color: #555;
}

.author-area {
  float: right;
}

.author-area.not_logged_in .join {
  padding: 30px 0;
}

.author-area.not_logged_in .join .btn + .btn {
  margin-left: 20px;
}

.author-area__seller-btn {
  background: #0674ec;
  color: #fff;
  padding: 0 17px;
  display: inline-block;
  line-height: 34px;
  border-radius: 21px;
  font-weight: 500;
  margin-right: 30px;
  transition: 0.3s ease;
}

.author-area__seller-btn:hover {
  background: #7347c1;
  color: #fff;
}

.author__avatar {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.author__avatar span {
  font-size: 14px;
  position: absolute;
  font-weight: 500;
  line-height: 22px;
  width: 22px;
  text-align: center;
  background: #ff0000;
  color: #fff;
  right: -6px;
  top: -7px;
  border-radius: 50%;
}

.autor__info {
  margin-left: 16px;
  margin-right: 0;
  display: inline-block;
  vertical-align: middle;
}

.autor__info p {
  margin: 0;
  line-height: 22px;
}

.autor__info .name {
  font-weight: 500;
  color: #333;
  font-size: 16px;
}

.autor__info .ammount {
  color: #0674ec;
  font-size: 15px;
  font-weight: 400;
}

.author__notification_area {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
}

.author__notification_area ul li {
  display: inline-block;
  padding: 38px 15px;
  position: relative;
}

.author__notification_area ul li span.lnr {
  font-size: 20px;
  color: #7e8fa1;
  vertical-align: middle;
}

.author__notification_area ul li .icon_wrap {
  position: relative;
}

.author__notification_area ul li .notification_count {
  height: 22px;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  top: 0;
  font-size: 14px;
  position: absolute;
  padding: 0 4px;
  vertical-align: middle;
  right: 0;
  min-width: 22px;
  text-align: center;
  transform: translate(50%, -50%);
}

.author__notification_area ul li .notification_count.noti {
  background: #ff6a6d;
}

.author__notification_area ul li .notification_count.msg {
  background: #77de51;
}

.author__notification_area ul li .notification_count.purch {
  background: #0674ec;
}

.author-author__info {
  padding: 24px 0;
  float: right;
}

/* start notification dropdown area */
.has_dropdown {
  position: relative;
}

.has_dropdown.has_megamenu {
  position: initial;
}

.has_dropdown .icon_wrap {
  cursor: pointer;
}

.has_dropdown .dropdowns {
  top: calc(100% + 20px);
}

.has_dropdown:hover .dropdowns {
  visibility: visible;
  opacity: 1;
  top: 100%;
}

/* start mainenu area css */
.mainmenu__menu {
  border: none;
  margin: 0;
  background: #fff;
  padding: 0;
}

.mainmenu__menu .navbar-collapse {
  padding: 0;
  float: left;
}

.mainmenu__menu .navbar-nav > li {
  display: inline-block;
  padding: 15px 0;
  margin-right: 30px;
}

.mainmenu__menu .navbar-nav > li:hover > a {
  color: #0674ec;
}

.mainmenu__menu .navbar-nav > li > a {
  font-weight: 500;
  padding: 0;
  font-size: 15px;
  line-height: 42px;
  transition: 0.3s ease;
  color: #333333;
  text-transform: uppercase;
}

.mainmenu__search {
  float: right;
  padding: 12px 0;
}

.searc-wrap {
  position: relative;
}

.searc-wrap input {
  line-height: 45px;
  height: 45px;
  border: 1px solid #d9dfe5;
  width: 360px;
  border-radius: 23px;
  padding: 0 25px;
}

.searc-wrap .search-wrap__btn {
  position: absolute;
  line-height: 45px;
  right: 0;
  top: 0;
  border: 0;
  width: 45px;
  text-align: center;
  background: #0674ec;
  color: #fff;
  border-radius: 50%;
}

/* mobile menu offcanvas */
.mobile_content .menu_icon {
  background: #0673ec;
  padding: 0 20px;
  line-height: 60px;
  color: white;
  display: none;
  font-size: 16px;
  cursor: pointer;
}

.mobile_content span {
  float: right;
}

.mobile_content .offcanvas-menu {
  position: fixed;
  height: 100%;
  width: 220px;
  top: 0;
  right: 0;
  background: #fff;
  z-index: 989999;
  overflow-y: scroll;
  transition: 0.3s ease;
  box-shadow: 0 -2px 20px rgba(0, 0, 0, 0.3);
  padding-bottom: 25px;
}

.mobile_content .offcanvas-menu.closed {
  right: -100%;
}

.mobile_content .offcanvas-menu span.lnr-cross {
  float: left;
  top: 10px;
  position: absolute;
  left: 13px;
}

.mobile_content .author-author__info {
  background-color: #eff1f5;
  float: none;
  text-align: center;
  border-bottom: 1px solid #ececec;
  padding: 20px 0;
}

.mobile_content .author-author__info .autor__info {
  text-align: left;
  margin-left: 10px;
}

.mobile_content .author__notification_area {
  margin: 0;
  text-align: center;
  display: block;
}

.mobile_content .author__notification_area ul li {
  padding: 27px 12px 14px;
  margin: 0 3px;
}

.mobile_content .dropdown--author {
  visibility: visible;
  position: relative;
  opacity: 1;
  min-width: auto;
  box-shadow: 0 0 0;
}

.mobile_content .dropdown--author:before {
  display: none;
}

.mobile_content .dropdown--author ul li a {
  line-height: 35px;
}

.mobile_content .author-area__seller-btn {
  margin-right: 0;
}

/*****************************
	END MENU AREA CSS
*****************************/
/*****************************
	START HERO AREA CSS
*****************************/
.hero-area {
  position: relative;
}

.hero-area {
  height: 723px;
  position: relative;
  width: 100%;
}

.hero-area::before {
  position: absolute;
  height: 100%;
  width: 100%;
  content: '';
  background: linear-gradient(to left, #0030de, #0030de);
  top: 0;
  left: 0;
  opacity: 0.8;
}

.hero-area.hero--1:before {
  background: linear-gradient(to left, #7347c1, #0674ec);
  opacity: .9;
}

.hero-area .hero-content {
  display: table;
  vertical-align: middle;
  text-align: center;
  width: 100%;
  height: 100%;
}

.hero-area .hero-content .content-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.hero-area .hero__content__title {
  text-align: center;
}

.hero-area .hero__content__title h1 {
  color: #f3f3f8;
  text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.2);
  font-size: 60px;
}

.hero-area .hero__content__title h1 .light {
  font-weight: 400;
  display: block;
}

.hero-area .hero__content__title h1 .bold {
  font-weight: 500;
}

.hero-area .hero__content__title .tagline {
  color: #f3f3f8;
  font-size: 20px;
  font-weight: 500;
  line-height: 72px;
  padding-bottom: 44px;
  margin: 0;
  padding-top: 20px;
}

.hero-area .hero__btn-area .btn {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  transition: 0.3s ease;
}

.hero-area .hero__btn-area .btn:hover {
  color: #0674ec;
  background: #fff;
}

.hero-area .hero__btn-area .btn:first-child {
  margin-right: 26px;
}

.hero-area + section {
  padding-top: 187px;
}

.hero--1 {
  background: no-repeat center 207px;
}

.hero-area.hero--2 .hero-content {
  text-align: left;
}

.hero-area.hero--2:before {
  display: none;
}

.hero-area.hero--2 .hero__content__title {
  text-align: left;
}

.hero-area.hero--2 .hero__content__title h1 {
  font-weight: 400;
  font-size: 50px;
  line-height: 62px;
  color: #0674ec;
  text-shadow: none;
  background: linear-gradient(to left, #7347c1, #0674ec);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-area.hero--2 .hero__content__title .tagline {
  line-height: 36px;
  font-weight: 400;
  color: #6f7d8d;
}

.hero-area.hero--2 .hero__btn-area .btn {
  background: #0674ec;
  color: #fff;
  border: 0;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
}

.hero-area.hero--2 .hero__btn-area .btn:hover {
  background: #fff;
  color: #0674ec;
}

.hero-area.hero--2 .search_box::before, .hero-area.hero--2 .search_box:after {
  background: rgba(6, 116, 236, 0.1);
}

.go_top {
  line-height: 40px;
  cursor: pointer;
  width: 40px;
  background: #0674ec;
  color: #fff;
  position: fixed;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  right: calc((100% - 1140px)/2);
  z-index: 111;
  bottom: 20px;
}

.go_top span {
  display: inline-block;
}

.search-area {
  position: absolute;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  width: 100%;
  -o-transform: translateY(-50%);
  z-index: 2;
  transform: translateY(-50%);
}

.search_box {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  padding: 40px;
  border-radius: 6px;
  position: relative;
  background: #fff;
}

.search_box form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.search_box .text_field {
  flex: 2;
  line-height: 55px;
  height: 55px;
  padding: 0 25px;
  font-size: 16px;
  border-radius: 4px 0 0 4px;
  border-right: 0;
  background: #fff;
  border: 1px solid #dcdcdc;
}

.search_box .select--field {
  border-radius: 0 4px 4px 0;
  font-size: 16px;
  padding: 0 52px 0 20px;
  height: 55px;
  border: 1px solid #dcdcdc;
  border-left: 0;
  color: #555;
}

.search_box .search-btn {
  margin-left: 30px;
  transition: 0.3s ease;
  cursor: pointer;
}

.search_box .search-btn:hover {
  background: #7347c1;
}

.search_box::before, .search_box:after {
  position: absolute;
  content: '';
  background: rgba(255, 255, 255, 0.5);
  border-radius: 6px;
  height: 100%;
  z-index: -1;
}

.search_box::before {
  left: 15px;
  top: -13px;
  width: calc(100% - 30px);
}

.search_box:after {
  left: 30px;
  width: calc(100% - 60px);
  top: -26px;
}

/*****************************
	END HERO AREA CSS
*****************************/
/*****************************
	START PRODUCTS AREA CSS
*****************************/
.home1 .products {
  background: #f6f7fb;
}

.products {
  background: #eff1f5;
}

.home2 .product__title a {
  display: inline-block;
  transition: 0.3s ease;
}

.home2 .product__title a:hover h2 {
  color: #000;
}

.home2 .product__title a + a {
  margin-left: 20px;
}

.home2 .product__title a + a h2 {
  color: #747b86;
}

.sorting {
  background: #fff;
  padding: 30px;
  border-radius: 4px;
  margin-bottom: 50px;
  box-shadow: 0 1px 2px rgba(90, 93, 100, 0.1);
}

.sorting ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.sorting ul li {
  margin: 0 4px;
}

.sorting ul li a {
  font-size: 15px;
  border-radius: 100px;
  font-weight: 500;
  line-height: 34px;
  transition: 0.3s ease;
  padding: 0 21px;
  background: #f1f3f6;
  color: #747b86;
  display: inline-block;
}

.sorting ul li a:hover {
  color: #fff;
  background: #0674ec;
}

.more-product {
  text-align: center;
  margin-top: 30px;
}

.more-product a {
  transition: 0.3s ease;
}

.more-product a:hover {
  background: #7347c1;
  color: #fff;
}

.product_row:before, .product_row:after {
  display: table;
  content: '';
  clear: both;
}

/*****************************
	END PRODUCTS AREA CSS
*****************************/
/***************************************
	START FEATURED PRODUCTS AREA CSS
*****************************************/
/* home 3 featured area */
.prod_slide_prev, .prod_slide_next {
  position: absolute;
  line-height: 50px;
  width: 50px;
  font-size: 14px;
  background: #fff;
  text-align: center;
  top: 50%;
  border-radius: 200px;
  z-index: 3;
  box-shadow: 0 4px 15px rgba(67, 67, 67, 0.15);
  cursor: pointer;
}

.prod_slide_prev:active, .prod_slide_next:active {
  background: #f7f7f7;
}

.prod_slide_prev {
  left: 15px;
  transform: translate(-50%, -50%);
}

.prod_slide_next {
  right: 15px;
  transform: translate(50%, -50%);
}

.featured-products .product-title-area span.lnr:active, .featured-products .shortcode_module_title span.lnr:active, .featured-products .dashboard_contents .dashboard_title_area span.lnr:active, .dashboard_contents .featured-products .dashboard_title_area span.lnr:active {
  background: #6b3fba;
}

.product__slider-nav span.lnr.lnr-chevron-left {
  border-radius: 200px 0 0 200px;
}

.product__slider-nav span.lnr.lnr-chevron-right {
  border-radius: 0 200px 200px 0;
}

.featured-product-slider {
  overflow: hidden;
  border-radius: 4px;
  margin-top: 20px;
  box-shadow: 0 2px 30px rgba(147, 155, 168, 0.1);
}

.featured-product-slider .featured__single-slider {
  background: #fff;
  border-radius: 4px;
  height: 450px;
}

.featured-product-slider .featured__single-slider:before, .featured-product-slider .featured__single-slider:after {
  content: '';
  display: table;
  clear: both;
}

.featured-product-slider .featured__single-slider:hover .featured__preview-img:before {
  opacity: 0.9;
}

.featured-product-slider .featured__single-slider:hover .prod_btn {
  opacity: 1;
  top: 50%;
}

.featured-product-slider .featured__preview-img, .featured-product-slider .featured__product-description {
  float: left;
  width: 50%;
}

.featured-product-slider .featured__preview-img {
  position: relative;
}

.featured-product-slider .featured__preview-img .prod_btn {
  position: absolute;
  transition: 0.3s ease;
  z-index: 3;
  top: calc(50% + 40px);
  opacity: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.featured-product-slider .featured__preview-img .prod_btn a {
  transition: 0.3s ease;
  text-align: center;
  color: #fff;
  border: 1px solid #fff;
  display: inline-block;
  font-weight: 500;
  min-width: 130px;
  line-height: 45px;
}

.featured-product-slider .featured__preview-img .prod_btn a + a {
  margin-left: 10px;
}

.featured-product-slider .featured__preview-img .prod_btn a:hover {
  background: #fff;
  color: #0674ec;
}

.featured-product-slider .featured__preview-img:before {
  position: absolute;
  content: "";
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  height: 100%;
  opacity: 0;
  z-index: 2;
  -webkit-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
  width: 100%;
  top: 0;
  background: linear-gradient(to left, #7347c1, #0674ec);
}

.featured-product-slider .featured__product-description {
  padding: 41px 50px 0 50px;
  height: 100%;
  position: relative;
}

.featured-product-slider .featured__product-description .product_title h4 {
  font-size: 24px;
}

.featured-product-slider .featured__product-description .product-purchase .price_love > span {
  background: #0674ec;
  color: #fff;
  margin-right: 17px;
}

.featured-product-slider .featured__preview-img img {
  width: 100%;
  border-radius: 4px 0 0 4px;
}

.desc--featured {
  max-height: 256px;
  overflow: hidden;
  padding: 0;
}

.desc--featured .tags {
  margin-top: 38px;
}

.product_data {
  position: absolute;
  width: 100%;
  left: 0;
  padding: 0 50px;
  bottom: 0;
}

.product-purchase.featured--product-purchase {
  padding: 23px 0;
  margin-top: 30px;
}

.product-purchase.featured--product-purchase .rating {
  float: right;
}

.product-purchase.featured--product-purchase .sell {
  float: none;
  margin-left: 13px;
}

/***************************************
	END FEATURED PRODUCTS AREA CSS
*****************************************/
/***************************************
	START FOLLOWERS FEED AREA CSS
*****************************************/
.followers-feed {
  background: #eff1f5;
}

.followers-feed .product__slider-nav {
  background: #0674ec;
}

.followers-feed .product__slider-nav span.lnr:active:active {
  background: #066ddd;
}

/***************************************
	END FOLLOWERS FEED AREA CSS
*****************************************/
/***************************************
	START COUNTER UP AREA CSS
*****************************************/
.counter-up-area {
  position: relative;
}

.counter-up-area:before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  background: rgba(13, 15, 19, 0.8);
}

.counter-up-area.counter-up--area2 {
  background-image: none;
}

.counter-up-area.counter-up--area2:before {
  display: none;
}

.counter-up-area.counter-up--area2 .counter-up {
  padding: 60px 0px;
}

.counter-up-area.counter-up--area2 .counter-up .counter p {
  color: #555;
}

span.counter {
  display: block;
}

.counter-up {
  overflow: hidden;
  padding: 96px 0;
}

.counter-up .counter {
  width: 25%;
  float: left;
  text-align: center;
}

.counter-up .counter span.lnr {
  display: block;
  font-size: 48px;
}

.counter-up .counter .count {
  font-size: 36px;
  margin-top: 35px;
  display: inline-block;
}

.counter-up .counter p {
  color: #ffffff;
  margin-top: 7px;
  font-size: 18px;
  margin-bottom: 0;
}

/***************************************
	END COUNTER UP AREA CSS
*****************************************/
/***************************************
	START SELL BUY AREA
*****************************************/
.proposal-area {
  overflow: hidden;
}

.proposal {
  text-align: center;
  padding: 100px 19%;
}

.proposal .proposal__content {
  padding: 41px 0 35px;
}

.proposal .proposal__content h1 {
  padding-bottom: 24px;
}

.proposal--left {
  background: #0674ec no-repeat bottom;
}

.proposal--right {
  background: #7347c1 no-repeat bottom;
}

/***************************************
	END SELL BUY AREA
*****************************************/
/***************************************
	START TESTIMONIAL AREA
*****************************************/
.testimonial {
  padding: 50px 40px;
  border-left: 2px solid #0674ec;
  box-shadow: 0 2px 50px rgba(0, 0, 0, 0.08);
  background: #fff;
  margin: 30px 15px;
}

.testimonial:focus {
  outline: 0;
}

.testimonial .testimonial__about {
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
}

.testimonial .testimonial__about .avatar {
  height: 80px;
  width: 80px;
  display: inline-block;
}

.testimonial .testimonial__about .avatar img {
  border-radius: 50%;
  max-width: 100%;
}

.testimonial .testimonial__about .quote-icon {
  position: absolute;
  font-size: 60px;
  color: #e8e8e8;
  right: 0;
  z-index: -1;
}

.testimonial .testimonial__about .name-designation {
  padding-left: 20px;
  display: inline-block;
}

.testimonial .testimonial__about .name-designation .name {
  font-size: 20px;
  margin-bottom: 4px;
}

.testimonial .testimonial__about .name-designation .desig {
  color: #555;
  font-size: 16px;
}

.testimonial .testimonial__text p {
  margin: 0;
}

.all-testimonial {
  text-align: center;
  margin-top: 30px;
}

.testimonial-slider {
  position: relative;
}

.testimonial-slider .slick-arrow {
  position: absolute;
  cursor: pointer;
  background: #fff;
  line-height: 50px;
  width: 50px;
  border-radius: 50%;
  box-shadow: 0 4px 15px rgba(67, 67, 67, 0.15);
  text-align: center;
  color: #7347c1;
  top: 50%;
  z-index: 9;
}

.testimonial-slider .slick-arrow.lnr-chevron-left {
  left: 0;
  transform: translate(-50%, -50%);
}

.testimonial-slider .slick-arrow.lnr-chevron-right {
  right: 0;
  transform: translate(50%, -50%);
}

/***************************************
    END TESTIMONIAL AREA
*****************************************/
/***************************************
	START LATEST NEWS AREA
*****************************************/
.latest-news {
  background: #eff1f5;
}

.news {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  transition: 0.3s ease;
}

.news .news__thumbnail img {
  border-radius: 5px 5px 0 0;
  width: 100%;
}

.news .news__content {
  padding: 30px 30px 0;
  height: 185px;
  overflow: hidden;
}

.news .news__content p {
  margin: 0;
}

.news .news-title {
  margin-bottom: 20px;
  display: inline-block;
}

.news .news-title:hover h4 {
  color: #0674ec;
}

.news .news-title h4 {
  font-size: 22px;
  transition: 0.3s ease;
  font-weight: 500;
}

.news .news__meta {
  overflow: hidden;
  padding: 14px 30px;
  border-top: 1px solid #ececec;
  margin-top: 10px;
}

.news .news__meta span.lnr {
  font-size: 15px;
  color: #7e7e7e;
}

.news .news__meta .date {
  float: left;
}

.news .news__meta .date span.lnr {
  color: #0674ec;
}

.news .news__meta .date p {
  color: #999;
  margin: 0;
}

.news .news__meta .date span, .news .news__meta .date p {
  display: inline-block;
  line-height: 30px;
}

.news .news__meta .other {
  float: right;
  width: 50%;
  text-align: right;
  border-left: 1px solid #ececec;
}

.news .news__meta .other ul li {
  display: inline-block;
}

.news .news__meta .other ul li span {
  color: #7e7e7e;
  line-height: 30px;
  display: inline-block;
  vertical-align: middle;
}

.news .news__meta .other li + li {
  margin-left: 28px;
}

.news:hover {
  box-shadow: 0 2px 50px rgba(108, 111, 115, 0.1);
}

/***************************************
	END TESTIMONIAL AREA
*****************************************/
/***************************************
	START SPECIAL FEATURE AREA
*****************************************/
.special-feature-area.special-feature--2 {
  padding: 0;
}

.special-feature-area.special-feature--2 .special-feature {
  padding: 55px 0;
}

.special-feature {
  padding: 55px 0;
  text-align: center;
}

.special-feature .special__feature-title {
  padding-top: 28px;
}

.special__feature-title .highlight {
  color: #0674ec;
}

.feature--2 {
  position: relative;
}

.feature--2:before {
  content: '';
  position: absolute;
  width: 1px;
  height: 100%;
  left: -15px;
  top: 0;
  background: #ececec;
}

/***************************************
	END SPECIAL FEATURE AREA
*****************************************/
/***************************************
	START CALL TO ACTION AREA
*****************************************/
.call-to-action {
  position: relative;
  padding: 110px 0;
}

.call-to-action:before {
  content: "";
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
  background: linear-gradient(to left, #7347c1, #0674ec);
  opacity: .9;
}

.call-to-wrap {
  text-align: center;
}

.call-to-wrap h4 {
  font-size: 18px;
  margin-top: 24px;
}

.callto-action-btn {
  margin-top: 50px;
}

/***************************************
	END CALL TO ACTION AREA
*****************************************/
/***************************************
    START FOOTER AREA
*****************************************/
.footer-area.footer--light .footer-big {
  background: #eff1f5;
}

.footer-area.footer--light .mini-footer {
  background: #e2e5eb;
}

.footer-area.footer--light .mini-footer p {
  color: #5e6c7c;
}

.footer-area.footer--light .mini-footer p a {
  color: #0674ec;
}

.footer-area.footer--light .footer-menu ul li a {
  color: #737373;
}

.footer-area.footer--light .footer-menu ul li a:hover {
  color: #000;
}

.footer-big {
  background: #262b30;
}

.footer-big p, .footer-big ul li, .footer-big .footer-menu ul li a {
  color: #ababab;
}

.info-contact {
  margin-top: 21px;
}

.info-contact li {
  font-size: 16px;
  margin-bottom: 12px;
}

.info-contact li .info-icon, .info-contact li .info {
  display: inline-block;
  vertical-align: middle;
}

.info-contact li .info-icon {
  color: #fff;
}

.info-contact li .info {
  width: calc(100% - 30px);
  padding-left: 8px;
}

.info__logo {
  margin-bottom: 25px;
}

.footer-menu {
  display: inline-block;
  width: 49%;
  padding-left: 48px;
}

.footer-menu.footer-menu--1 {
  width: auto;
}

.footer-menu ul li a {
  font-size: 15px;
  line-height: 32px;
  transition: 0.3s ease;
}

.footer-menu ul li a:hover {
  color: #fff;
}

.footer-widget-title {
  line-height: 42px;
  margin-bottom: 10px;
}

.field-wrapper {
  position: relative;
  margin-top: 32px;
  margin-bottom: 48px;
}

.field-wrapper .relative-field {
  line-height: 50px;
  height: 50px;
}

.field-wrapper .btn {
  position: absolute;
  right: 0;
  height: 100%;
  padding: 0 30px;
  top: 0;
}

.mini-footer {
  background: #192027;
  text-align: center;
  padding: 32px 0;
}

.mini-footer p {
  margin: 0;
  line-height: 26px;
  font-size: 15px;
  color: #999;
}

.mini-footer p a {
  color: #fff;
}

.mini-footer p a:hover {
  color: #0674ec;
}

/***************************************
	END FOOTER AREA
*****************************************/
/***************************************
	START PROMOTION AREA
*****************************************/
.promotion-area {
  padding: 140px 0;
  background: url("../images/bundlebg.jpg");
  background-size: cover;
}

.promotion-img img {
  border-radius: 8px;
  box-shadow: 0 6px 40px rgba(0, 0, 0, 0.15);
  width: 100%;
}

.promotion-content .promotion__subtitle {
  line-height: 72px;
  color: #0674ec;
}

.promotion-content .promotion__title {
  font-size: 38px;
  line-height: 48px;
}

.promotion-content .promotion__title span {
  color: #7347c1;
  font-weight: 500;
}

.promotion-content p {
  color: #333333;
  line-height: 32px;
  margin: 35px 0 43px;
}

.promotion-content .btn {
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

/***************************************
	END PROMOTION AREA
****************************************/
/***************************************
	START OVERVIEW AREA
****************************************/
.step_num {
  color: #0674ec;
  opacity: .20;
  font-size: 60px;
  font-weight: 300;
}

.overview-title {
  font-size: 30px;
  line-height: 46px;
  margin-bottom: 28px;
}

.overview-icon img {
  width: 100%;
}

.overview_cont {
  margin-bottom: 60px;
}

/***************************************
	END OVERVIEW AREA
****************************************/
/***************************************
	START PARTNER AREA
****************************************/
.partner-area {
  background: #f6f7fb no-repeat center;
}

.partner-area.partner--area2 {
  background: #fff  no-repeat center;
}

.partners {
  margin-top: 30px;
}

.partners .partner {
  display: inline-block;
}

.partners .partner + .partner {
  margin-left: 90px;
}

/***************************************
	END PARTNER AREA
****************************************/
/***************************************
	START SEARCH AREA2
****************************************/
.search-area2, .breadcrumb-area {
  position: relative;
  padding-top: 64px;
}

.search-area2:before, .breadcrumb-area:before {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  background: linear-gradient(to left, #7347c1, #0674ec);
  opacity: 0.91;
  top: 0;
  left: 0;
}

.search {
  text-align: center;
}

.search .search__title {
  padding-bottom: 30px;
}

.search .search__title h3 {
  font-size: 26px;
  color: #fff;
}

.search .search__title h3 span {
  font-weight: 500;
}

.search .search__field {
  padding: 0 100px;
}

.search .field-wrapper {
  margin: 0;
}

.filter-area {
  position: relative;
  -webkit-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  -moz-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
}

.filter-bar .filter__option {
  display: inline-block;
  vertical-align: middle;
  padding: 29px 0;
}

.filter-bar .filter__option p {
  font-size: 24px;
  color: #000;
  line-height: 46px;
  margin-bottom: 0;
}

.filter-bar .filter__option p span {
  font-weight: 500;
}

.filter-bar .dropdown-trigger {
  font-size: 16px;
  line-height: 46px;
  display: inline-block;
  padding: 0 30px;
  color: #555555;
}

.filter-bar .dropdown-trigger span {
  font-size: 10px;
  margin-left: 6px;
}

.filter-bar .filter--dropdown {
  position: relative;
  border-right: 1px solid #ececec;
}

.filter-bar .filter--dropdown.filter--range .dropdowns {
  width: 350px;
}

.filter-bar .filter--dropdown.filter--range .dropdowns .range-slider.ui-slider {
  margin-top: 14px;
}

.filter-bar .filter--dropdown .dropdowns {
  top: calc(100% - 20px);
}

.filter-bar .filter--dropdown:first-child {
  border-left: 1px solid #ececec;
}

.filter-bar .filter--dropdown:first-child .dropdown-trigger {
  display: block;
}

.filter-bar .filter--select {
  width: 217px;
  margin: 0 23.93px;
}

.filter-bar .filter--text a {
  font-size: 16px;
  color: #555555;
  line-height: 42px;
  transition: 0.3s ease;
}

.filter-bar .filter--text a:hover {
  color: #0674ec;
}

.filter-bar .filter--text a + a {
  margin-left: 40px;
}

.filter-bar.filter-bar2 {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 4px;
  margin-bottom: 30px;
  padding-left: 30px;
}

.filter-bar.filter-bar2 .filter__option {
  padding: 20px 0;
}

.select-wrap {
  position: relative;
}

.select-wrap select {
  height: 45px;
  border: 1px solid #ececec;
  padding: 0 40px 0 020px;
  border-radius: 4px;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
}

.select-wrap span {
  font-size: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 18px;
}

.select-wrap.select-wrap2 select {
  background: #f4f5f8;
  height: 50px;
  border-radius: 0;
  border: none;
}

.select-wrap.select-wrap2 select[multiple] {
  height: 80px;
}

.select-wrap.select-wrap2 span {
  font-size: 12px;
  color: #747a86;
}

.filter__option.filter--layout {
  padding: 10px 20px 11px;
  border: 1px solid #ececec;
  border-radius: 4px;
}

.filter__option.filter--layout .svg-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.filter__option.filter--layout .svg-icon .svg {
  fill: #747b86;
}

.filter__option.filter--layout .svg-icon + .svg-icon {
  margin-left: 10px;
}

/***************************************
	END SEARCH AREA2
****************************************/
/***************************************
    START SINGLE PRODUCT DESCRIPTION AREA
****************************************/
.single-product-desc {
  background: #eff1f5;
  padding: 70px 0;
}

.single-product-desc2 .item-navigation {
  text-align: center;
  border-bottom: 1px solid #ececec;
}

.single-product-desc2 .item-info {
  padding: 0 30px 30px;
}

.single-product-desc2 .sidebar--single-product .card-pricing .price {
  box-shadow: none;
}

.single-product-desc2 .item-info .tab-content #product-details .item_social_share {
  border-top: 1px solid #ececec;
}

.single-product-desc2 .item-info .tab-content #product-details .item_social_share p, .single-product-desc2 .item-info .tab-content #product-details .item_social_share img {
  margin-bottom: 0;
}

.single-product-desc2 .item-info .tab-content #product-details .item_social_share .social ul, .single-product-desc2 .item-info .tab-content #product-details .item_social_share .single_blog_content .share_tags .share .social_share ul, .single_blog_content .share_tags .share .single-product-desc2 .item-info .tab-content #product-details .item_social_share .social_share ul {
  padding-bottom: 0;
}

.single-product-desc2 .item-info .tab-content #product-details .item_social_share .social ul li:before, .single-product-desc2 .item-info .tab-content #product-details .item_social_share .single_blog_content .share_tags .share .social_share ul li:before, .single_blog_content .share_tags .share .single-product-desc2 .item-info .tab-content #product-details .item_social_share .social_share ul li:before {
  display: none;
}

/* ********* items preview area ********** */
.item-preview {
  /* item preview2 style */
}

.item-preview .prev-slide img {
  border-radius: 4px 4px 0 0;
}

.item-preview img {
  max-width: 100%;
}

.item-preview .item__preview-thumb {
  padding: 30px;
}

.item-preview .item__preview-thumb .thumb-slider {
  width: calc(100% - 164px);
  display: inline-block;
  vertical-align: middle;
  margin-right: -3px;
}

.item-preview .item__preview-thumb .item-thumb {
  margin-right: 3px;
  cursor: pointer;
  outline: 0;
}

.item-preview .prev-nav {
  display: inline-block;
  vertical-align: middle;
  margin-right: -3px;
  display: inline-block;
  padding-left: 14px;
}

.item-preview .prev-nav span {
  line-height: 35px;
  font-size: 16px;
  padding: 0 15px;
  background: #0674ec;
  border-radius: 200px;
  cursor: pointer;
  display: inline-block;
  color: #fff;
}

.item-preview .prev-nav span:active {
  background: #6b3fba;
}

.item-preview .prev-nav span:hover {
  background: #7347c1;
  transition: 0.3s ease;
}

.item-preview .prev-nav span + span {
  margin-left: 16px;
}

.item-preview .item-action {
  border-top: 1px solid #ececec;
  padding-top: 30px;
  text-align: center;
}

.item-preview .item-action a:hover {
  color: #fff;
  background: #7347c1;
  transition: 0.3s ease;
}

.item-preview .item-action a + a {
  margin-left: 25px;
}

.item-preview .prev-thumb {
  padding-bottom: 30px;
}

.item-preview .item_social_share {
  padding-top: 30px;
  text-align: center;
}

.item-preview .item_social_share p {
  margin-right: 30px;
  display: inline-block;
}

.item-preview .item_social_share p img {
  width: 20px;
}

.item-preview .item_social_share p span {
  display: inline-block;
  vertical-align: middle;
  line-height: 20px;
}

.item-preview.item-preview2 .item-action {
  padding-top: 0;
  border-top: 0;
  border-bottom: 1px solid #ececec;
  padding-bottom: 30px;
}

/* ********* items detail information area ********** */
.item-info {
  -webkit-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  -moz-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  border-radius: 4px;
  background: #fff;
}

.item-info .tab-content-wrapper {
  padding: 30px;
}

.item-info .tab-content {
  margin-top: 50px;
}

.tab-content-wrapper {
  padding: 30px;
}

#product-details h1 {
  font-size: 28px;
  padding-bottom: 35px;
}

#product-details h1 + p, #product-details p:last-child {
  margin-bottom: 62px;
}

#product-details img {
  margin-bottom: 65px;
}

#product-details h2 {
  font-size: 24px;
  padding-bottom: 22px;
}

#product-details li {
  font-size: 16px;
  line-height: 34px;
}

#product-details .item_social_share img {
  margin-bottom: 0;
}

#product-details .social ul li:before, #product-details .single_blog_content .share_tags .share .social_share ul li:before, .single_blog_content .share_tags .share #product-details .social_share ul li:before {
  display: none;
}

#product-details ol {
  padding-left: 20px;
  padding-bottom: 55px;
}

#product-details ol li {
  list-style: decimal;
}

#product-details ul {
  padding-bottom: 55px;
}

#product-details ul li:before {
  content: url("../images/check.png");
  margin-right: 15px;
}

#product-details iframe {
  width: 100%;
}

/* ********* items comment area********** */
.thread {
  /* ********* items review area********** */
  /* thread review2 */
}

.thread .thread-list .single-thread {
  border-bottom: 1px solid #ececec;
  /* nested comment area css */
}

.thread .thread-list .single-thread .media {
  padding: 30px 30px 26px;
}

.thread .thread-list .single-thread .media-left {
  padding-right: 19px;
}

.thread .thread-list .single-thread .media-left a {
  display: inline-block;
  height: 70px;
  width: 70px;
}

.thread .thread-list .single-thread .media-left a img {
  max-width: 100%;
}

.thread .thread-list .single-thread .media-body .media-heading, .thread .thread-list .single-thread .media-body .comment-tag {
  display: inline-block;
}

.thread .thread-list .single-thread .media-body .media-heading h4 {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
  line-height: 26px;
}

.thread .thread-list .single-thread .media-body .media-heading span {
  color: #7e7e7e;
  font-size: 15px;
  line-height: 26px;
}

.thread .thread-list .single-thread .media-body .comment-tag {
  vertical-align: top;
  font-size: 12px;
  color: #fff;
  line-height: 28px;
  text-transform: capitalize;
  border-radius: 200px;
  font-weight: 500;
  padding: 0 10px;
  margin-left: 15px;
}

.thread .thread-list .single-thread .media-body .comment-tag.buyer {
  background: #7347c1;
}

.thread .thread-list .single-thread .media-body .comment-tag.author {
  background: #0674ec;
}

.thread .thread-list .single-thread .media-body a.reply-link {
  float: right;
  display: inline-block;
  font-size: 16px;
  color: #0674ec;
  padding-top: 18px;
}

.thread .thread-list .single-thread .media-body a.reply-link:hover {
  color: #7347c1;
}

.thread .thread-list .single-thread .media-body p {
  padding-top: 14px;
  margin-bottom: 0;
}

.thread .thread-list .single-thread .depth-2 {
  margin-left: 93px;
  border-top: 1px solid #ececec;
  border-bottom: 0;
}

.thread.thread_review .thread-list .single-thread.depth-2 .media-body .media-heading {
  display: inline-block;
}

.thread.thread_review .thread-list .single-thread.depth-2 .media-body .media-heading span {
  margin: 0;
}

.thread.thread_review .thread-list .single-thread .media-body .media-heading {
  display: block;
}

.thread.thread_review .thread-list .single-thread .media-body .media-heading a {
  display: inline-block;
}

.thread.thread_review .thread-list .single-thread .media-body .media-heading span {
  margin-left: 20px;
}

.thread.thread_review .thread-list .single-thread .media-body .media-heading .rev_item {
  margin-left: 15px;
  color: #0674ec;
}

.thread.thread_review .thread-list .single-thread .media-body p {
  padding-top: 18px;
}

.thread.thread_review .thread-list .single-thread .media-body .rating {
  display: inline-block;
}

.thread.thread_review .thread-list .review_tag {
  background: rgba(115, 71, 193, 0.1);
  border-radius: 200px;
  line-height: 30px;
  padding: 0 12px;
  display: inline-block;
  color: #7347c1;
  font-weight: 500;
  margin-left: 10px;
}

.thread.thread_review2 {
  -webkit-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  -moz-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  background: #fff;
  border-radius: 4px;
}

.thread.thread_review2 .rev_time {
  font-size: 15px;
  color: #7e7e7e;
  line-height: 26px;
}

/* comment reply form */
.comment-reply-form textarea, .comment-form-area .comment-form textarea {
  height: 79px;
  background: #eff1f5;
  border: none;
  resize: none;
  border-radius: 4px;
  padding: 15px;
}

.comment-reply-form button, .comment-form-area .comment-form button {
  margin-top: 20px;
}

.comment-form-area {
  padding: 30px;
}

.comment-form-area h4 {
  color: #000;
  font-size: 20px;
  line-height: 26px;
  padding-bottom: 20px;
}

.comment-form-area .comment-form .media-left {
  margin-right: 15px;
}

.comment-form-area .support__comment .trumbowyg-box {
  margin-top: 0;
}

/* ********* item support area ********** */
.support .support__title {
  padding: 30px;
  border-bottom: 1px solid #ececec;
}

.support .support__title h3 {
  font-size: 22px;
  line-height: 26px;
}

.support .support__form {
  padding: 30px;
}

.support .support__form .usr-msg p {
  color: #333;
  margin-bottom: 26px;
}

.support .support__form .usr-msg p a {
  color: #0674ec;
}

.support .support__form label, .support .support__form .form-group p.label, .form-group .support .support__form p.label {
  font-size: 16px;
  line-height: 32px;
  margin-bottom: 15px;
}

/* ********* related item area ********** */
.more_product_area {
  background: #f6f7fb;
}

.more_product_area .section-title h1 {
  margin-bottom: 0;
  font-size: 30px;
}

/***************************************
	END PRODUCT DESCRIPTION AREA
****************************************/
/***************************************
	START OVERVIEW AREA
****************************************/
.single-feature {
  padding: 70px 0;
}

.single-feature p {
  line-height: 30px;
}

.single-feature img {
  width: 100%;
  box-shadow: 0 5px 50px rgba(0, 0, 0, 0.15);
}

.single-feature ul {
  padding-left: 20px;
}

.single-feature ul li {
  list-style: disc;
  line-height: 30px;
  font-size: 16px;
}

/***************************************
	END OVERVIEW AREA
****************************************/
/***************************************
	START AUTHOR AREA
****************************************/
.author-profile-area {
  background: #eff1f5;
  padding: 70px 0;
}

.author-profile-area .product-title-area, .author-profile-area .shortcode_module_title, .author-profile-area .dashboard_contents .dashboard_title_area, .dashboard_contents .author-profile-area .dashboard_title_area {
  padding: 25px;
}

.author-profile-area .product-title-area h2, .author-profile-area .shortcode_module_title h2, .author-profile-area .dashboard_contents .dashboard_title_area h2, .dashboard_contents .author-profile-area .dashboard_title_area h2 {
  font-size: 24px;
}

.author-profile-area .product-title-area .btn, .author-profile-area .shortcode_module_title .btn, .author-profile-area .dashboard_contents .dashboard_title_area .btn, .dashboard_contents .author-profile-area .dashboard_title_area .btn {
  float: right;
}

/* author sidebar area */
.sidebar_author .author-menu {
  padding: 30px;
}

.sidebar_author .author-menu ul {
  border: 1px solid #ececec;
  border-radius: 4px;
}

.sidebar_author .author-menu ul li a {
  font-size: 16px;
  line-height: 50px;
  color: #000;
  padding: 0 20px;
  display: block;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  transition: 0.3s ease;
}

.sidebar_author .author-menu ul li a:hover, .sidebar_author .author-menu ul li a.active {
  background: #0674ec;
  color: #fff;
}

.sidebar_author .freelance-status {
  padding: 20px 30px;
}

.sidebar_author .freelance-status .custom-radio label, .sidebar_author .freelance-status .custom-radio .form-group p.label, .form-group .sidebar_author .freelance-status .custom-radio p.label {
  font-size: 16px;
  cursor: default;
}

.sidebar_author .freelance-status .custom-radio label span.circle:before, .sidebar_author .freelance-status .custom-radio .form-group p.label span.circle:before, .form-group .sidebar_author .freelance-status .custom-radio p.label span.circle:before {
  background: #77de51;
  border: 0;
  content: "\f00c";
  font-family: FontAwesome , sans-serif;
}

.sidebar_author .message-card .message-form {
  padding: 30px;
}

.sidebar_author .message-card .message-form textarea {
  height: 150px;
}

.sidebar_author .message-card .message-form p {
  text-align: center;
  padding-top: 22px;
}

.sidebar_author .message-card .message-form p a {
  color: #0674ec;
}

.sidebar_author .message-card .message-form .msg_submit {
  text-align: center;
}

.author-info {
  text-align: center;
  border-radius: 4px;
  min-height: 124px;
  padding: 22px 0 30px;
  margin-bottom: 70px;
}

.author-info.author-info--dashboard {
  padding: 36px 0 34px;
}

.author-info.author-info--dashboard h3 {
  line-height: 48px;
}

.author-info.author-info--dashboard p {
  font-size: 18px;
  margin-bottom: 5px;
}

.author-info p {
  color: #fff;
  margin-bottom: 7px;
}

.author-info h3 {
  color: #fff;
  font-size: 36px;
  font-weight: 500;
}

.author-info .rating span.fa {
  line-height: 30px;
}

.author-info .rating .rating__count {
  color: #fff;
}

/**/
.author_module {
  -webkit-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  -moz-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  margin-bottom: 30px;
  background: #fff;
}

.about_author {
  padding: 44px 30px;
  border-radius: 4px;
  margin-bottom: 70px;
}

.about_author h2 {
  line-height: 48px;
  padding-bottom: 22px;
}

.about_author h2 span {
  font-weight: 500;
}

.about_author p + p {
  margin-bottom: 0;
}

/* AUTHOR FOLLOWERS AREA */
.user_area {
  background: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  -moz-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
}

.user_area > ul {
  margin-bottom: 30px;
}

.user_area > ul > li + li {
  border-top: 1px solid #ececec;
}

.user_area .user_single {
  padding: 30px 0;
}

.user_area .user_single .user__short_desc, .user_area .user_single .user__meta, .user_area .user_single .user__status {
  display: inline-block;
  vertical-align: middle;
  margin-right: -3px;
  padding: 0 28px;
  border-right: 1px solid #ececec;
}

.user_area .user_single .user__short_desc p, .user_area .user_single .user__meta p, .user_area .user_single .user__status p {
  margin: 0;
}

.user_area .user_single .user__short_desc, .user_area .user_single .user__meta {
  min-height: 100px;
}

.user_area .user_single .user__short_desc {
  max-width: 350px;
}

.user_area .user_single .user__short_desc .user_avatar, .user_area .user_single .user__short_desc .user_info {
  display: inline-block;
}

.user_area .user_single .user__short_desc .user_info {
  width: calc(100% - 75px);
  padding-left: 15px;
}

.user_area .user_single .user__short_desc .user_info a {
  font-size: 18px;
  line-height: 26px;
  color: #000;
  font-weight: 500;
  display: inline-block;
  padding-bottom: 10px;
}

.user_area .user_single .user__short_desc .user_info p {
  line-height: 28px;
}

.user_area .user_single .user__meta {
  max-width: 195px;
}

.user_area .user_single .user__meta p {
  line-height: 28px;
}

.user_area .user_single .user__meta .rating {
  padding-top: 7px;
}

.user_area .user_single .user__status {
  border-right: 0;
}

.user_area .user_single .user__status .btn {
  min-width: 135px;
  transition: 0.3s;
}

.user_area .user_single .user__status.user--follow .btn {
  background: #7347c1;
}

.user_area .user_single .user__status.user--follow .btn:hover {
  background: #0674ec;
  color: #fff;
}

.user_area .user_single .user__status.user--following .btn:hover {
  background: #ff6a6d;
  color: #fff;
}

.user_area .pagination-area {
  border-top: 1px solid #ececec;
}

/***************************************
	END AUTHOR AREA
****************************************/
/***************************************
  START DASHBOARD AREA
****************************************/
.dashboard-area {
  background: #eff1f5;
}

.dashboard-area.dashboard_purchase .dashboard_menu {
  justify-content: flex-start;
}

.dashboard-area.dashboard_purchase .dashboard_menu li + li {
  margin-left: 30px;
}

.dashboard_menu_area {
  box-shadow: 0 3px 10px rgba(121, 135, 150, 0.1);
  background: #fff;
}

.dashboard_menu_area .dashboard_menu {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.dashboard_menu_area .dashboard_menu li {
  margin: 8px 0;
}

.dashboard_menu_area .dashboard_menu li + li {
  margin-left: 30px;
}

.dashboard_menu_area .dashboard_menu li a {
  color: #54667a;
  font-size: 16px;
  display: inline-block;
  line-height: 70px;
}

.dashboard_menu_area .dashboard_menu li a:hover {
  color: #0674ec;
}

.dashboard_menu_area .dashboard_menu li a span.lnr {
  font-size: 18px;
  margin-right: 10px;
}

.dashboard_menu_area .dashboard_menu li.active a {
  color: #0674ec;
}

.dashboard_contents {
  padding: 70px 0;
}

/*DEFAULT DASHBOARD PAGE OR AREA*/
.dashboard_module {
  background: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  -moz-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  margin-bottom: 30px;
}

.dashboard_module .dashboard__title {
  padding: 36px 30px 30px;
  border-bottom: 1px solid #ececec;
  overflow: hidden;
}

.dashboard_module .dashboard__title h4 {
  font-size: 22px;
  display: inline-block;
}

.dashboard_module .loading {
  float: right;
}

.dashboard_module .loading a:hover {
  color: #0674ec;
}

.dashboard_module .dashboard__content {
  padding: 0 28px 18px;
}

.dashboard_module .dashboard__content > ul li {
  padding: 20px 0;
}

.dashboard_module .dashboard__content > ul li + li {
  border-top: 1px solid #ececec;
}

.dashboard_module.recent_message .dashboard__content .messages .message {
  padding: 30px 0;
}

.dashboard_module.recent_message .dashboard__content .messages .message .actions {
  display: none;
}

.dashboard_module.recent_message .dashboard__content .messages .message .message_data {
  width: calc(100% - 56px);
}

/* dashboard statistics area */
.statistics_module .dashboard__title {
  border-bottom: 0;
}

.statistics_module .select-wrap, .statistics_module #stat_legend {
  display: inline-block;
}

.statistics_data {
  border-top: 1px solid #ececec;
  padding-top: 25px;
  margin-top: 25px;
}

.statistics_data .single_stat_data {
  display: inline-block;
  text-align: center;
}

.statistics_data .single_stat_data .single_stat__title {
  font-size: 30px;
  line-height: 30px;
  font-weight: 500;
}

.statistics_data .single_stat_data p span {
  color: #0674ec;
}

/*dashboard statistic area 2*/
.legend {
  margin-top: 20px;
}

.legend ul li {
  display: inline-block;
  margin-right: 30px;
  color: #747a86;
}

.legend ul li span {
  height: 12px;
  width: 12px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 11px;
}

/*chart*/
.visit_data .dashboard__content {
  padding: 40px 30px;
}

.visit_data .v_refer {
  margin-bottom: 30px;
}

.visit_data .v_refer .nav.nav-tabs li {
  border: none;
}

.visit_data .v_refer .nav.nav-tabs li:before {
  display: none;
}

.visit_data .v_refer .select-wrap {
  float: right;
}

.visit_data .referrals_data ul li {
  overflow: hidden;
  padding: 23px 0 24px;
  border-bottom: 1px solid #ececec;
}

.visit_data .referrals_data ul li:first-child {
  border-top: 1px solid #ececec;
}

.visit_data .referrals_data ul li p {
  margin-bottom: 0;
  color: #747a86;
  line-height: 28px;
}

.visit_data .referrals_data ul li .site {
  float: left;
}

.visit_data .referrals_data ul li .visit {
  float: right;
  color: #000;
}

.visit_data .referrals_data ul li .visit span {
  margin-right: 6px;
  color: #747a86;
  text-transform: capitalize;
}

.chart_top .v_refer .nav-tabs {
  display: inline-block;
  border-bottom: 0;
}

.chart_top .v_refer .nav-tabs li a {
  border: 0;
  font-size: 22px;
  line-height: 28px;
  font-weight: 400;
  color: #747a86;
}

.chart_top .v_refer .nav-tabs li a:hover {
  background: none;
}

.chart_top .v_refer .nav-tabs li a.active {
  color: #333;
}

.chart_top .v_refer .nav-tabs li a.active::before {
  content: none;
}

/* country visit statistic */
.country_statistics .dashboard__content {
  padding: 0 28px;
}

.country_statistics table {
  margin-bottom: 0;
}

.country_statistics table thead tr th {
  border-bottom: 0;
  padding: 15px 0;
  color: #333;
}

.country_statistics table tbody tr {
  border-bottom: 0;
}

.country_statistics table tbody tr + tr {
  border-top: 1px solid #e8ebf0;
}

.country_statistics table tbody tr td {
  color: #747a86;
  font-size: 16px;
  padding: 15px 0;
}

.country_statistics table tbody tr td .country_flag {
  height: 40px;
  width: 40px;
  display: inline-block;
}

.country_statistics table tbody tr td .country_flag img {
  -webkit-border-radius: 200px;
  -moz-border-radius: 200px;
  border-radius: 200px;
  width: 100%;
}

.country_statistics table tbody tr td span {
  line-height: 28px;
  padding-left: 16px;
}

.country_statistics .dashboard__content {
  padding: 0 28px 25px;
}

.country_statistics .select-wrap {
  float: right;
}

.country_statistics .select-wrap .period_selector {
  width: auto;
  height: 40px;
  border: 1px solid #ececec;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

/* recent sells */
.recent_sells .single_sell {
  overflow: hidden;
}

.recent_sells .single_sell .single_sell__thumb-title {
  float: left;
}

.recent_sells .single_sell .single_sell__thumb-title .prod_thumbnail {
  max-width: 90px;
  float: left;
}

.recent_sells .single_sell .single_sell__thumb-title .prod_thumbnail img {
  max-width: 100%;
}

.recent_sells .single_sell .single_sell__thumb-title .prod_title {
  padding-top: 8px;
  float: left;
  padding-left: 20px;
}

.recent_sells .single_sell .single_sell__thumb-title .prod_title h4 {
  line-height: 24px;
  font-weight: 500;
  padding-bottom: 9px;
}

.recent_sells .single_sell .single_sell__thumb-title .prod_title .category img {
  display: inline-block;
}

.recent_sells .single_sell .ammount {
  float: right;
  padding-top: 15px;
}

.recent_sells .single_sell .ammount p {
  color: #0674ec;
  line-height: 28px;
  margin-bottom: 0;
}

/* single item visitor*/
.single_item_visitor .dashboard__title {
  border-bottom: 0;
}

.single_item_visitor .dashboard__title #visit_legend {
  float: right;
}

.single_item_visitor .dashboard__content .item_info {
  margin-top: 20px;
  overflow: hidden;
}

.single_item_visitor .dashboard__content .item_info .select-wrap {
  float: left;
  margin-top: 9px;
}

.single_item_visitor .dashboard__content .item_info .select-wrap select {
  font-weight: 500;
  color: #000;
}

.single_item_visitor .dashboard__content .item_info .info {
  float: right;
  text-align: right;
}

.single_item_visitor .dashboard__content .item_info .info h4 {
  font-size: 30px;
  line-height: 36px;
  font-weight: 500;
  color: #0674ec;
}

.single_item_visitor .dashboard__content .item_info .info p {
  margin-bottom: 0;
  font-size: 15px;
  line-height: 30px;
}

/*  recent buyers */
.recent_buyers .single_buyer {
  overflow: hidden;
}

.recent_buyers .single_buyer .buyer__thumb_title {
  overflow: hidden;
  float: left;
}

.recent_buyers .single_buyer .buyer__thumb_title h4 {
  font-size: 16px;
  line-height: 28px;
  color: #333;
  font-weight: 500;
}

.recent_buyers .single_buyer .buyer__thumb_title .thumb {
  float: left;
  height: 50px;
  width: 50px;
}

.recent_buyers .single_buyer .buyer__thumb_title .thumb img {
  -webkit-border-radius: 200px;
  -moz-border-radius: 200px;
  border-radius: 200px;
  max-width: 1000%;
}

.recent_buyers .single_buyer .buyer__thumb_title .title {
  float: left;
  padding-left: 20px;
}

.recent_buyers .single_buyer .buyer__thumb_title .title p {
  color: #747a86;
  margin-bottom: 0;
  font-size: 14px;
}

.recent_buyers .single_buyer .price {
  float: right;
  padding-top: 15px;
  color: #747a86;
}

/* recent comment */
.recent_comment .dashboard__content .thread .single-thread {
  display: block;
}

.recent_comment .dashboard__content .thread .single-thread + .single-thread {
  border-bottom: 0;
  border-top: 1px solid #ececec;
}

.recent_comment .dashboard__content .thread .single-thread .media {
  padding: 30px 0;
}

.recent_comment .dashboard__content .thread .single-thread .media .media-left a {
  width: 50px;
  height: 50px;
}

.recent_comment .dashboard__content .thread .single-thread .media.depth-2 {
  margin-left: 0;
  padding-top: 0;
  border-top: 0;
  margin-top: 6px;
}

/* recent message */
.recent_message .dashboard__content {
  padding-top: 0 !important;
}

.recent_message .dashboard__content .message_composer {
  padding: 30px 0 0 0 !important;
}

/* recent notification */
.recent_notification .dashboard__content {
  padding: 0;
}

/* product que */
.product_que .uploaded_product {
  width: calc(100% - 35px);
  display: inline-block;
  vertical-align: middle;
  margin-right: -3px;
}

.product_que .uploaded_product h4 {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

.product_que .uploaded_product p {
  margin-bottom: 0;
  font-size: 15px;
}

.product_que .cross {
  color: #0674ec;
  line-height: 28px;
  display: inline-block;
  vertical-align: middle;
  margin-right: -3px;
  width: 28px;
  text-align: center;
  font-size: 14px;
  background: rgba(6, 116, 236, 0.1);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

/* DASHBOARD SETTING AREA */
.information_module {
  background: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  -moz-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  margin-bottom: 30px;
}

.information_module .information__set {
  border-top: 1px solid #ececec;
}

.information_module .information__set .information_wrapper {
  padding: 30px 30px;
}

.information_module .information__set .information_wrapper.form--fields {
  padding: 22px 30px;
}

.information_module #authbio {
  height: 180px;
}

.profile_images input[type='file'] {
  visibility: hidden;
  opacity: 0;
  position: absolute;
}

.profile_images p {
  margin-bottom: 0;
}

.profile_images .subtitle {
  font-size: 14px;
  color: #898f96;
}

.profile_images .bold {
  color: #000;
}

.profile_images .upload_btn {
  float: right;
  padding-left: 18px;
  padding-top: 27px;
}

.profile_images .upload_btn .btn {
  background: #7347c1;
}

.profile_images .upload_btn .btn:hover {
  background: #0674ec;
  color: #fff;
}

.profile_images .profile_image_area {
  padding-bottom: 23px;
}

.profile_images .profile_image_area .img_info, .profile_images .profile_image_area img {
  display: inline-block;
  vertical-align: middle;
}

.profile_images .profile_image_area .img_info {
  padding-left: 14px;
}

.profile_images .profile_image_area img {
  border-radius: 50%;
}

.profile_images .prof_img_upload img {
  width: 100%;
}

.profile_images .prof_img_upload p {
  display: inline-block;
}

.profile_images .prof_img_upload .bold {
  padding-bottom: 9px;
}

.profile_images .prof_img_upload .upload_title {
  overflow: hidden;
  padding-top: 20px;
}

.profile_images .prof_img_upload .upload_title p {
  padding-top: 10px;
}

.profile_images .prof_img_upload .upload_btn {
  padding: 0;
}

.social_profile .social__single {
  margin-bottom: 30px;
}

.social_profile .social__single .link_field {
  display: inline-block;
  width: calc(100% - 50px);
  padding-left: 12px;
}

.social_profile .social__single:last-child {
  margin-bottom: 0;
}

/* Mail setting area */
.mail_setting .custom_checkbox {
  position: relative;
  padding-bottom: 18px;
}

.mail_setting .custom_checkbox label .radio_title, .mail_setting .custom_checkbox .form-group p.label .radio_title, .form-group .mail_setting .custom_checkbox p.label .radio_title {
  display: block;
  font-weight: 500;
  color: #000;
  padding-left: 35px;
}

.mail_setting .custom_checkbox label .shadow_checkbox, .mail_setting .custom_checkbox .form-group p.label .shadow_checkbox, .form-group .mail_setting .custom_checkbox p.label .shadow_checkbox {
  position: absolute;
  top: 5px;
}

.mail_setting .custom_checkbox label .desc, .mail_setting .custom_checkbox .form-group p.label .desc, .form-group .mail_setting .custom_checkbox p.label .desc {
  padding-left: 35px;
  font-size: 14px;
  color: #898f96;
}

.mail_setting .custom_checkbox label .circle, .mail_setting .custom_checkbox .form-group p.label .circle, .form-group .mail_setting .custom_checkbox p.label .circle {
  position: absolute;
  left: 0;
  top: 5px;
}

.mail_setting .custom_checkbox label span, .mail_setting .custom_checkbox .form-group p.label span, .form-group .mail_setting .custom_checkbox p.label span {
  line-height: 26px;
  font-size: 16px;
}

.dashboard_setting_btn {
  text-align: center;
  margin-top: 20px;
}

/*---- end dashboard settina area ----*/
/* DASHBOARD PURCHASE AREA */
.dashboard_purchase .dashboard__title {
  padding: 30px 0;
}

.dashboard_purchase .filter__option {
  margin-right: 28px;
  margin-left: 0;
}

.dashboard_purchase .filter-bar .filter__option p {
  font-size: 18px;
}

.product_archive {
  background: #fff;
  background: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  -moz-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
}

.product_archive .title_area {
  padding: 30px;
  border-bottom: 1px solid #ececec;
}

.product_archive .add_info {
  padding-left: 35px;
}

.product_archive.added_to__cart {
  padding-bottom: 80px;
}

.product_archive.added_to__cart .product__price_download .item_action {
  float: none;
}

.product_archive.added_to__cart .short_desc a h4 {
  transition: 0.3s ease;
}

.product_archive.added_to__cart .short_desc a:hover h4 {
  color: #0674ec;
}

.single_product {
  padding: 30px;
  border-bottom: 1px solid #ececec;
}

.single_product .product__description img {
  display: inline-block;
  vertical-align: middle;
}

.single_product .product__description .short_desc {
  display: inline-block;
  width: calc(100% - 155px);
  padding-left: 20px;
  vertical-align: middle;
}

.single_product .product__description .short_desc h4 {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

.single_product .product__additional_info {
  padding-left: 35px;
}

.single_product .product__additional_info ul li a:hover {
  color: #7347c1;
}

.single_product .product__additional_info ul li a img {
  margin-right: 5px;
}

.single_product .product__additional_info p {
  margin: 0;
  color: #000;
}

.single_product .product__additional_info p span {
  color: #555;
}

.single_product .license p {
  color: #0674ec;
}

.single_product .product__price_download {
  display: table;
  width: 100%;
}

.single_product .product__price_download .item_price {
  display: table-cell;
}

.single_product .product__price_download .item_price span {
  background: rgba(6, 116, 236, 0.1);
  line-height: 32px;
  display: inline-block;
  padding: 0 15px;
  color: #0674ec;
  margin-right: 10px;
  font-size: 15px;
  font-weight: 500;
  border-radius: 100px;
}

.single_product .product__price_download .item_action {
  float: right;
}

.single_product .product__price_download .item_action .btn {
  display: block;
}

.single_product .product__price_download .item_action .btn + .btn {
  margin-top: 20px;
}

.single_product .product__price_download .item_action .rating--btn {
  position: relative;
}

.single_product .product__price_download .item_action .rating--btn:focus {
  outline: 0;
}

.single_product .product__price_download .item_action .rating--btn .rating {
  position: relative;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  top: 0;
}

.single_product .product__price_download .item_action .rating--btn .rating ul li + li {
  margin-left: 5px;
  margin-right: 0;
}

.single_product .product__price_download .item_action .rating--btn .rating ul li span.fa-star-o {
  color: #898f96;
}

.single_product .product__price_download .item_action .rating--btn .rate_it {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  left: 0;
  margin-bottom: 0;
  top: -10%;
  text-align: center;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  width: 100%;
}

.single_product .product__price_download .item_action .not--rated:hover .rate_it {
  visibility: visible;
  opacity: 1;
  top: 50%;
  transform: translateY(-50%);
}

.single_product .product__price_download .item_action .not--rated:hover .rating {
  top: 20px;
  opacity: 0;
  visibility: hidden;
}

.single_product .product__price_download .item_action .remove_from_cart:hover span {
  background: #ff6a6d;
  color: #fff;
}

.single_product .product__price_download .item_action .remove_from_cart span {
  font-size: 18px;
  transition: 0.2s;
  line-height: 50px;
  width: 50px;
  text-align: center;
  background: rgba(6, 116, 236, 0.1);
  border-radius: 50%;
  display: inline-block;
  color: #0674ec;
}

.rating_modal .modal-header h4 {
  display: inline-block;
  font-weight: 500;
}

.rating_modal .modal-header P {
  display: inline-block;
  margin-left: 14px;
}

.rating_modal .modal-header P a {
  color: #0674ec;
}

.rating_modal .modal-body ul li {
  margin-bottom: 30px;
}

.rating_modal .modal-body ul li p {
  min-width: 138px;
  display: inline-block;
  margin-bottom: 0;
  color: #333;
  font-weight: 500;
}

.rating_modal .modal-body ul li p:after {
  content: ':';
}

.rating_modal .modal-body ul li .right_content {
  display: inline-block;
}

.rating_modal .modal-body .rating_field label, .rating_modal .modal-body .rating_field .form-group p.label, .form-group .rating_modal .modal-body .rating_field p.label {
  margin-bottom: 16px;
}

.rating_modal .modal-body .text_field {
  border-radius: 4px;
  height: 200px;
  margin-bottom: 20px;
  padding: 15px 25px;
}

.rating_modal .modal-body .notice {
  margin-bottom: 35px;
}

/*---- end dashboard PURCHASE area ----*/
/*****************************
	DASHBOARD ADD CREDIT AREA
*****************************/
.shortcode_modules .btn {
  margin-bottom: 25px;
  margin-left: 6px;
}

.shortcode_modules .form-group {
  margin-bottom: 45px;
}

.shortcode_modules .typog h1, .shortcode_modules .typog h2, .shortcode_modules .typog h3, .shortcode_modules .typog h4, .shortcode_modules .typog h5, .shortcode_modules .typog h6 {
  margin-bottom: 40px;
}

.credit_modules, .shortcode_modules, .upload_modules, .withdraw_module {
  margin-bottom: 30px;
}

.credit_modules .modules__title, .shortcode_modules .modules__title, .upload_modules .modules__title, .withdraw_module .modules__title {
  border-bottom: 1px solid #ececec;
  padding: 30px;
}

.credit_modules .custom_amount, .shortcode_modules .custom_amount, .upload_modules .custom_amount, .withdraw_module .custom_amount {
  width: 280px;
}

.credit_modules .custom_amount .input-group, .shortcode_modules .custom_amount .input-group, .upload_modules .custom_amount .input-group, .withdraw_module .custom_amount .input-group {
  align-items: center;
}

.credit_modules .custom_amount .input-group-addon, .shortcode_modules .custom_amount .input-group-addon, .upload_modules .custom_amount .input-group-addon, .withdraw_module .custom_amount .input-group-addon {
  font-weight: 500;
  font-size: 22px;
}

.credit_modules .custom_amount input, .shortcode_modules .custom_amount input, .upload_modules .custom_amount input, .withdraw_module .custom_amount input {
  height: 55px;
  font-size: 20px;
  font-weight: 500;
  width: 250px;
  margin-left: 15px;
}

.credit_modules .modules__content, .shortcode_modules .modules__content, .upload_modules .modules__content, .withdraw_module .modules__content {
  padding: 30px 30px 40px;
}

.credit_modules .modules__content .subtitle, .shortcode_modules .modules__content .subtitle, .upload_modules .modules__content .subtitle, .withdraw_module .modules__content .subtitle {
  font-size: 22px;
  color: #333333;
  margin-bottom: 25px;
}

.credit_modules .modules__content .amounts, .shortcode_modules .modules__content .amounts, .upload_modules .modules__content .amounts, .withdraw_module .modules__content .amounts {
  padding-bottom: 40px;
}

.credit_modules .modules__content .amounts ul, .shortcode_modules .modules__content .amounts ul, .upload_modules .modules__content .amounts ul, .withdraw_module .modules__content .amounts ul {
  display: flex;
  flex-wrap: wrap;
}

.credit_modules .modules__content .amounts ul li + li, .shortcode_modules .modules__content .amounts ul li + li, .upload_modules .modules__content .amounts ul li + li, .withdraw_module .modules__content .amounts ul li + li {
  margin-left: 25px;
}

.credit_modules .modules__content .amounts ul li p, .shortcode_modules .modules__content .amounts ul li p, .upload_modules .modules__content .amounts ul li p, .withdraw_module .modules__content .amounts ul li p {
  font-size: 32px;
  line-height: 28px;
  cursor: pointer;
  font-weight: 500;
  background: #eff1f5;
  padding: 17px 0;
  min-width: 190px;
  text-align: center;
}

.credit_modules .modules__content .amounts ul li p.selected, .shortcode_modules .modules__content .amounts ul li p.selected, .upload_modules .modules__content .amounts ul li p.selected, .withdraw_module .modules__content .amounts ul li p.selected {
  background: #0674ec;
  color: #fff;
}

.credit_modules .modules__content .payment_method li, .shortcode_modules .modules__content .payment_method li, .upload_modules .modules__content .payment_method li, .withdraw_module .modules__content .payment_method li {
  display: inline-block;
  margin-right: 14px;
}

.credit_modules .modules__content .custom_radio--big label, .shortcode_modules .modules__content .custom_radio--big label, .upload_modules .modules__content .custom_radio--big label, .withdraw_module .modules__content .custom_radio--big label, .credit_modules .modules__content .custom_radio--big .form-group p.label, .form-group .credit_modules .modules__content .custom_radio--big p.label, .shortcode_modules .modules__content .custom_radio--big .form-group p.label, .form-group .shortcode_modules .modules__content .custom_radio--big p.label, .upload_modules .modules__content .custom_radio--big .form-group p.label, .form-group .upload_modules .modules__content .custom_radio--big p.label, .withdraw_module .modules__content .custom_radio--big .form-group p.label, .form-group .withdraw_module .modules__content .custom_radio--big p.label {
  text-align: center;
}

.credit_modules .modules__content .custom_radio--big label img, .shortcode_modules .modules__content .custom_radio--big label img, .upload_modules .modules__content .custom_radio--big label img, .withdraw_module .modules__content .custom_radio--big label img, .credit_modules .modules__content .custom_radio--big .form-group p.label img, .form-group .credit_modules .modules__content .custom_radio--big p.label img, .shortcode_modules .modules__content .custom_radio--big .form-group p.label img, .form-group .shortcode_modules .modules__content .custom_radio--big p.label img, .upload_modules .modules__content .custom_radio--big .form-group p.label img, .form-group .upload_modules .modules__content .custom_radio--big p.label img, .withdraw_module .modules__content .custom_radio--big .form-group p.label img, .form-group .withdraw_module .modules__content .custom_radio--big p.label img {
  display: block;
  max-width: 192px;
  height: 123px;
  border: 3px solid #e0e3ea;
}

.credit_modules .modules__content .custom_radio--big label span.circle, .shortcode_modules .modules__content .custom_radio--big label span.circle, .upload_modules .modules__content .custom_radio--big label span.circle, .withdraw_module .modules__content .custom_radio--big label span.circle, .credit_modules .modules__content .custom_radio--big .form-group p.label span.circle, .form-group .credit_modules .modules__content .custom_radio--big p.label span.circle, .shortcode_modules .modules__content .custom_radio--big .form-group p.label span.circle, .form-group .shortcode_modules .modules__content .custom_radio--big p.label span.circle, .upload_modules .modules__content .custom_radio--big .form-group p.label span.circle, .form-group .upload_modules .modules__content .custom_radio--big p.label span.circle, .withdraw_module .modules__content .custom_radio--big .form-group p.label span.circle, .form-group .withdraw_module .modules__content .custom_radio--big p.label span.circle {
  margin-top: 20px;
}

.credit_modules .payment_info .btn, .shortcode_modules .payment_info .btn, .upload_modules .payment_info .btn, .withdraw_module .payment_info .btn {
  margin-top: 30px;
}

/*****************************
  	END DASHBOARD ADD CREDIT AREA
  *****************************/
/* DASHBOARD STATEMENT AREA */
.dashboard-statement .dashboard_title_area .dashboard__title h3 {
  float: left;
  margin-top: 10px;
}

.statement_info_card {
  padding: 0 25px;
  min-height: 150px;
  display: table;
  width: 100%;
}

.statement_info_card .info_wrap {
  display: table-cell;
  vertical-align: middle;
}

.statement_info_card .icon {
  border-radius: 200px;
  line-height: 60px;
  margin-right: 13px;
  width: 60px;
  color: #fff;
  display: inline-block;
  text-align: center;
  font-size: 24px;
}

.statement_info_card .info {
  display: inline-block;
}

.statement_info_card .info p {
  color: #333;
  font-size: 30px;
  line-height: 34px;
  font-weight: 500;
}

.statement_info_card .info span {
  color: #54667a;
  font-size: 18px;
}

.statement_table {
  margin-top: 30px;
}

/*---- end dashboard statement area ----*/
/* DASHBOARD INVOICE AREA */
.invoice-page .dashboard_title_area {
  padding: 25px 30px 25px;
}

.invoice-page .dashboard__title h3 {
  line-height: 45px;
}

.print_btn {
  line-height: 44px;
  padding: 0 22px;
  background: #7347c1;
  margin-right: 20px;
}

.print_btn span {
  margin-right: 8px;
}

.invoice {
  background: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  -moz-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
}

.invoice .invoice__head {
  overflow: hidden;
  padding: 40px 30px 30px;
  border-bottom: 1px solid #ececec;
}

.invoice .invoice__head .invoice_logo {
  float: left;
  margin-top: 10px;
}

.invoice .invoice__head .info {
  float: right;
  text-align: right;
}

.invoice .invoice__head .info h4 {
  font-size: 20px;
  margin-bottom: 5px;
  line-height: 32px;
  color: #333;
  font-weight: 500;
}

.invoice .invoice__meta p {
  line-height: 30px;
}

.invoice .invoice__meta .address {
  width: 50%;
  display: inline-block;
  margin-left: -3px;
}

.invoice .invoice__meta .address h5 {
  color: #000;
  font-size: 16px;
  line-height: 30px;
}

.invoice .invoice__meta .date_info {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  text-align: right;
  margin-left: -3px;
}

.invoice .invoice__meta .date_info p span {
  color: #000;
  font-weight: 500;
  margin-right: 5px;
}

.invoice .invoice__meta .date_info p span:after {
  content: ':';
  margin-left: 2px;
}

.invoice .invoice__meta .date_info p.status {
  color: #77de51;
}

.invoice .invoice__meta {
  padding: 45px 30px;
}

.invoice .invoice__meta p {
  margin-bottom: 0;
}

.invoice .pricing_info {
  padding: 0 30px 100px;
  margin-top: 30px;
  text-align: right;
}

.invoice .pricing_info p {
  margin: 0;
  color: #000;
  line-height: 36px;
}

.invoice .pricing_info .bold {
  font-size: 18px;
}

.invoice .invoice__detail {
  margin-top: 100px;
}

/*---- end dashboard statement area ----*/
/* DASHBOARD UPLOAD AREA */
.upload_modules, .withdraw_module {
  background: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  -moz-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
}

.upload_modules .form-group, .withdraw_module .form-group {
  margin-bottom: 31px;
}

.upload_modules .custom-radio label, .withdraw_module .custom-radio label, .upload_modules .custom-radio .form-group p.label, .form-group .upload_modules .custom-radio p.label, .withdraw_module .custom-radio .form-group p.label, .form-group .withdraw_module .custom-radio p.label {
  font-size: 16px;
}

.upload_modules #tags, .withdraw_module #tags {
  height: 150px;
}

.upload_modules.with--addons .input-group, .with--addons.withdraw_module .input-group {
  align-items: center;
}

.upload_modules.with--addons .input-group input, .with--addons.withdraw_module .input-group input {
  width: calc(100% - 30px);
  margin-left: 15px;
}

/* DASHBOARD EDIT PAGE */
.dashboard-edit .product .product__thumbnail:before {
  display: none;
}

.dashboard-edit .product .prod_option {
  position: absolute;
  top: 20px;
  left: 20px;
}

.dashboard-edit .product .prod_option.show .options.dropdown-menu {
  visibility: visible;
  opacity: 1;
}

.dashboard-edit .product .prod_option .setting-icon {
  font-size: 20px;
  line-height: 45px;
  width: 45px;
  text-align: center;
  background: #000;
  color: #fff;
  display: inline-block;
  background: #0674ec;
  -webkit-border-radius: 200px;
  -moz-border-radius: 200px;
  border-radius: 200px;
  cursor: pointer;
}

.dashboard-edit .product .prod_option .options {
  position: absolute;
  width: 120px;
  background: #fff;
  padding: 15px;
  border-radius: 4px;
  opacity: 0;
  display: initial;
  transition: 0.3s ease;
  visibility: hidden;
  top: 18px !important;
}

.dashboard-edit .product .prod_option .options:before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 10px;
  border-bottom: 10px solid #fff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

.dashboard-edit .product .prod_option .options ul li a {
  color: #54667a;
  padding: 0;
  transition: 0.3s ease;
  display: inline-block;
}

.dashboard-edit .product .prod_option .options ul li a span {
  margin-right: 5px;
  font-size: 14px;
}

.dashboard-edit .product .prod_option .options ul li a:hover {
  color: #0674ec;
}

.dashboard-edit .product .prod_option .options ul li a.delete:hover {
  color: #ff6a6d;
}

/* trumboyg*/
.trumbowyg-button-pane {
  background: #e1e7ee;
}

.trumbowyg-box, .trumbowyg-editor {
  border: none;
  min-height: 200px;
  background: #f4f5f8;
  margin-bottom: 0;
}

.upload_wrapper > p {
  font-weight: 500;
  margin-top: 0;
  color: #000;
  margin-bottom: 6px;
  font-size: 16px;
}

.upload_wrapper > p span {
  font-size: 15px;
  color: #555;
  font-weight: 400;
  margin-left: 10px;
}

.upload_wrapper label, .upload_wrapper .form-group p.label, .form-group .upload_wrapper p.label {
  margin-bottom: 0;
}

.upload_wrapper .custom_upload {
  display: inline-block;
}

.upload_wrapper .progress_wrapper {
  width: calc(100% - 163px);
  margin-bottom: 0;
}

.upload_cross, .progress_wrapper .p_cross {
  line-height: 28px;
  vertical-align: -11px;
  width: 28px;
  border-radius: 3px;
  background: rgba(6, 116, 236, 0.1);
  font-size: 14px;
  display: inline-block;
  text-align: center;
  color: #0674ec;
}

.filter-bar.dashboard_title_area {
  padding: 24px 30px;
}

.filter-bar.dashboard_title_area .dashboard__title h3 {
  font-size: 24px;
  line-height: 45px;
}

.filter-bar.dashboard_title_area .filter__option {
  margin-right: 0;
  padding: 0;
}

.filter-bar.dashboard_title_area .filter__option p {
  font-size: 18px;
}

/*dashboard withdraw page */
.dashboard-withdraw .dashboard_title_area {
  padding: 23px 30px;
}

.dashboard-withdraw .dashboard_title_area h3 {
  line-height: 45px;
}

.withdraw_module .modules__content .subtitle {
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
}

.withdraw_module .modules__content .subtitle a {
  color: #0674ec;
}

.withdraw_module .custom-radio {
  margin-bottom: 10px;
}

.withdraw_module .custom-radio label, .withdraw_module .custom-radio .form-group p.label, .form-group .withdraw_module .custom-radio p.label {
  color: #333;
}

.withdraw_module .custom-radio .bold {
  font-size: 16px;
}

.withdraw_module .custom-radio:last-child {
  margin-bottom: 0;
}

.withdraw_module .withdraw_amount {
  margin-top: 15px;
}

.withdraw_module .withdraw_amount .input-group {
  display: inline-block;
}

.withdraw_module .withdraw_amount .input-group .input-group-addon {
  width: auto;
  display: inline-block;
  padding-right: 8px;
}

.withdraw_module .withdraw_amount .input-group input {
  width: 235px;
}

.withdraw_module .withdraw_amount .fee {
  margin-left: 10px;
  color: #555;
}

.withdraw_module .button_wrapper {
  margin-top: 40px;
}

.withdraw_module .button_wrapper .cancel_btn {
  background: #7347c1;
  line-height: 40px;
  color: #fff;
  margin-left: 10px;
  padding: 0 36px;
}

.withdraw_history {
  padding-bottom: 30px;
}

.withdraw_table_header {
  padding: 33px 25px 30px;
  border-bottom: 1px solid #ececec;
}

/* end dashboard withdraw page*/
/***************************************
	END DASHBOARD AREA
****************************************/
/***************************************
	START NOTIFICATIONS AREA
****************************************/
.notifications_module .notification {
  overflow: hidden;
  padding: 20px 30px;
  border-bottom: 1px solid #ececec;
  position: relative;
}

.notifications_module .notification .line {
  width: 13px;
  left: 0;
  height: 2px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  background: #0674ec;
  position: absolute;
  display: inline-block;
}

.notifications_module .notification .line:before, .notifications_module .notification .line:after {
  width: 18px;
  height: 2px;
  content: '';
  position: absolute;
  background: #0674ec;
}

.notifications_module .notification .line:before {
  top: -7px;
}

.notifications_module .notification .line:after {
  top: 7px;
}

.notifications_module .notification .notification__info {
  float: left;
}

.notifications_module .notification .notification__info p {
  margin: 0;
}

.notifications_module .notification .notification__info .info_avatar {
  height: 50px;
  width: 50px;
  margin-right: 16px;
}

.notifications_module .notification .notification__info .info_avatar img {
  border-radius: 50%;
  width: 100%;
}

.notifications_module .notification .notification__info .info_avatar,
.notifications_module .notification .notification__info .info {
  display: inline-block;
  vertical-align: middle;
}

.notifications_module .notification .notification__info .info p {
  color: #747a86;
}

.notifications_module .notification .notification__info .info p span {
  color: #000;
  font-weight: 500;
}

.notifications_module .notification .notification__info .info p a {
  color: #0674ec;
}

.notifications_module .notification .notification__info .info .time {
  font-size: 14px;
  margin-top: 5px;
  color: #747a86;
}

.notifications_module .notification .notification__icons {
  padding-top: 10px;
  float: right;
}

.notifications_module .notification .notification__icons .noti_icon {
  line-height: 40px;
  width: 40px;
  text-align: center;
  font-size: 16px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  border-radius: 50%;
}

.notifications_module .notification .notification__icons .noti_icon.loved {
  background: rgba(6, 116, 236, 0.1);
  color: #0674ec;
}

.notifications_module .notification .notification__icons .noti_icon.commented {
  background: rgba(115, 71, 193, 0.1);
  color: #7347c1;
}

.notifications_module .notification .notification__icons .noti_icon.purchased {
  color: #ff6a6d;
  background: rgba(255, 106, 109, 0.1);
}

.notifications_module .notification .notification__icons .noti_icon.reviewed {
  color: #77de51;
  background: rgba(119, 222, 81, 0.1);
}

.notifications_module .notification .notification__icons span.lnr-cross {
  color: #c1c7d2;
  font-size: 16px;
  cursor: pointer;
}

.notifications_module .notification .notification__icons span.lnr-cross:hover {
  color: #7347c1;
}

.notifications_module .pagination-area {
  border-bottom: 0;
}

/***************************************
	END NOTIFICATIONS AREA
****************************************/
/***************************************
	START MESSAGE AREA
****************************************/
.message_area {
  background: #eff1f5;
  padding: 80px 0  120px;
}

.messaging_sidebar, .recent_message {
  position: relative;
}

.messaging_sidebar .messaging__header, .recent_message .messaging__header {
  padding: 30px;
  box-shadow: 0 3px 6px rgba(108, 111, 115, 0.15);
  position: absolute;
  width: 100%;
  z-index: 1;
  background: #fff;
}

.messaging_sidebar .messaging__header:before, .recent_message .messaging__header:before, .messaging_sidebar .messaging__header:after, .recent_message .messaging__header:after {
  content: " ";
  display: table;
}

.messaging_sidebar .messaging__header:after, .recent_message .messaging__header:after {
  clear: both;
}

.messaging_sidebar .messaging__header .messaging_menu, .recent_message .messaging__header .messaging_menu {
  float: left;
  color: #0674ec;
  padding-top: 10px;
  font-size: 16px;
}

.messaging_sidebar .messaging__header .messaging_menu .msg, .recent_message .messaging__header .messaging_menu .msg {
  line-height: 20px;
  width: 20px;
  display: inline-block;
  text-align: center;
  margin: 0 10px;
  color: #fff;
  background: #ff6a6d;
  -webkit-border-radius: 50%;
  font-size: 14px;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.messaging_sidebar .messaging__header .messaging_menu .lnr-chevron-down, .recent_message .messaging__header .messaging_menu .lnr-chevron-down {
  font-size: 12px;
  color: #54667a;
  cursor: pointer;
}

.messaging_sidebar .messaging__header .messaging_menu span, .recent_message .messaging__header .messaging_menu span {
  margin-right: 10px;
}

.messaging_sidebar .messaging__header .messaging_menu .dropdowns, .recent_message .messaging__header .messaging_menu .dropdowns {
  top: 100%;
  left: 0;
}

.messaging_sidebar .messaging__header .messaging_action, .recent_message .messaging__header .messaging_action {
  float: right;
}

.messaging_sidebar .messaging__header .messaging_action .lnr-trash, .recent_message .messaging__header .messaging_action .lnr-trash {
  margin-right: 5px;
  color: #54667a;
}

.messaging_sidebar .messaging__header .messaging_action .btn, .recent_message .messaging__header .messaging_action .btn {
  margin-left: 10px;
}

.messaging_sidebar .messaging__header .messaging_action .btn span, .recent_message .messaging__header .messaging_action .btn span {
  margin-right: 5px;
}

.messaging_sidebar .messaging__contents, .recent_message .messaging__contents, .recent_message .dashboard__content {
  padding-top: 102px;
}

.messaging_sidebar .messaging__contents .message_search, .recent_message .messaging__contents .message_search, .recent_message .dashboard__content .message_search {
  position: relative;
}

.messaging_sidebar .messaging__contents .message_search input, .recent_message .messaging__contents .message_search input, .recent_message .dashboard__content .message_search input {
  border-left: none;
  border-right: none;
  border-top: none;
  padding-left: 50px;
  border-bottom: 1px solid #ececec;
  line-height: 50px;
}

.messaging_sidebar .messaging__contents .message_search span, .recent_message .messaging__contents .message_search span, .recent_message .dashboard__content .message_search span {
  position: absolute;
  left: 25px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 15px;
  color: #898f96;
}

.messaging_sidebar .messaging__contents .messages, .recent_message .messaging__contents .messages, .recent_message .dashboard__content .messages {
  max-height: 1094px;
  overflow-Y: scroll;
  margin-right: 2px;
}

.messaging_sidebar .messaging__contents .messages.active, .recent_message .messaging__contents .messages.active, .recent_message .dashboard__content .messages.active {
  background: #f5fafe;
}

.messaging_sidebar .messaging__contents .messages .message__actions_avatar, .recent_message .messaging__contents .messages .message__actions_avatar, .recent_message .dashboard__content .messages .message__actions_avatar {
  display: inline-block;
  vertical-align: middle;
}

.messaging_sidebar .messaging__contents .messages .message__actions_avatar .actions, .recent_message .messaging__contents .messages .message__actions_avatar .actions, .recent_message .dashboard__content .messages .message__actions_avatar .actions, .messaging_sidebar .messaging__contents .messages .message__actions_avatar .avatar, .recent_message .messaging__contents .messages .message__actions_avatar .avatar, .recent_message .dashboard__content .messages .message__actions_avatar .avatar {
  display: inline-block;
  vertical-align: middle;
}

.messaging_sidebar .messaging__contents .messages .message__actions_avatar .avatar, .recent_message .messaging__contents .messages .message__actions_avatar .avatar, .recent_message .dashboard__content .messages .message__actions_avatar .avatar {
  height: 50px;
  width: 50px;
}

.messaging_sidebar .messaging__contents .messages .message__actions_avatar .actions, .recent_message .messaging__contents .messages .message__actions_avatar .actions, .recent_message .dashboard__content .messages .message__actions_avatar .actions {
  margin-right: 13px;
}

.messaging_sidebar .messaging__contents .messages .message__actions_avatar .actions span.fa, .recent_message .messaging__contents .messages .message__actions_avatar .actions span.fa, .recent_message .dashboard__content .messages .message__actions_avatar .actions span.fa {
  margin-left: 3px;
}

.messaging_sidebar .messaging__contents .messages .message__actions_avatar .actions span.fa.fa-star, .recent_message .messaging__contents .messages .message__actions_avatar .actions span.fa.fa-star, .recent_message .dashboard__content .messages .message__actions_avatar .actions span.fa.fa-star {
  color: #ffc000;
}

.messaging_sidebar .messaging__contents .messages .message__actions_avatar .actions span.fa.fa-star-o, .recent_message .messaging__contents .messages .message__actions_avatar .actions span.fa.fa-star-o, .recent_message .dashboard__content .messages .message__actions_avatar .actions span.fa.fa-star-o {
  color: #b9b9b9;
}

.messaging_sidebar .messaging__contents .messages .message__actions_avatar .avatar img, .recent_message .messaging__contents .messages .message__actions_avatar .avatar img, .recent_message .dashboard__content .messages .message__actions_avatar .avatar img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.messaging_sidebar .messaging__contents .messages .message_data, .recent_message .messaging__contents .messages .message_data, .recent_message .dashboard__content .messages .message_data {
  display: inline-block;
  width: calc(100% - 90px);
  cursor: pointer;
  padding-left: 10px;
  vertical-align: middle;
}

.messaging_sidebar .messaging__contents .messages .message_data .name_time > p, .recent_message .messaging__contents .messages .message_data .name_time > p, .recent_message .dashboard__content .messages .message_data .name_time > p {
  margin-bottom: 0;
  margin-top: 10px;
}

.messaging_sidebar .messaging__contents .messages .message_data .name, .recent_message .messaging__contents .messages .message_data .name, .recent_message .dashboard__content .messages .message_data .name {
  display: inline-block;
}

.messaging_sidebar .messaging__contents .messages .message_data .name p, .recent_message .messaging__contents .messages .message_data .name p, .recent_message .dashboard__content .messages .message_data .name p {
  display: inline-block;
  font-weight: 500;
  color: #000;
  margin-bottom: 0;
}

.messaging_sidebar .messaging__contents .messages .message_data .name span, .recent_message .messaging__contents .messages .message_data .name span, .recent_message .dashboard__content .messages .message_data .name span {
  display: inline-block;
  color: #0674ec;
  font-size: 15px;
  margin-left: 10px;
}

.messaging_sidebar .messaging__contents .messages .message_data .time, .recent_message .messaging__contents .messages .message_data .time, .recent_message .dashboard__content .messages .message_data .time {
  float: right;
  color: #0674ec;
  font-size: 15px;
}

.messaging_sidebar .messaging__contents .messages .message, .recent_message .messaging__contents .messages .message, .recent_message .dashboard__content .messages .message {
  padding: 30px;
  border-bottom: 1px solid #ececec;
}

.messaging_sidebar .messaging__contents .messages .message.active, .recent_message .messaging__contents .messages .message.active, .recent_message .dashboard__content .messages .message.active {
  background: #f5fafe;
  position: relative;
}

.messaging_sidebar .messaging__contents .messages .message.active:before, .recent_message .messaging__contents .messages .message.active:before, .recent_message .dashboard__content .messages .message.active:before {
  content: "";
  height: 100%;
  width: 3px;
  background: #0674ec;
  position: absolute;
  top: 0;
  left: 0;
}

.chat_area .chat_area--title, .recent_message .dashboard__content .chat_area--title {
  background: #fff;
  padding: 36px 28px;
  box-shadow: 0 3px 6px rgba(108, 111, 115, 0.15);
  position: relative;
}

.chat_area .chat_area--title h3, .recent_message .dashboard__content .chat_area--title h3 {
  display: inline-block;
  font-size: 22px;
  line-height: 28px;
}

.chat_area .chat_area--title h3 span, .recent_message .dashboard__content .chat_area--title h3 span {
  font-weight: 500;
  color: #0674ec;
}

.chat_area .chat_area--title .message_toolbar, .recent_message .dashboard__content .chat_area--title .message_toolbar {
  float: right;
}

.chat_area .chat_area--title .message_toolbar span, .recent_message .dashboard__content .chat_area--title .message_toolbar span {
  color: #54667a;
  margin-right: 12px;
  font-size: 16px;
  line-height: 28px;
}

.chat_area .chat_area--title .message_toolbar a:hover span, .recent_message .dashboard__content .chat_area--title .message_toolbar a:hover span {
  color: #0674ec;
}

.chat_area .chat_area--title .message_toolbar .custom_dropdown, .recent_message .dashboard__content .chat_area--title .message_toolbar .custom_dropdown {
  transform: translate3d(0, 0, 0) !important;
  right: 0;
  left: auto !important;
  top: 98px !important;
}

.chat_area .chat_area--title .message_toolbar .custom_dropdown:before, .recent_message .dashboard__content .chat_area--title .message_toolbar .custom_dropdown:before {
  right: 20px;
}

.chat_area .chat_area--title .dropdowns, .recent_message .dashboard__content .chat_area--title .dropdowns {
  right: 0;
  left: auto;
  top: 100%;
  min-width: 195px;
}

.chat_area .chat_area--title .dropdowns:before, .recent_message .dashboard__content .chat_area--title .dropdowns:before {
  right: 20px;
}

.chat_area .chat_area--conversation, .recent_message .dashboard__content .chat_area--conversation {
  padding: 30px;
}

.chat_area .chat_area--conversation .conversation, .recent_message .dashboard__content .chat_area--conversation .conversation {
  border-bottom: 1px solid #ececec;
  padding-bottom: 26px;
}

.chat_area .chat_area--conversation .conversation + .conversation, .recent_message .dashboard__content .chat_area--conversation .conversation + .conversation {
  padding-top: 30px;
}

.chat_area .chat_area--conversation .chat_avatar, .recent_message .dashboard__content .chat_area--conversation .chat_avatar {
  display: inline-block;
  vertical-align: middle;
}

.chat_area .chat_area--conversation .chat_avatar img, .recent_message .dashboard__content .chat_area--conversation .chat_avatar img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.chat_area .chat_area--conversation .name_time, .recent_message .dashboard__content .chat_area--conversation .name_time {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 55px);
  padding-left: 12px;
}

.chat_area .chat_area--conversation .name_time h4, .recent_message .dashboard__content .chat_area--conversation .name_time h4 {
  display: inline-block;
  font-weight: 500;
  color: #000;
}

.chat_area .chat_area--conversation .name_time p, .recent_message .dashboard__content .chat_area--conversation .name_time p {
  margin-bottom: 0;
  float: right;
  color: #747a86;
  font-size: 15px;
}

.chat_area .chat_area--conversation .name_time .email, .recent_message .dashboard__content .chat_area--conversation .name_time .email {
  color: #54667a;
  margin-top: 5px;
  display: inline-block;
}

.chat_area .chat_area--conversation .body, .recent_message .dashboard__content .chat_area--conversation .body {
  padding-left: 66px;
  margin-top: 19px;
}

.chat_area .chat_area--conversation .body p, .recent_message .dashboard__content .chat_area--conversation .body p {
  margin-bottom: 0;
}

.chat_area .chat_area--conversation .body .attachments, .recent_message .dashboard__content .chat_area--conversation .body .attachments {
  margin-top: 30px;
}

.chat_area .chat_area--conversation .body .attachments .attachment_head p, .recent_message .dashboard__content .chat_area--conversation .body .attachments .attachment_head p {
  display: inline-block;
  font-size: 15px;
  line-height: 26px;
  color: #54667a;
}

.chat_area .chat_area--conversation .body .attachments .attachment_head a, .recent_message .dashboard__content .chat_area--conversation .body .attachments .attachment_head a {
  display: inline-block;
  color: #0674ec;
  margin-left: 20px;
}

.chat_area .chat_area--conversation .body .attachments .attachment, .recent_message .dashboard__content .chat_area--conversation .body .attachments .attachment {
  margin-top: 15px;
}

.chat_area .chat_area--conversation .body .attachments .attachment ul li, .recent_message .dashboard__content .chat_area--conversation .body .attachments .attachment ul li {
  display: inline-block;
}

.chat_area .chat_area--conversation .body .attachments .attachment ul li + li, .recent_message .dashboard__content .chat_area--conversation .body .attachments .attachment ul li + li {
  margin-left: 20px;
}

.chat_area .message_composer, .recent_message .dashboard__content .message_composer {
  padding: 0 30px 30px;
}

.chat_area .message_composer .trumbowyg-box, .recent_message .dashboard__content .message_composer .trumbowyg-box {
  margin-top: 0;
}

.chat_area .message_composer .attached, .recent_message .dashboard__content .message_composer .attached {
  margin-top: 20px;
}

.chat_area .message_composer .attached p, .recent_message .dashboard__content .message_composer .attached p {
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  line-height: 36px;
  color: #0674ec;
  background: #f2f4f8;
  padding: 0 15px;
  border-radius: 3px;
  margin-right: 13px;
  margin-bottom: 13px;
}

.chat_area .message_composer .attached p span, .recent_message .dashboard__content .message_composer .attached p span {
  font-size: 16px;
  vertical-align: middle;
  display: inline-block;
  color: #647485;
  margin-left: 14px;
  cursor: pointer;
}

.chat_area .message_composer .composer_field, .recent_message .dashboard__content .message_composer .composer_field {
  min-height: 140px;
}

.chat_area .message_composer .btns, .recent_message .dashboard__content .message_composer .btns {
  margin-top: 30px;
}

.chat_area .message_composer .btns .send, .recent_message .dashboard__content .message_composer .btns .send {
  margin-right: 16px;
  padding: 0 40px;
}

.chat_area .message_composer .btns label, .recent_message .dashboard__content .message_composer .btns label, .chat_area .message_composer .btns .form-group p.label, .form-group .chat_area .message_composer .btns p.label, .recent_message .dashboard__content .message_composer .btns .form-group p.label, .form-group .recent_message .dashboard__content .message_composer .btns p.label {
  color: #0674ec;
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 0;
  cursor: pointer;
}

.chat_area .message_composer .btns label span, .recent_message .dashboard__content .message_composer .btns label span, .chat_area .message_composer .btns .form-group p.label span, .form-group .chat_area .message_composer .btns p.label span, .recent_message .dashboard__content .message_composer .btns .form-group p.label span, .form-group .recent_message .dashboard__content .message_composer .btns p.label span {
  color: #0674ec;
}

.chat_area .message_composer .btns label input, .recent_message .dashboard__content .message_composer .btns label input, .chat_area .message_composer .btns .form-group p.label input, .form-group .chat_area .message_composer .btns p.label input, .recent_message .dashboard__content .message_composer .btns .form-group p.label input, .form-group .recent_message .dashboard__content .message_composer .btns p.label input {
  display: none;
}

.chat_area .message_composer.composing, .recent_message .dashboard__content .message_composer.composing {
  padding-top: 30px;
}

.chat_area .message_composer.composing .recipient_field, .recent_message .dashboard__content .message_composer.composing .recipient_field {
  line-height: 38px;
  border: 1px solid #d0d9e2;
  border-radius: 3px;
  margin-bottom: 20px;
}

.chat_area .message_composer.composing .cancel_btn, .recent_message .dashboard__content .message_composer.composing .cancel_btn {
  background: #7347c1;
  float: right;
}

/***************************************
	END MESSAGE AREA
****************************************/
/***************************************
	START CART PAGE AREA
****************************************/
.cart_calculation {
  text-align: right;
}

.cart_calculation .cart--subtotal, .cart_calculation .cart--total {
  padding: 0 30px;
  border-bottom: 1px solid #ececec;
  border-left: 1px solid #ececec;
}

.cart_calculation .cart--subtotal p, .cart_calculation .cart--total p {
  color: #000;
  line-height: 77px;
}

.cart_calculation .cart--subtotal p span, .cart_calculation .cart--total p span {
  margin-right: 36px;
}

.cart_calculation .cart--subtotal p span:after, .cart_calculation .cart--total p span:after {
  content: ':';
}

.cart_calculation .cart--total p {
  font-weight: 500;
}

.cart_calculation .checkout_link {
  margin-top: 50px;
  margin-right: 30px;
}

.login_assist {
  margin-top: 40px;
}

.login_assist p {
  margin-bottom: 0;
  color: #898f96;
}

.login_assist p a {
  color: #0674ec;
}

.login_assist p a:hover {
  color: #7347c1;
}

.login_assist .recover {
  text-align: left;
  display: inline-block;
}

.login_assist .signup {
  text-align: right;
  float: right;
}

/***************************************
	END CART PAGE AREA
****************************************/
/***************************************
	START CHECKOUT CSS
****************************************/
.order_summary ul li {
  padding: 24px 30px;
  border-top: 1px solid #ececec;
}

.order_summary ul li.item a {
  color: #0674ec;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
}

.order_summary ul li.total_ammount {
  font-weight: 500;
  font-size: 22px;
}

.order_summary ul li.total_ammount p {
  font-size: 22px;
  font-weight: 500;
  color: #000;
}

.order_summary ul li p {
  display: inline-block;
  margin-bottom: 0;
}

.order_summary ul li span {
  float: right;
  color: #333;
}

.payment_options ul li {
  padding: 30px;
  border-top: 1px solid #ececec;
}

.payment_options ul li .custom-radio {
  display: inline-block;
}

.payment_options ul li .custom-radio label, .payment_options ul li .custom-radio .form-group p.label, .form-group .payment_options ul li .custom-radio p.label {
  font-size: 16px;
  color: #333;
}

.payment_options ul li img, .payment_options ul li p {
  float: right;
  line-height: 37px;
}

.payment_options ul li img span, .payment_options ul li p span {
  font-weight: 500;
  color: #000;
}

.payment_options .payment_info {
  padding: 0 30px 30px;
}

/***************************************
	END CHECKOUT CSS
****************************************/
/***************************************
	END CHECKOUT CSS
****************************************/
.login_area, .signup_area, .pass_recover_area {
  background: #eff1f5;
}

.login, .signup_form, .recover_pass {
  padding: 60px 0;
}

.login .login--header, .signup_form .login--header, .recover_pass .login--header {
  border-bottom: 1px solid #ececec;
  text-align: center;
  padding: 0 50px 26px;
}

.login .login--header h3, .signup_form .login--header h3, .recover_pass .login--header h3 {
  color: #000;
  font-size: 26px;
  padding-bottom: 20px;
}

.login .login--header p, .signup_form .login--header p, .recover_pass .login--header p {
  margin-bottom: 0;
  line-height: 28px;
}

.login .login--form, .signup_form .login--form, .recover_pass .login--form {
  padding: 25px 50px 0;
}

/***************************************
	END CHECKOUT CSS
****************************************/
.signup_area .register_btn {
  margin-top: 10px;
}

.recover_pass .login--header {
  text-align: left;
}

/***************************************
	START SUPPORT AREA CSS
****************************************/
.support_threads_area {
  background: #eff1f5;
}

.thread_sort_area {
  margin-bottom: 30px;
  padding: 22px 30px;
}

.thread_sort_area .sort_options {
  float: left;
  padding: 9px 0;
}

.thread_sort_area .sort_options ul li {
  display: inline-block;
  margin-right: -4px;
}

.thread_sort_area .sort_options ul li:hover a, .thread_sort_area .sort_options ul li.active a {
  color: #fff;
  background: #0674ec;
  border-radius: 200px;
}

.thread_sort_area .sort_options ul li a {
  color: #747a86;
  font-size: 16px;
  transition: 0.3s ease;
  line-height: 28px;
  display: inline-block;
  padding: 0 14px;
}

.thread_sort_area .thread_search_area {
  float: right;
}

.thread_sort_area .thread_search_area .searc-wrap input {
  width: 260px;
}

.support_thread_list .support_thread--single {
  padding: 17px 30px 25px;
  border-bottom: 1px solid #ececec;
}

.support_thread_list .support_thread--single:before, .support_thread_list .support_thread--single:after {
  content: '';
  display: table;
}

.support_thread_list .support_thread--single:after {
  clear: both;
}

.support_thread_list .support_thread--single .support_thread_info .support_title {
  color: #333333;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
}

.support_thread_list .support_thread--single .support_thread_info .support_title:hover {
  color: #0674ec;
}

.support_thread_list .support_thread--single .support_thread_info .support_title span {
  font-weight: 400;
  color: #fff;
  font-size: 12px;
  line-height: 24px;
  display: inline-block;
  padding: 0 12px;
  border-radius: 200px;
  text-transform: uppercase;
}

.support_thread_list .support_thread--single .support_thread_info .support_title span.pinned {
  background: #0674ec;
}

.support_thread_list .support_thread--single .support_thread_info .support_title span.closed {
  background: #ff6a6d;
}

.support_thread_list .support_thread--single .support_thread_info .support_title span.solved {
  background: #77de51;
}

.support_thread_list .support_thread--single .support_thread_info .suppot_query_tag {
  margin-top: 12px;
}

.support_thread_list .support_thread--single .support_thread_info .suppot_query_tag .user {
  display: inline-block;
}

.support_thread_list .support_thread--single .support_thread_info .suppot_query_tag .user img {
  width: 25px;
  border-bottom-left-radius: 50%;
  height: 25px;
  margin-right: 3px;
}

.support_thread_list .support_thread--single .support_thread_info .support_tag {
  display: inline-block;
  line-height: 26px;
  -webkit-border-radius: 200px;
  -moz-border-radius: 200px;
  border-radius: 200px;
  padding: 0 12px;
  color: #7347c1;
  margin-left: 9px;
  font-size: 12px;
  font-weight: 500;
  background: rgba(115, 71, 193, 0.1);
}

.support_thread_list .support_thread--single .support_thread_info {
  float: left;
  width: calc(100% - 260px);
}

.support_thread_list .support_thread--single .support_thread_meta {
  float: right;
}

.support_thread_list .support_thread--single .support_thread_meta ul li {
  display: inline-block;
  text-align: center;
  padding: 5px 18px 7px;
  -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

.support_thread_list .support_thread--single .support_thread_meta ul li p {
  margin-bottom: 0;
}

.support_thread_list .support_thread--single .support_thread_meta ul li .number {
  font-weight: 500;
  color: #000;
}

.support_thread_list .support_thread--single .support_thread_meta ul li span {
  color: #747a86;
  font-size: 14px;
}

.support_thread_list .support_thread--single .support_thread_meta ul li.answer_time {
  box-shadow: 0 0 0;
  padding: 5px 0;
  width: 70px;
}

.support_thread_list .support_thread--single .support_thread_meta ul li.answer_time img {
  display: inline-block;
}

.support_thread_list .support_thread--single .support_thread_meta ul li.answer_time span {
  display: block;
  color: #333;
  line-height: 28px;
}

.support_thread_list .support_thread--single .support_thread_meta ul li + li {
  margin-left: 10px;
}

/***************************************
	END SUPPORT AREA CSS
****************************************/
/***************************************
	START HOW IT WORKS
****************************************/
.how_it_works h2 {
  font-size: 30px;
  line-height: 46px;
  color: #000000;
  padding-bottom: 30px;
}

.how_it_works p {
  margin-bottom: 20px;
}

.how_it_works_module, .content_block3 {
  padding: 100px 0;
}

.how_it_works_module:nth-last-child(2n+2), .content_block3:nth-last-child(2n+2) {
  background: #eff1f5;
}

.how_it_works_module .area_content a:hover, .content_block3 .area_content a:hover {
  background: #0674ec;
  color: #fff;
}

/***************************************
	END HOW IT WORKS
****************************************/
/***************************************
	START FORUM AREA
****************************************/
.forum_detail_area .forum--issue {
  padding: 30px;
  margin-bottom: 30px;
}

.forum_detail_area .forum--issue .title_vote {
  margin-bottom: 6px;
}

.forum_detail_area .forum--issue .title_vote h3 {
  display: inline-block;
  font-size: 24px;
  line-height: 28px;
  margin-top: 4px;
}

.forum_detail_area .forum--issue .suppot_query_tag .user img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.forum_detail_area .forum--issue .suppot_query_tag span {
  color: #747a86;
  margin-left: 3px;
}

.forum_detail_area .forum--issue .suppot_query_tag .support_tag {
  background: #7347c1;
  color: #fff;
  line-height: 28px;
  padding: 0 10px;
  display: inline-block;
  border-radius: 200px;
  margin-left: 6px;
  margin-bottom: 25px;
}

.forum_detail_area .vote {
  float: right;
  background: #0674ec;
  padding: 12px 14px;
  border-radius: 4px;
}

.forum_detail_area .vote a {
  color: #8cbef4;
}

.forum_detail_area .vote a + a {
  margin-left: 10px;
}

.forum_detail_area .vote a.active {
  color: #fff;
}

.forum_detail_area .forum--replays .area_title {
  padding: 30px;
}

.forum_detail_area .forum--replays .area_title h3 {
  font-size: 20px;
  color: #000;
}

.forum_detail_area .forum--replays .forum_single_reply {
  padding: 30px;
  border-top: 1px solid #ececec;
}

.forum_detail_area .forum--replays .forum_single_reply .reply_avatar {
  display: inline-block;
}

.forum_detail_area .forum--replays .forum_single_reply .reply_avatar img {
  width: 60px;
}

.forum_detail_area .forum--replays .forum_single_reply .reply_content {
  display: inline-block;
  width: calc(100% - 66px);
  vertical-align: top;
  padding-left: 18px;
}

.forum_detail_area .forum--replays .forum_single_reply .reply_content > p {
  margin-bottom: 0;
  margin-top: 19px;
}

.forum_detail_area .forum--replays .forum_single_reply .name_vote:before, .forum_detail_area .forum--replays .forum_single_reply .name_vote:after {
  content: '';
  display: table;
}

.forum_detail_area .forum--replays .forum_single_reply .name_vote:after {
  clear: both;
}

.forum_detail_area .forum--replays .forum_single_reply .name_vote h4 {
  color: #000;
  font-weight: 500;
}

.forum_detail_area .forum--replays .forum_single_reply .name_vote h4 span {
  color: #fff;
  background: #0674ec;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: inline-block;
  padding: 0 10px;
  border-radius: 200px;
}

.forum_detail_area .forum--replays .forum_single_reply .name_vote p {
  font-size: 15px;
  color: #747a86;
  margin-bottom: 0;
}

.forum_detail_area .comment-form-area {
  border-top: 1px solid #ececec;
}

.forum_detail_area .comment-form-area .support__comment .comment-reply-form .trumbowyg-box button, .forum_detail_area .comment-form-area .support__comment .comment-form .trumbowyg-box button {
  margin: 0;
}

/***************************************
	END FORUM AREA CSS
****************************************/
/***************************************
	START ABOUT US  HERO AREA CSS
****************************************/
.about_hero {
  position: relative;
  text-align: center;
  padding: 191px 0;
}

.about_hero:before {
  content: '';
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  background: linear-gradient(to left, #7347c1, #0674ec);
  opacity: 0.8;
}

.about_hero .about_hero_contents h1 {
  font-weight: 500;
}

.about_hero .about_hero_contents h1, .about_hero .about_hero_contents p {
  font-size: 42px;
  line-height: 62px;
  color: #fff;
}

.about_hero .about_hero_contents p {
  font-weight: 300;
}

.about_hero .about_hero_contents p span {
  font-weight: 500;
}

.about_hero .about_hero_contents .about_hero_btns {
  padding-top: 44px;
}

.about_hero .about_hero_contents .about_hero_btns .play_btn {
  color: #fff;
  font-size: 16px;
  margin-right: 28px;
  line-height: 72px;
  display: inline-block;
}

.about_hero .about_hero_contents .about_hero_btns .play_btn img {
  margin-right: 8px;
}

.about_hero .about_hero_contents .about_hero_btns a {
  display: inline-block;
  vertical-align: middle;
}

/***************************************
     END ABOUT US HERO AREA CSS
****************************************/
/***************************************
     START GALLERY AREA
****************************************/
.gallery_area {
  padding: 20px;
}

.gallery_area .gallery_contents_wrapper {
  padding: 210px 0;
}

.gallery_area .gallery_contents {
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
  padding: 53px 67px 67px;
}

.gallery_area .gallery_contents h3 {
  color: #fff;
  font-size: 44px;
  line-height: 72px;
  font-weight: 500;
}

.gallery_area .gallery_contents p {
  color: #fff;
  margin-bottom: 0;
  font-size: 20px;
  line-height: 36px;
}

/***************************************
     END GALLERY AREA
****************************************/
/***************************************
     START TESTIMONIAL VIDEO
****************************************/
.tesimonial-page-area {
  background: #eff1f5;
}

.tesimonial-page-area .testimonial {
  margin: 0 0 30px 0;
}

.testimonial_video {
  margin-bottom: 30px;
  position: relative;
}

.testimonial_video:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.testimonial_video .video_play {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}

.testimonial_video .video_play .figure img {
  border-radius: 5px;
}

.testimonial_video .video_play button {
  background: transparent;
  border: none;
}

.testimonial_vid .modal-content {
  height: 600px;
}

.testimonial_vid .modal-content iframe {
  width: 100%;
  height: 100%;
}

/***************************************
      END TESTIMONIAL AREA
****************************************/
/***************************************
     START PRICING AREA
****************************************/
.pricing_area {
  background: #eff1f5;
}

/***************************************
     END PRICING AREA
****************************************/
/***************************************
     START FAQ AREA
****************************************/
.faq_area {
  background: #eff1f5;
}

.faq-title {
  border-bottom: 1px solid #ececec;
  padding: 33px 30px;
}

.faq-title span {
  display: inline-block;
  color: #0674ec;
  font-size: 20px;
  line-height: 32px;
  vertical-align: middle;
  margin-right: 14px;
}

.faq-title h4 {
  display: inline-block;
  vertical-align: middle;
  font-size: 20px;
  line-height: 32px;
  color: #000;
}

.faqs {
  padding: 30px;
}

/***************************************
     END FAQ AREA
****************************************/
/***************************************
     START AFFILIATE PAGE
****************************************/
.affiliate_area {
  background: #eff1f5;
}

.affiliate_area .affiliate_form {
  padding: 30px;
}

.affiliate_area .generated {
  text-align: center;
  background: rgba(6, 116, 236, 0.1);
  padding: 30px;
  border-radius: 2px;
  margin-top: 50px;
}

.affiliate_area .generated p {
  margin-bottom: 0;
  color: #010101;
  font-weight: 500;
}

.affiliate_area .generated .link {
  color: #0674ec;
}

.affliate_rule_module .affiliate_title {
  padding: 37px 30px 25px;
  border-bottom: 1px solid #ececec;
}

.affliate_rule_module .affiliate_title span {
  font-size: 24px;
  vertical-align: middle;
  margin-right: 13px;
}

.affliate_rule_module .affiliate_title span.lnr-checkmark-circle {
  color: #0674ec;
}

.affliate_rule_module .affiliate_title span.lnr-cross-circle {
  color: #ff6a6d;
}

.affliate_rule_module .affiliate_title h4 {
  display: inline-block;
  vertical-align: middle;
  font-size: 24px;
}

.affliate_rule_module .collapsible-content {
  padding: 25px 30px;
}

/***************************************
     END AFFILIATE PAGE
****************************************/
.affliate_rules {
  background: #e8ebf1;
}

/***************************************
     START TERM AND CONDITION AREA
****************************************/
.term_condition_area {
  background: #eff1f5;
  padding: 60px 0 120px;
}

.term_modules {
  padding-bottom: 50px;
}

.term_modules .term .term__title {
  padding: 32px 30px 22px;
  border-bottom: 1px solid #ececec;
}

.term_modules .term .term__title h4 {
  font-size: 24px;
  color: #000;
  line-height: 32px;
}

.term_modules .term p {
  padding: 30px;
}

/***************************************
     END TERM AND CONDITION AREA
****************************************/
/***************************************
     START BLOG AREA
****************************************/
.blog_area {
  background: #eff1f5;
}

.single_blog {
  background: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  -moz-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
}

.single_blog figure img {
  border-radius: 4px 4px 0 0;
  width: 100%;
}

.single_blog .blog__content {
  padding-left: 30px;
  padding-right: 30px;
}

.single_blog .blog__title h4 {
  font-weight: 500;
  color: #000;
  transition: 0.3s;
}

.single_blog .blog__title:hover h4 {
  color: #0674ec;
}

/* Blog Card Modifier */
.blog--card {
  margin-bottom: 30px;
}

.blog--card .blog__content {
  padding-top: 30px;
  padding-bottom: 26px;
}

.blog--card .blog__content p {
  margin-bottom: 0;
}

.blog--card .blog__title {
  display: inline-block;
  margin-bottom: 23px;
}

.blog--card .blog__title h4 {
  font-size: 22px;
}

.blog--card .blog__meta {
  padding: 13px 30px;
  border-top: 1px solid #ececec;
}

.blog--card .blog__meta:before, .blog--card .blog__meta:after {
  content: '';
  display: table;
  clear: both;
}

.blog--card .blog__meta .date_time, .blog--card .blog__meta .comment_view {
  width: 50%;
  float: left;
}

.blog--card .blog__meta .date_time p, .blog--card .blog__meta .comment_view p {
  display: inline-block;
  margin-bottom: 0;
  color: #7e7e7e;
  font-size: 15px;
  line-height: 30px;
}

.blog--card .blog__meta .date_time p span, .blog--card .blog__meta .comment_view p span {
  margin-right: 6px;
}

.blog--card .blog__meta .date_time {
  text-align: left;
  position: relative;
}

.blog--card .blog__meta .date_time span {
  color: #0674ec;
  vertical-align: middle;
  line-height: 30px;
}

.blog--card .blog__meta .date_time:before {
  content: '';
  width: 1px;
  height: 100%;
  right: 0;
  top: 0;
  background: #ececec;
  position: absolute;
}

.blog--card .blog__meta .comment_view {
  text-align: right;
}

.blog--card .blog__meta .comment_view p + p {
  margin-left: 26px;
}

/* Blog default */
.blog--default {
  margin-bottom: 30px;
}

.blog--default .blog__title {
  padding-top: 35px;
  display: inline-block;
  padding-bottom: 3px;
}

.blog--default .blog__title h4 {
  font-size: 30px;
  line-height: 100%;
}

.blog--default .blog__content {
  border-bottom: 1px solid #ececec;
  padding-bottom: 10px;
}

.blog--default .blog__content .blog__meta .date_time, .blog--default .blog__content .blog__meta .comment_view, .blog--default .blog__content .blog__meta .author {
  display: inline-block;
}

.blog--default .blog__content .blog__meta .date_time p, .blog--default .blog__content .blog__meta .comment_view p, .blog--default .blog__content .blog__meta .author p {
  display: inline-block;
  margin-right: 25px;
  color: #898f96;
}

.blog--default .blog__content .blog__meta .date_time p span, .blog--default .blog__content .blog__meta .comment_view p span, .blog--default .blog__content .blog__meta .author p span {
  margin-right: 6px;
}

.blog--default .blog__content .blog__meta .author .lnr-user {
  margin-right: 7px;
}

.blog--default .blog__content .blog__meta .date_time span {
  margin-right: 6px;
}

.blog--default .btn_text {
  padding: 25px 30px 30px;
}

.blog--default .btn_text .btn {
  margin-top: 10px;
}

.single_blog_content {
  padding: 25px 30px;
}

.single_blog_content p {
  line-height: 30px;
}

.single_blog_content blockquote {
  background: #f2f8fe;
  font-size: 20px;
  line-height: 38px;
  color: #000;
  font-style: normal;
  padding: 45px 25px 45px 40px;
  border-left: 3px solid #0674ec;
  margin-bottom: 35px;
}

.single_blog_content h1, .single_blog_content h2, .single_blog_content h3, .single_blog_content h4, .single_blog_content h5, .single_blog_content h6 {
  font-weight: 500;
  color: #333;
  margin-bottom: 15px;
}

.single_blog_content h1 {
  font-size: 24px;
}

.single_blog_content h2 {
  font-size: 20px;
}

.single_blog_content h3 {
  font-size: 18px;
}

.single_blog_content h4 {
  font-size: 16px;
}

.single_blog_content ol {
  margin-bottom: 30px;
}

.single_blog_content ol li {
  list-style-type: decimal;
  line-height: 32px;
  font-size: 16px;
  color: #555;
}

.single_blog_content img {
  margin-bottom: 20px;
}

.single_blog_content ul {
  margin: 0 0 30px 30px;
}

.single_blog_content ul li {
  line-height: 32px;
  font-size: 16px;
  position: relative;
  padding-left: 27px;
}

.single_blog_content ul li:before {
  content: '';
  height: 8px;
  width: 8px;
  position: absolute;
  border-radius: 50px;
  background: #555555;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.single_blog_content .share_tags {
  background: #eff1f5;
  padding: 20px;
  margin-top: 45px;
  position: relative;
  overflow: hidden;
}

.single_blog_content .share_tags ul {
  margin: 0;
}

.single_blog_content .share_tags li {
  display: inline-block;
  padding-left: 0;
}

.single_blog_content .share_tags li:before {
  display: none;
}

.single_blog_content .share_tags .share {
  float: left;
}

.single_blog_content .share_tags .share p {
  display: inline-block;
  padding: 0 20px;
  background: #fff;
  border-radius: 200px;
  margin-right: 10px;
  line-height: 38px;
  margin-bottom: 0;
  color: #777;
}

.single_blog_content .share_tags .share .social_share {
  display: inline-block;
}

.single_blog_content .share_tags .share .social_share ul li {
  margin-right: 4px;
}

.single_blog_content .share_tags .share .social_share ul li a {
  width: 38px;
  line-height: 38px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  font-size: 14px;
}

.single_blog_content .share_tags .tags {
  float: right;
  margin-top: 4px;
}

.single_blog_content .share_tags .tags ul li {
  display: inline-block;
}

.single_blog_content .share_tags .tags ul li + li {
  padding-left: 10px;
}

.single_blog_content .share_tags .tags ul li a {
  font-size: 15px;
  color: #0674ec;
  background: rgba(6, 116, 236, 0.07);
  line-height: 30px;
  padding: 0 14px;
  display: inline-block;
  -webkit-border-radius: 200px;
  -moz-border-radius: 200px;
  border-radius: 200px;
}

.author_info {
  margin-bottom: 30px;
  padding: 36px 26px;
}

.author_info .author__img {
  height: 115px;
  width: 115px;
  display: inline-block;
  vertical-align: top;
}

.author_info .author__img img {
  min-width: 100%;
  border-radius: 500px;
}

.author_info .author__info {
  display: inline-block;
  width: calc(100% - 120px);
  padding-left: 30px;
}

.author_info .author__info h4 {
  font-size: 20px;
  margin-bottom: 20px;
}

.author_info .author__info ul li {
  display: inline-block;
}

.author_info .author__info ul li + li {
  margin-left: 16px;
}

.author_info .author__info ul a {
  font-size: 14px;
  color: #a9a9a9;
  transition: 0.3s;
}

.author_info .author__info ul a:hover {
  color: #0674ec;
}

.author_info .author__info p {
  margin-bottom: 15px;
}

.comment_area.comment--form {
  margin-top: 40px;
}

.comment_area .comment__title {
  padding: 28px 30px 19px;
  border-bottom: 1px solid #ececec;
}

.comment_area .comment__title h4 {
  font-size: 20px;
  line-height: 42px;
}

.comment_area .comment___wrapper .media-list {
  padding: 0 30px;
}

.comment_area .comment___wrapper .media-list li + li {
  border-top: 1px solid #ececec;
}

.comment_area .comment___wrapper .media-list .media {
  padding-bottom: 15px;
  padding-top: 24px;
}

.comment_area .comment___wrapper .media-list .cmnt_avatar {
  height: 90px;
  width: 90px;
}

.comment_area .comment___wrapper .media-list .cmnt_avatar img {
  border-radius: 200px;
  max-width: 100%;
}

.comment_area .comment___wrapper .media-list .media_top {
  overflow: hidden;
  margin-bottom: 15px;
}

.comment_area .comment___wrapper .media-list .media-heading {
  font-size: 18px;
  line-height: 28px;
  color: #000;
}

.comment_area .comment___wrapper .media-list .media-body {
  padding-left: 20px;
}

.comment_area .comment___wrapper .media-list .heading_left a h4 {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 0;
}

.comment_area .comment___wrapper .media-list .heading_left span {
  color: #555;
  font-size: 15px;
  line-height: 28px;
}

.comment_area .comment___wrapper .media-list .reply.visible-xs-m {
  display: none;
}

.comment_area .comment___wrapper .media-list .children > .depth-2 {
  padding-left: 114px;
}

.comment_area .comment___wrapper .media-list .children > .depth-2 .media {
  border-top: 1px solid #ececec;
}

.comment_area .commnet_form_wrapper {
  padding: 30px;
}

/***************************************
       END BLOG AREA
 ****************************************/
/***************************************
       START CONTACT AREA
 ****************************************/
.contact-area {
  background: #eff1f5;
}

.contact_tile {
  padding: 55px 38px 45px;
  margin-bottom: 30px;
  text-align: center;
}

.contact_tile .tiles__icon {
  font-size: 48px;
  color: #0674ec;
}

.contact_tile .tiles__title {
  color: #000;
  font-weight: 500;
  font-size: 20px;
  padding: 37px 0 24px;
  line-height: 30px;
}

.contact_tile .tiles__content p {
  margin-bottom: 0;
}

.contact_form .contact_form__title, .comment_area .cmnt_reply_form .contact_form__title, .apply_form .contact_form__title, .ticket_form .contact_form__title {
  padding: 50px 0 45px;
  border-bottom: 1px solid #ececec;
  text-align: center;
}

.contact_form .contact_form__title h3, .comment_area .cmnt_reply_form .contact_form__title h3, .apply_form .contact_form__title h3, .ticket_form .contact_form__title h3 {
  color: #000;
}

.contact_form .form-group, .comment_area .cmnt_reply_form .form-group, .apply_form .form-group, .ticket_form .form-group {
  margin-bottom: 30px;
}

.contact_form .contact_form--wrapper, .comment_area .cmnt_reply_form .contact_form--wrapper, .apply_form .contact_form--wrapper, .ticket_form .contact_form--wrapper {
  padding: 40px 0 60px;
}

.contact_form input, .comment_area .cmnt_reply_form input, .apply_form input, .ticket_form input {
  line-height: 48px;
  height: 48px;
  color: #9e9e9e;
  border: 1px solid #e7e7e7;
}

.contact_form textarea, .comment_area .cmnt_reply_form textarea, .apply_form textarea, .ticket_form textarea {
  border: 1px solid #e7e7e7;
  padding-top: 20px;
  height: 129px;
}

.contact_form .sub_btn, .comment_area .cmnt_reply_form .sub_btn, .apply_form .sub_btn, .ticket_form .sub_btn {
  text-align: center;
  margin-top: 30px;
}

/***************************************
       END CONTACT AREA
****************************************/
/***************************************
       START 404 AREA
****************************************/
.not_found {
  margin-top: 20px;
}

.not_found h3 {
  font-size: 30px;
  line-height: 80px;
  color: #000;
  margin-bottom: 16px;
}

/***************************************
       END 404 AREA
****************************************/
/***************************************
       START JOB HERO HERO
****************************************/
.job_hero_area {
  padding-top: 176px;
  padding-bottom: 127px;
  position: relative;
  text-align: center;
}

.job_hero_area:before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(23, 28, 35, 0.7);
}

.job_hero_area h1 {
  color: #fff;
  font-weight: 500;
  font-size: 48px;
  line-height: 62px;
}

.job_hero_area h1 .highlight {
  color: #0674ec;
}

.job_hero_area p {
  padding: 30px 0 73px;
  color: #fff;
  font-size: 20px;
  line-height: 34px;
}

/***************************************
       END JOB HERO AREA
****************************************/
/***************************************
       START JOB  AREA
****************************************/
.job_area {
  background: #f0f1f5;
}

/***************************************
       END JOB AREA
****************************************/
/***************************************
       START FEATURE AREA
****************************************/
.content_block5 {
  background: #f0f1f5;
}

.content_block5 .content_block5_content h1 {
  font-size: 36px;
  line-height: 46px;
  margin-bottom: 34px;
}

.content_block5 .content_block5_content h1 span {
  color: #0674ec;
}

.content_block5 .content_block5_content p {
  margin-bottom: 45px;
}

/***************************************
       END FEATURE AREA
****************************************/
/***************************************
       START JOB DETAIL
****************************************/
.job_detail .job_hero_content, .event_detail_breadcrumb .job_hero_content {
  margin-bottom: 55px;
}

.job_detail .job_hero_content .job_date, .event_detail_breadcrumb .job_hero_content .job_date {
  display: inline-block;
  margin-top: 32px;
}

.job_detail .job_hero_content .job_date p, .event_detail_breadcrumb .job_hero_content .job_date p {
  padding: 0;
  display: inline-block;
  color: #d7d7d7;
  font-size: 18px;
}

.job_detail .job_hero_content .job_date p + p, .event_detail_breadcrumb .job_hero_content .job_date p + p {
  margin-left: 53px;
}

.job_detail_module {
  background: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  -moz-box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
  box-shadow: 0 2px 4px rgba(108, 111, 115, 0.1);
}

.job_detail_module .job__title {
  border-bottom: 1px solid #ececec;
  padding: 23px 30px 11px;
  font-size: 24px;
  line-height: 66px;
}

.job_detail_module .job__content {
  padding: 34px 30px 16px;
}

.job_detail_module .job__content .informations {
  overflow: hidden;
}

.job_detail_module .job__content .informations li {
  float: left;
  width: 33%;
  margin-bottom: 46px;
}

.job_detail_module .job__content .informations li .info_title, .job_detail_module .job__content .informations li .info {
  display: inline-block;
}

.job_detail_module .job__content .informations li .info_title {
  min-width: calc(100% - 228px);
}

.job_detail_module .job__content .informations li .info_title span {
  display: inline-block;
  font-size: 20px;
  color: #0674ec;
  line-height: 30px;
  vertical-align: middle;
  margin-right: 12px;
}

.job_detail_module .job__content .informations li .info_title p {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 500;
  color: #333333;
  line-height: 30px;
  vertical-align: middle;
}

.job_detail_module .job__content .informations li .info p {
  margin-bottom: 0;
}

.job_detail_module .job__content .content_list {
  padding-left: 30px;
  margin-top: 25px;
}

.job_detail_module .job__content .content_list li {
  padding-left: 26px;
  position: relative;
  line-height: 30px;
  color: #555;
}

.job_detail_module .job__content .content_list li:before {
  content: "";
  height: 7px;
  width: 7px;
  background: #c5cad4;
  position: absolute;
  left: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.job_detail_module .job__content .social, .job_detail_module .job__content .single_blog_content .share_tags .share .social_share, .single_blog_content .share_tags .share .job_detail_module .job__content .social_share {
  margin-top: 72px;
  margin-bottom: 60px;
}

.job_detail_module .job__content .social p, .job_detail_module .job__content .single_blog_content .share_tags .share .social_share p, .single_blog_content .share_tags .share .job_detail_module .job__content .social_share p {
  display: inline-block;
  font-weight: 500;
  color: #000;
  margin-right: 20px;
}

.job_detail_module .job__content .social ul, .job_detail_module .job__content .single_blog_content .share_tags .share .social_share ul, .single_blog_content .share_tags .share .job_detail_module .job__content .social_share ul {
  display: inline-block;
}

.job_detail_module .job__content .social ul li, .job_detail_module .job__content .single_blog_content .share_tags .share .social_share ul li, .single_blog_content .share_tags .share .job_detail_module .job__content .social_share ul li {
  padding-left: 0;
}

.job_detail_module .job__content .social ul li:before, .job_detail_module .job__content .single_blog_content .share_tags .share .social_share ul li:before, .single_blog_content .share_tags .share .job_detail_module .job__content .social_share ul li:before {
  display: none;
}

.job_detail_module .job__content .social ul li + li, .job_detail_module .job__content .single_blog_content .share_tags .share .social_share ul li + li, .single_blog_content .share_tags .share .job_detail_module .job__content .social_share ul li + li {
  margin-left: 6px;
}

.job_detail_module .job__content .social ul li a span, .job_detail_module .job__content .single_blog_content .share_tags .share .social_share ul li a span, .single_blog_content .share_tags .share .job_detail_module .job__content .social_share ul li a span {
  box-shadow: 0 5px 10px rgba(35, 54, 72, 0.2);
}

.job_detail_module.information {
  margin-bottom: 40px;
}

.job_detail_module.information .job__title {
  color: #0674ec;
}

.job_detail_module.information .job__content {
  padding: 34px 30px 0;
}

/***************************************
       END JOB DETAIL AREA
****************************************/
/***************************************
       START APPLY FORM
****************************************/
.cv_upload {
  width: 100%;
  border: 1px solid #ececec;
  height: 48px;
  padding: 0 20px;
}

.cv_upload input {
  width: 100%;
  display: none;
}

.cv_upload span {
  line-height: 48px;
}

.cv_upload .up_icon {
  float: right;
  font-size: 16px;
  color: #0674ec;
}

/***************************************
       END APPLY FORM
****************************************/
/***************************************
       END EVENT AREA
****************************************/
.event_area {
  background: #eff1f5;
}

.featured_event {
  overflow: hidden;
  margin: 0 0 30px;
}

.featured_event .col-md-6 {
  padding: 0;
}

.featured_event .event_img {
  border-radius: 4px 0 0 4px;
}

.featured_event .event_img img {
  max-width: 100%;
}

.featured_event .featured_event_detail {
  padding-left: 15px;
}

.featured_event h1 {
  font-size: 36px;
  line-height: 38px;
  font-weight: 500;
  margin-bottom: 40px;
}

.featured_event .date_place {
  margin-bottom: 30px;
}

.featured_event .date_place li {
  display: inline-block;
}

.featured_event .date_place li + li {
  margin-left: 32px;
}

.featured_event .date_place li span, .featured_event .date_place li p {
  display: inline-block;
  display: inline-block;
  vertical-align: middle;
  margin-right: -3px;
}

.featured_event .date_place li span {
  color: #0674ec;
  font-size: 20px;
  margin-right: 12px;
}

.featured_event .date_place li p {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
}

.featured_event .countdown li {
  display: inline-block;
  color: #0674ec;
  font-size: 48px;
  font-weight: 500;
  text-align: center;
}

.featured_event .countdown li + li {
  margin-left: 35px;
}

.featured_event .countdown li span {
  display: block;
  font-size: 16px;
  color: #555555;
  line-height: 30px;
  text-transform: capitalize;
}

/***************************************
        END EVENT AREA
****************************************/
/***************************************
        START EVENT DETAIL AREA
****************************************/
.event_detail_breadcrumb:before {
  background: rgba(23, 28, 35, 0.4);
}

.event_detail_breadcrumb .social, .event_detail_breadcrumb .single_blog_content .share_tags .share .social_share, .single_blog_content .share_tags .share .event_detail_breadcrumb .social_share {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%);
}

.event_detail_breadcrumb .social p, .event_detail_breadcrumb .single_blog_content .share_tags .share .social_share p, .single_blog_content .share_tags .share .event_detail_breadcrumb .social_share p {
  display: inline-block;
  margin-bottom: 0;
  background: #fff;
  line-height: 50px;
  padding: 0 30px;
  color: #000;
  font-size: 18px;
  font-weight: 500;
  border-radius: 200px;
  margin-right: 20px;
  -webkit-box-shadow: 0 5px 20px rgba(35, 54, 72, 0.2);
  -moz-box-shadow: 0 5px 20px rgba(35, 54, 72, 0.2);
  box-shadow: 0 5px 20px rgba(35, 54, 72, 0.2);
}

.event_detail_breadcrumb .social ul, .event_detail_breadcrumb .single_blog_content .share_tags .share .social_share ul, .single_blog_content .share_tags .share .event_detail_breadcrumb .social_share ul {
  display: inline-block;
}

.event_module {
  border-bottom: 1px solid #ececec;
  padding-bottom: 92px;
  margin-bottom: 80px;
}

.event_module:before, .event_module:after {
  content: '';
  display: table;
  clear: both;
}

.event_module .event_module__title {
  font-size: 36px;
  line-height: 48px;
  font-weight: 500;
  padding-bottom: 43px;
}

.event_module .list_item {
  width: 50%;
  float: left;
  padding-top: 23px;
}

.event_module .list_item li {
  color: #333;
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
}

.event_module .list_item li:before {
  content: "\f00c";
  font-family: FontAwesome,sans-serif;
  color: #0674ec;
  margin-right: 18px;
}

.event_module.sponsor--area {
  border-bottom: 0;
  padding-bottom: 0;
}

.sponsores li {
  display: inline-block;
  box-shadow: 0 0 20px rgba(74, 74, 74, 0.1);
}

.sponsores li img {
  max-width: 250px;
}

.sponsores li + li {
  margin-left: 40px;
}

.single_speaker {
  width: 50%;
  float: left;
  margin-bottom: 60px;
}

.single_speaker .speaker__thumbnail {
  height: 263px;
  width: 263px;
  display: inline-block;
  vertical-align: middle;
  margin-right: -3px;
}

.single_speaker .speaker__thumbnail img {
  border-radius: 50%;
}

.single_speaker .speaker__detail {
  display: inline-block;
  vertical-align: middle;
  margin-right: -3px;
  padding-left: 30px;
  width: calc(100% - 268px);
}

.single_speaker .speaker__detail h4 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  margin-bottom: 10px;
}

.single_speaker .speaker__detail p {
  line-height: 30px;
}

.single_speaker .speaker__detail .ocuup {
  color: #555;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 27px;
  font-size: 18px;
  display: inline-block;
}

.single_speaker .speaker_social {
  margin-top: 33px;
}

.single_speaker .speaker_social ul li {
  display: inline-block;
  margin-right: 4px;
}

.single_speaker .speaker_social ul li a:hover span {
  background: #7347c1;
}

.single_speaker .speaker_social ul li span {
  transition: 0.3s ease;
  line-height: 40px;
  color: #fff;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  background: #0674ec;
}

.single_speaker:nth-child(2n+2) {
  padding-left: 15px;
}

.single_speaker:nth-child(2n+1) {
  padding-right: 15px;
}

.sign_up_area {
  padding-top: 90px;
}

.sign_up_area .sign_up_title {
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  padding-bottom: 55px;
}

/***************************************
        END EVENT DETAIL AREA
****************************************/
/***************************************
        START GOOGLE MAP AREA
****************************************/
#map {
  height: 500px;
}

.google_map {
  position: relative;
}

.google_map .location_address {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 361px;
  left: calc((100vw - 1110px) / 2);
  z-index: 1;
}

.google_map .location_address .addres_module {
  padding: 50px 40px;
}

.google_map .location_address .addres_module h4 {
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 55px;
  font-weight: 500;
}

.google_map .location_address .addres_module p {
  font-weight: 500;
  font-size: 18px;
}

.google_map .location_address .addres_module p span {
  color: #0674ec;
  margin-right: 12px;
}

/***************************************
        END GOOGLE MAP
****************************************/
/***************************************
        START TICKET AREA
****************************************/
.ticket_form .sub_btn {
  text-align: left;
}

/***************************************
        END TICKET AREA
****************************************/
.btn .br-wrapper {
  position: relative;
  z-index: 2;
}

/* bar rating scss */
.br-theme-fontawesome-stars .br-widget {
  white-space: nowrap;
}

.br-theme-fontawesome-stars .br-widget a {
  font: normal normal normal 15px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  text-decoration: none;
  margin-right: 2px;
}

.br-theme-fontawesome-stars .br-widget a + a {
  margin-left: 2px;
}

.br-theme-fontawesome-stars .br-widget a:after {
  content: "\f006";
  color: #898f96;
}

.br-theme-fontawesome-stars .br-widget a.br-active:after {
  color: #ffc000;
  content: "\f005";
}

.br-theme-fontawesome-stars .br-widget a.br-selected:after {
  color: #ffc000;
  content: "\f005";
}

.br-theme-fontawesome-stars .br-widget .br-current-rating {
  display: none;
}

.br-theme-fontawesome-stars .br-readonly a {
  cursor: default;
}

@media print {
  .br-theme-fontawesome-stars .br-widget a:after {
    content: '\f006';
    color: black;
  }
  .br-theme-fontawesome-stars .br-widget a.br-active:after,
  .br-theme-fontawesome-stars .br-widget a.br-selected:after {
    content: '\f005';
    color: black;
  }
}

/* Badge Page */
.badges .author-badges {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.badges .author-badges .badge-single {
  text-align: center;
  width: 265px;
  padding: 0 15px;
  margin-bottom: 30px;
}

.badges .author-badges .badge-single img {
  display: inline-block;
  width: 160px;
  height: 160px;
}

.badges .author-badges .badge-single h3 {
  margin: 20px 0;
  font-size: 20px;
  font-weight: 500;
}

.badges .author-badges .badge-single p {
  margin-bottom: 0;
}

.badges.author-rank {
  padding: 120px 0 50px 0;
  border-bottom: 1px solid #ececec;
}

.badges.community-badges {
  padding: 100px 0 120px 0;
}

.badges.community-badges .author-badges {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.badges.community-badges .author-badges .badge-single {
  width: 20%;
}

.badge-single svg.locked {
  filter: grayscale(1);
  opacity: 0.15;
}

.badge-single .svg_wrapper {
  position: relative;
}

.badge-single .svg_wrapper span {
  position: absolute;
  font-size: 94px;
  color: #696a75;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/***************************************
        RESPONSIVE CSS GOES HERE
****************************************/
/*****************************
	RESPONSIVE
*****************************/
/*****************************
	-- THEME
*****************************/
/*this is responsive scss*/
/* 992 TO 1199 */
@media (min-width: 992px) and (max-width: 1199px) {
  /* menu area scss */
  .mainmenu__menu .navbar-nav > li {
    margin-right: 20px;
  }
  .has_megamenu .dropdown_megamenu {
    width: 930px;
  }
  .searc-wrap input {
    width: 300px;
  }
  .search_box .text_field {
    width: 44%;
  }
  .single_speaker .speaker__thumbnail {
    width: 150px;
  }
  .single_speaker .speaker__detail {
    width: calc(100% - 150px);
  }
  /* featured area scss */
  .featured-product-slider .featured__single-slider {
    height: 381px;
  }
  .featured-product-slider .featured__product-description {
    padding: 24px 30px 0 35px;
  }
  .featured-product-slider .featured__product-description .titlebtm {
    padding-bottom: 15px;
  }
  .featured-product-slider .desc--featured {
    max-height: 219px;
  }
  .featured-product-slider .product_data {
    padding: 0 31px;
  }
  .featured-product-slider .product-purchase.featured--product-purchase {
    padding: 16px 0;
    margin-top: 15px;
  }
  /* PRODUCT SORTING */
  .sorting {
    padding: 30px 30px 15px;
  }
  .sorting ul li {
    margin-bottom: 20px;
  }
  /* PRODUCT */
  .product.product--list {
    height: auto;
  }
  .product.product--list .product__details {
    padding: 15px 0;
  }
  .product.product--list .product__details .product-desc, .product.product--list .product__details .product-meta, .product.product--list .product__details .product-purchase {
    float: left;
  }
  .product.product--list .product__details .product-desc {
    width: 320px;
  }
  .product.product--list .product__details .product-purchase {
    width: auto;
  }
  .product.product--list .product__details .product-purchase .price_love {
    padding: 0;
    margin-right: 10px;
  }
  .product.product--list .product__details .product-purchase .sell {
    display: inline-block;
  }
  .product.product--list .product__details .product-meta .author {
    margin-bottom: 15px;
  }
  /* PROPOSAL AREA */
  .proposal {
    padding: 100px 13%;
  }
  .footer-area .social ul li + li, .footer-area .single_blog_content .share_tags .share .social_share ul li + li, .single_blog_content .share_tags .share .footer-area .social_share ul li + li {
    margin-left: 6px;
  }
  /* PRODUCTS */
  .product .product-purchase .price_love p {
    display: none;
  }
  /* FILTER BAR ARE CSS */
  .filter-bar .filter__option.filter--layout {
    padding: 10px 10px 7px;
  }
  .filter-bar .dropdown-trigger {
    padding: 0 15px;
  }
  .filter-bar .filter--select {
    width: 200px;
    margin: 0 10px;
  }
  .filter-bar .filter__option {
    padding: 20px 0;
  }
  /* PRODUCT AREA */
  .product .product-desc {
    padding: 25px;
  }
  .product .product-purchase {
    padding: 15px 25px;
  }
  .product .rating ul {
    margin-bottom: 5px;
  }
  .product .rating ul li span {
    font-size: 13px;
  }
  .product.product--list-small .product__thumbnail {
    width: 200px;
  }
  .product.product--list-small .product__details {
    margin-bottom: 10px;
  }
  .product.product--list-small .product__details .product-desc {
    width: 280px;
  }
  .product.product--list-small .product__details .product-desc .titlebtm {
    padding-top: 12px;
  }
  .product.product--list-small .product__details .product-meta {
    border-right: 0;
  }
  .product.product--list-small .product__details .product-meta .love-comments p {
    margin-bottom: 5px;
  }
  .product.product--list-small .product__details .product-meta .love-comments p + p {
    display: none;
  }
  .product.product--list-small .product__details .product-purchase {
    padding-left: 20px;
    padding-top: 10px;
  }
  /* SIDEBAR CARD*/
  .sidebar .sidebar-card .collapsible-content, .sidebar .item-preview .collapsible-content {
    padding: 0 20px;
  }
  .sidebar .card--slider .card-content .price-ranges .from {
    margin-right: 0;
  }
  /* SINGLE ITEM PREVIEW */
  .item-info .item-navigation ul li:last-child a {
    padding-right: 25px;
  }
  .item-info .item-navigation ul li a {
    padding: 0 18px;
  }
  /* USER AREA */
  .user_area .user_single .user__short_desc {
    max-width: 280px;
  }
  .user_area .user_single .user__short_desc, .user_area .user_single .user__meta, .user_area .user_single .user__status {
    padding: 0 22px;
  }
  .user_area .user_single .user__status .btn {
    min-width: 120px;
  }
  /* DASHBOARD AREA */
  .dashboard-area .dashboard_menu_area .dashboard_menu li {
    margin-right: 15px;
  }
  .dashboard-area .dashboard_menu_area .dashboard_menu li a {
    line-height: 35px;
    padding: 8px 0;
  }
  /* CREDIT MODULES */
  .credit_modules .modules__content .amounts ul li p, .shortcode_modules .modules__content .amounts ul li p, .upload_modules .modules__content .amounts ul li p, .withdraw_module .modules__content .amounts ul li p {
    min-width: 151px;
    font-size: 28px;
    padding: 14px 0;
  }
  .credit_modules .payment_method li, .shortcode_modules .payment_method li, .upload_modules .payment_method li, .withdraw_module .payment_method li {
    width: 17%;
  }
  .credit_modules .payment_method li .custom-radio label img, .shortcode_modules .payment_method li .custom-radio label img, .upload_modules .payment_method li .custom-radio label img, .withdraw_module .payment_method li .custom-radio label img, .credit_modules .payment_method li .custom-radio .form-group p.label img, .form-group .credit_modules .payment_method li .custom-radio p.label img, .shortcode_modules .payment_method li .custom-radio .form-group p.label img, .form-group .shortcode_modules .payment_method li .custom-radio p.label img, .upload_modules .payment_method li .custom-radio .form-group p.label img, .form-group .upload_modules .payment_method li .custom-radio p.label img, .withdraw_module .payment_method li .custom-radio .form-group p.label img, .form-group .withdraw_module .payment_method li .custom-radio p.label img {
    width: 100%;
    height: auto;
  }
  /* DASHBOARD STATEMENT */
  .statement_info_card .info_wrap {
    text-align: center;
  }
  .statement_info_card .info_wrap span {
    margin-right: 0;
  }
  .statement_info_card .info_wrap .info {
    display: block;
    margin-top: 10px;
  }
}

/* 991 */
@media (max-width: 991px) {
  .search_box form {
    justify-content: center;
  }
  .search_box .text_field {
    flex: auto;
  }
  .dashboard_menu_area .dashboard_menu {
    justify-content: center;
  }
  /* Main Menu ares css goes here */
  .mainmenu__menu .navbar-nav > li {
    margin-right: 8px;
  }
  .mainmenu__menu .navbar-nav > li a {
    font-size: 14px;
  }
  .mainmenu__search .searc-wrap input {
    width: 244px;
  }
  .dropdowns.notification--dropdown {
    right: -15px;
    left: auto;
  }
  .dropdowns.notification--dropdown:before {
    right: 30px;
    left: auto;
  }
  /* feature two before */
  .feature--2 {
    border-top: 1px solid #ececec;
  }
  .feature--2:before {
    display: none;
  }
  /* product area */
  .sorting {
    padding: 30px 30px 20px;
  }
  .sorting ul li {
    margin-bottom: 10px;
  }
  .sorting ul li a {
    font-size: 14px;
    padding: 0 15px;
  }
  /* PRODUCT */
  .product.product--list {
    height: auto;
  }
  /* JOB PAGE */
  .job_detail_module.information .job__content .informations li .info {
    display: block;
  }
  /* DASHBOARD AREA */
  .dashboard-area .dashboard_menu_area .dashboard_menu li {
    margin-right: 15px;
  }
  .dashboard-area .dashboard_menu_area .dashboard_menu li a {
    line-height: 35px;
    padding: 8px 0;
  }
  /* DASHBOARD STATEMENT PAGE */
  .statement_info_card {
    text-align: center;
  }
  /* DASHBOARD MANAGE ITEM */
  .dashboard-edit .filter-bar .filter--select {
    width: auto;
  }
  /* PRICING AREA */
  .pricing {
    margin-bottom: 40px;
  }
  /* EVENT PAGE CSS */
  .event_module .list_item {
    width: auto;
  }
  .single_speaker {
    text-align: center;
  }
  .single_speaker .speaker__thumbnail {
    height: auto;
    width: auto;
  }
  .single_speaker .speaker__detail {
    width: 100%;
    padding-left: 0;
    margin-top: 30px;
  }
  .event_detail_breadcrumb .social, .event_detail_breadcrumb .single_blog_content .share_tags .share .social_share, .single_blog_content .share_tags .share .event_detail_breadcrumb .social_share {
    width: 100%;
  }
  .go_top {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

/* 768 TO 992*/
@media (min-width: 768px) and (max-width: 991px) {
  /*--------------- General scss goes here -------------- */
  /* menu area */
  .has_megamenu .dropdown_megamenu {
    width: 690px;
  }
  .user_area .user_single .user__short_desc {
    max-width: 320px;
  }
  /* search area */
  .search-area .search_box {
    text-align: center;
    padding: 30px;
  }
  .search-area .search_box .search-btn {
    margin-top: 30px;
  }
  .search-area .text_field, .search-area .search__select {
    width: 50%;
  }
  .section-title p {
    padding: 0 125px;
  }
  /* hero area */
  .hero-content .hero__content__title h1 {
    font-size: 50px;
  }
  .hero-content .hero__content__title .tagline {
    line-height: 35px;
  }
  .logo img {
    width: 100%;
  }
  /* featured area */
  .featured-product-slider .featured__single-slider {
    height: auto;
  }
  .featured-product-slider .featured__single-slider .featured__product-description, .featured-product-slider .featured__single-slider .featured__preview-img {
    display: inline-block;
    vertical-align: middle;
    margin-right: -3px;
    float: none;
  }
  .featured-product-slider .featured__single-slider .featured__product-description {
    padding: 30px 30px 0 30px;
  }
  .featured-product-slider .featured__single-slider .featured__product-description .titlebtm + p {
    display: none;
  }
  .featured-product-slider .featured__single-slider .product_data {
    position: initial;
    padding: 0;
  }
  .featured-product-slider .featured__single-slider .product_data .tags ul li {
    margin-bottom: 5px;
  }
  /* testimonial scss */
  .testimonial {
    padding: 25px 25px;
  }
  /* news area scss */
  .news {
    margin-bottom: 30px;
  }
  .news .news__thumbnail img {
    width: 100%;
  }
  /* PRODUCT */
  .product.product--list .product__thumbnail {
    width: 220px;
  }
  .product.product--list .product__details {
    padding: 15px 0;
  }
  .product.product--list .product__details .product-meta, .product.product--list .product__details .product-purchase, .product.product--list .product__details .product-desc {
    float: left;
  }
  .product.product--list .product__details .product-purchase {
    width: auto;
  }
  .product.product--list .product__details .product-purchase .price_love {
    display: inline-block;
    padding-bottom: 0;
  }
  .product.product--list .product__details .product-purchase .sell {
    display: inline-block;
  }
  .product.product--list .product__details .product-desc, .product.product--list .product__details .product-meta {
    width: 50%;
  }
  .product.product--list .product__details .product-meta {
    border-right: 0;
  }
  .product.product--list .product__details .product-meta .author {
    margin-bottom: 15px;
  }
  .product.product--list .product__details .product-meta .love-comments p + p {
    margin-left: 0;
  }
  .product .prod_btn a {
    padding: 0 25px;
  }
  .product .prod_btn a:first-child {
    margin-bottom: 10px;
  }
  .product.product--list-small .product-purchase {
    padding-left: 20px;
    margin-top: 15px;
  }
  /* FOOTER AREA */
  .footer-area .footer-menu {
    padding-left: 0;
    margin-bottom: 40px;
  }
  .footer-area .newsletter {
    padding: 0 60px;
    text-align: center;
  }
  .footer-area .newsletter__form .field-wrapper {
    margin-bottom: 25px;
  }
  .footer-area .social, .footer-area .single_blog_content .share_tags .share .social_share, .single_blog_content .share_tags .share .footer-area .social_share {
    display: block;
    text-align: center;
  }
  /* SEARCH AREA */
  .search .search__field {
    padding: 0 50px;
  }
  /* FILTERBAR AREA */
  .filter-bar.filter--bar2 .filter--text {
    padding: 21px 0;
  }
  .filter-bar.filter--bar2 .filter--text a + a {
    margin-left: 15px;
  }
  .filter-bar.filter--bar2 .filter--select {
    width: 37%;
  }
  .filter-bar.filter--bar2 .filter--select + .filter--select {
    margin-left: 16px;
  }
  .filter-bar.filter--bar2 .filter--layout {
    padding: 10px 12px 7px;
  }
  .filter-bar .filter--dropdown {
    width: 32%;
    padding: 18px 0;
    border-bottom: 1px solid #ececec;
  }
  .filter-bar .filter--dropdown .dropdowns {
    top: 100%;
  }
  .filter-bar .filter--dropdown.filter--range .dropdowns {
    width: auto;
  }
  .filter-bar .filter--select {
    width: 40%;
    margin: 0;
    padding: 20px 0;
  }
  .filter-bar .filter--select + .filter--select {
    margin-left: 20px;
  }
  .filter-bar.filter-bar3 .filter--dropdown {
    width: 25%;
  }
  .filter-bar.filter-bar3 .filter--text {
    padding: 19px 0;
  }
  .filter-bar.filter-bar3 .dropdown-trigger {
    padding: 0 22px;
  }
  .filter-bar.filter-bar3 .filter--select {
    width: 34%;
  }
  .filter-bar.filter-bar3 .filter--layout {
    display: none;
  }
  /* SINGLE PREVIEW */
  .tab {
    margin-bottom: 40px;
  }
  .tab .item-navigation a {
    padding: 0 24px;
  }
  .tab3 ul.nav-tabs li a {
    padding: 0 25px;
  }
  .tab4 .item-navigation {
    width: 30%;
  }
  .tab4 .tab-content {
    width: 70%;
  }
  /* SINGLE PRODUCT SIDEBAR */
  .sidebar--single-product .sidebar-card.card-pricing .purchase-button, .sidebar--single-product .card-pricing.item-preview .purchase-button {
    text-align: center;
  }
  .sidebar--single-product .sidebar-card.card-pricing .purchase-button .btn, .sidebar--single-product .card-pricing.item-preview .purchase-button .btn {
    width: auto;
  }
  .sidebar--single-product .sidebar-card.card-pricing .purchase-button .btn + .btn, .sidebar--single-product .card-pricing.item-preview .purchase-button .btn + .btn {
    margin-top: 0;
    margin-left: 15px;
  }
  /*  */
  .product_archive .single_product .product__description img {
    width: 110px;
  }
  .product_archive .single_product .product__description .short_desc {
    width: calc(100% - 115px);
  }
  .product_archive .single_product .product__additional_info {
    padding-left: 0;
  }
  .product_archive .title_area {
    display: none;
  }
  /* AUTHOE PROFILE AREA */
  .author-profile-area .filter-bar2 .filter--dropdown {
    width: auto;
  }
  /* MESSAGING PAGE */
  .messaging_sidebar .messaging__header, .recent_message .messaging__header {
    padding: 20px;
  }
  .messaging_sidebar .messaging__header .messaging_action .lnr.lnr-sync, .recent_message .messaging__header .messaging_action .lnr.lnr-sync {
    display: none;
  }
  .messaging_sidebar .messaging__header .messaging_action a, .recent_message .messaging__header .messaging_action a {
    padding: 0 15px;
  }
  .messaging_sidebar .messaging__header .messaging_action .text, .recent_message .messaging__header .messaging_action .text {
    display: none;
  }
  .messaging_sidebar .messaging__contents .messages .message, .recent_message .messaging__contents .messages .message, .recent_message .dashboard__content .messages .message {
    padding: 20px;
  }
  .messaging_sidebar .messaging__contents .messages .message .message__actions_avatar .actions, .recent_message .messaging__contents .messages .message .message__actions_avatar .actions, .recent_message .dashboard__content .messages .message .message__actions_avatar .actions, .messaging_sidebar .messaging__contents .messages .message .message__actions_avatar .avatar, .recent_message .messaging__contents .messages .message .message__actions_avatar .avatar, .recent_message .dashboard__content .messages .message .message__actions_avatar .avatar {
    display: inline-block;
  }
  .messaging_sidebar .messaging__contents .messages .message .message__actions_avatar .actions, .recent_message .messaging__contents .messages .message .message__actions_avatar .actions, .recent_message .dashboard__content .messages .message .message__actions_avatar .actions {
    margin-right: 0;
  }
  .messaging_sidebar .messaging__contents .messages .message .message__actions_avatar .avatar, .recent_message .messaging__contents .messages .message .message__actions_avatar .avatar, .recent_message .dashboard__content .messages .message .message__actions_avatar .avatar {
    display: none;
  }
  .messaging_sidebar .messaging__contents .messages .message .message_data, .recent_message .messaging__contents .messages .message .message_data, .recent_message .dashboard__content .messages .message .message_data {
    width: calc(100% - 30px);
    display: inline-block;
  }
  .chat_area .chat_area--conversation .body, .recent_message .dashboard__content .chat_area--conversation .body {
    padding-left: 40px;
  }
  /* DASHBOARD AREA */
  .dashboard-area .author-info {
    margin-bottom: 40px;
  }
  /* DASHBOARD PURCHASE */
  .dashboard_purchase .filter-bar2 .dashboard__title {
    display: none;
  }
  .dashboard_purchase .filter-bar2 > .pull-right {
    float: none !important;
  }
  .dashboard_purchase .filter-bar2 .filter__option {
    width: 30%;
  }
  .dashboard_purchase .product_archive .single_product .product__description img {
    display: none;
  }
  .dashboard_purchase .product_archive .single_product .product__description .short_desc {
    width: 100%;
    padding-left: 0;
  }
  .dashboard_purchase .product_archive .single_product .product__price_download .item_action .btn {
    min-width: 140px;
    width: 140px;
    padding: 0;
  }
  /* DASHBOARD ADD CREDIT */
  .credit_modules .modules__content .amounts ul li + li, .shortcode_modules .modules__content .amounts ul li + li, .upload_modules .modules__content .amounts ul li + li, .withdraw_module .modules__content .amounts ul li + li {
    margin-left: 10px;
  }
  .credit_modules .modules__content .amounts ul li p, .shortcode_modules .modules__content .amounts ul li p, .upload_modules .modules__content .amounts ul li p, .withdraw_module .modules__content .amounts ul li p {
    min-width: 120px;
    font-size: 25px;
    padding: 12px 0;
  }
  .credit_modules .modules__content .payment_method li, .shortcode_modules .modules__content .payment_method li, .upload_modules .modules__content .payment_method li, .withdraw_module .modules__content .payment_method li {
    width: 17%;
  }
  .credit_modules .modules__content .payment_method li .custom-radio label img, .shortcode_modules .modules__content .payment_method li .custom-radio label img, .upload_modules .modules__content .payment_method li .custom-radio label img, .withdraw_module .modules__content .payment_method li .custom-radio label img, .credit_modules .modules__content .payment_method li .custom-radio .form-group p.label img, .form-group .credit_modules .modules__content .payment_method li .custom-radio p.label img, .shortcode_modules .modules__content .payment_method li .custom-radio .form-group p.label img, .form-group .shortcode_modules .modules__content .payment_method li .custom-radio p.label img, .upload_modules .modules__content .payment_method li .custom-radio .form-group p.label img, .form-group .upload_modules .modules__content .payment_method li .custom-radio p.label img, .withdraw_module .modules__content .payment_method li .custom-radio .form-group p.label img, .form-group .withdraw_module .modules__content .payment_method li .custom-radio p.label img {
    width: 100%;
    height: auto;
  }
  .credit_modules .modules__content .payment_method li .custom-radio label .circle, .shortcode_modules .modules__content .payment_method li .custom-radio label .circle, .upload_modules .modules__content .payment_method li .custom-radio label .circle, .withdraw_module .modules__content .payment_method li .custom-radio label .circle, .credit_modules .modules__content .payment_method li .custom-radio .form-group p.label .circle, .form-group .credit_modules .modules__content .payment_method li .custom-radio p.label .circle, .shortcode_modules .modules__content .payment_method li .custom-radio .form-group p.label .circle, .form-group .shortcode_modules .modules__content .payment_method li .custom-radio p.label .circle, .upload_modules .modules__content .payment_method li .custom-radio .form-group p.label .circle, .form-group .upload_modules .modules__content .payment_method li .custom-radio p.label .circle, .withdraw_module .modules__content .payment_method li .custom-radio .form-group p.label .circle, .form-group .withdraw_module .modules__content .payment_method li .custom-radio p.label .circle {
    margin-top: 10px;
    height: 25px;
    width: 25px;
  }
  .credit_modules .modules__content .payment_method li .custom-radio label .circle:before, .shortcode_modules .modules__content .payment_method li .custom-radio label .circle:before, .upload_modules .modules__content .payment_method li .custom-radio label .circle:before, .withdraw_module .modules__content .payment_method li .custom-radio label .circle:before, .credit_modules .modules__content .payment_method li .custom-radio .form-group p.label .circle:before, .form-group .credit_modules .modules__content .payment_method li .custom-radio p.label .circle:before, .shortcode_modules .modules__content .payment_method li .custom-radio .form-group p.label .circle:before, .form-group .shortcode_modules .modules__content .payment_method li .custom-radio p.label .circle:before, .upload_modules .modules__content .payment_method li .custom-radio .form-group p.label .circle:before, .form-group .upload_modules .modules__content .payment_method li .custom-radio p.label .circle:before, .withdraw_module .modules__content .payment_method li .custom-radio .form-group p.label .circle:before, .form-group .withdraw_module .modules__content .payment_method li .custom-radio p.label .circle:before {
    width: 25px;
    height: 25px;
    line-height: 25px;
  }
  /* DASHBOARD STATEMENT PAGE */
  .dashboard_statement_area .dashboard_title_area .dashboard__title h3 {
    display: none;
  }
  .statement_info_card {
    padding: 0 18px;
  }
  .statement_info_card .info_wrap span.icon {
    margin-right: 0;
    line-height: 50px;
    width: 50px;
  }
  .statement_info_card .info_wrap .info {
    margin-top: 10px;
  }
  .statement_info_card .info_wrap .info span {
    font-size: 16px;
  }
  .statement_info_card .info_wrap .info p {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 10px;
  }
  /* EVENT PAGE */
  .featured_event .v_middle, .featured_event .user_area .user_single .user__short_desc .user_avatar, .user_area .user_single .user__short_desc .featured_event .user_avatar, .featured_event .user_area .user_single .user__short_desc .user_info, .user_area .user_single .user__short_desc .featured_event .user_info, .featured_event .statement_info_card .icon, .statement_info_card .featured_event .icon, .featured_event .statement_info_card .info, .statement_info_card .featured_event .info {
    display: block;
  }
  .featured_event .event_img {
    padding: 0;
  }
  .featured_event .event_img img {
    width: 100%;
    max-width: initial;
  }
  .featured_event .featured_event_detail {
    padding: 36px 40px;
  }
  .featured_event .featured_event_detail h1 {
    font-size: 30px;
  }
  .google_map .location_address {
    left: calc((100vw - 750px) / 2);
  }
  .badges.community-badges .author-badges .badge-single {
    width: 33.33%;
  }
}

/* 767 */
@media screen and (max-width: 767px) {
  .dashboard-area.dashboard_purchase .dashboard_menu,
  .dashboard_menu_area .dashboard_menu {
    justify-content: flex-start;
  }
  .dashboard-area.dashboard_purchase .dashboard_menu li,
  .dashboard_menu_area .dashboard_menu li {
    margin-right: 15px;
  }
  .dashboard-area.dashboard_purchase .dashboard_menu li + li,
  .dashboard_menu_area .dashboard_menu li + li {
    margin-left: 0;
  }
  .upload_wrapper .progress_wrapper {
    padding: 15px 35px 0 0;
  }
  .how_it_works_module .area_image {
    margin: 30px 0;
  }
  /* typography */
  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 26px;
  }
  h3 {
    font-size: 22px;
  }
  /*-------------- general scss ----------------*/
  .search_box .search-btn {
    margin-left: 0;
  }
  .contact_form--wrapper form {
    padding: 0 30px;
  }
  .mobile_content .menu_icon {
    display: block;
  }
  .product-title-area, .shortcode_module_title, .dashboard_contents .dashboard_title_area {
    padding: 24px 25px 26px;
  }
  .product-title-area .product__title, .shortcode_module_title .product__title, .dashboard_contents .dashboard_title_area .product__title {
    float: none;
    width: calc(100% - 95px);
    display: inline-block;
    vertical-align: middle;
  }
  .product-title-area .product__title h2, .shortcode_module_title .product__title h2, .dashboard_contents .dashboard_title_area .product__title h2 {
    line-height: 34px;
    font-size: 24px;
  }
  .product-title-area .product__slider-nav, .shortcode_module_title .product__slider-nav, .dashboard_contents .dashboard_title_area .product__slider-nav {
    float: none;
    display: inline-block;
    vertical-align: middle;
  }
  .product-title-area .product__slider-nav span, .shortcode_module_title .product__slider-nav span, .dashboard_contents .dashboard_title_area .product__slider-nav span {
    line-height: 38px;
    width: 42px;
  }
  .product-title-area .filter__menu, .shortcode_module_title .filter__menu, .dashboard_contents .dashboard_title_area .filter__menu {
    float: none;
    display: inline-block;
    margin-right: -3px;
  }
  .product-title-area .filter__menu p, .shortcode_module_title .filter__menu p, .dashboard_contents .dashboard_title_area .filter__menu p {
    margin-right: 10px;
  }
  .tab_half_width {
    width: 50%;
    float: left;
  }
  .section--padding {
    padding: 100px 0;
  }
  .section-title p {
    padding: 0;
  }
  .logo {
    padding: 10px 14px 10px 0;
  }
  .logo img {
    width: 80%;
  }
  /* MOBILE MENU AREA */
  .mainmenu .navbar {
    display: block;
  }
  .mainmenu .navbar .navbar-toggler {
    padding: 4px;
    margin: 20px 0;
  }
  .has_megamenu .dropdown_megamenu {
    width: 100%;
  }
  .navbar-header .navbar-toggle {
    z-index: 5;
    float: left;
  }
  .navbar-header .navbar-toggle span {
    font-size: 22px;
    width: 40px;
    line-height: 40px;
    background: #fff;
    display: inline-block;
    border-radius: 5px;
    color: #000;
    border: 1px solid #ececec;
  }
  .navbar-header .mainmenu__search {
    position: relative;
    z-index: 1;
    padding: 18px 0;
  }
  .navbar-header .mainmenu__search .searc-wrap input {
    width: 210px;
    line-height: 40px;
  }
  .mainmenu__menu {
    min-height: 0;
  }
  .mainmenu__menu .navbar-collapse {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 5;
    height: 280px;
    background: #fff;
    padding: 0 15px;
    box-shadow: 0 2px 20px rgba(108, 111, 115, 0.1);
    overflow: scroll;
  }
  .mainmenu__menu .navbar-collapse .navbar-nav {
    margin: 0;
  }
  .mainmenu__menu .navbar-nav > li {
    display: block;
    padding: 0;
    margin-right: 0;
  }
  .mainmenu__menu .navbar-nav > li a {
    line-height: 40px;
  }
  .mainmenu__menu .has_dropdown > a, .mainmenu__menu .has_megamenu > a {
    display: flex;
    align-items: center;
  }
  .mainmenu__menu .has_dropdown .dropdowns, .mainmenu__menu .has_dropdown .dropdown_megamenu, .mainmenu__menu .has_megamenu .dropdowns, .mainmenu__menu .has_megamenu .dropdown_megamenu {
    position: initial;
    display: none;
    box-shadow: 0 0 0;
    opacity: 1;
    padding: 5px;
    visibility: visible;
    transition: 0s;
  }
  .mainmenu__menu .has_dropdown .dropdowns ul li a, .mainmenu__menu .has_dropdown .dropdown_megamenu ul li a, .mainmenu__menu .has_megamenu .dropdowns ul li a, .mainmenu__menu .has_megamenu .dropdown_megamenu ul li a {
    line-height: 35px;
  }
  .mainmenu__menu .has_dropdown a span, .mainmenu__menu .has_megamenu a span {
    text-align: right;
    display: block;
    cursor: pointer;
    line-height: inherit;
    flex: 1;
  }
  .mainmenu__menu .has_megamenu .dropdown_megamenu {
    display: none;
  }
  .mainmenu__menu .megamnu_module {
    float: none;
  }
  .menu-area.menu--style1 .mainmenu__menu ul li a {
    color: #333;
  }
  /*--------------- components sccs ---------------*/
  /*btn*/
  .btn--lg {
    min-width: 165px;
    line-height: 50px;
  }
  /* hero area */
  .hero-area {
    height: 660px;
  }
  .hero-area .hero-content .content-wrapper {
    vertical-align: top;
    padding-top: 90px;
  }
  .hero-area .hero__content__title .tagline {
    line-height: 30px;
  }
  .hero-area .hero__content__title h1 {
    font-size: 40px;
  }
  .hero-area .search_box {
    padding: 30px;
    text-align: center;
  }
  .hero-area .search_box .text_field, .hero-area .search_box .search__select {
    width: 100%;
    border-left: 1px solid #ececec;
    margin-bottom: 20px;
  }
  /* CUSTOM RADIO */
  .custom-radio.custom_radio--big span.circle {
    height: 25px;
    width: 25px;
    margin-top: 15px;
  }
  .custom-radio.custom_radio--big span.circle:before {
    line-height: 25px;
    width: 25px;
    height: 25px;
  }
  /* PAGINATION AREA */
  .pagination-area.categorised_item_pagination {
    text-align: center;
  }
  /* menu area */
  .author-area {
    display: none;
  }
  /* featured products area css*/
  .featured-products .featured__product-description, .featured-products .featured__preview-img {
    width: 100%;
    float: none;
  }
  .featured-products .featured__product-description {
    padding: 31px 36px 0 34px;
  }
  .featured-products .featured__single-slider {
    height: initial;
  }
  .featured-products .desc--featured {
    height: 100%;
  }
  .featured-products .product_data {
    position: initial;
    padding: 20px 0 0;
  }
  /* */
  .sorting {
    padding: 25px 25px  15px;
  }
  /* PRODUCT */
  .product.product--list .product__thumbnail {
    width: 100%;
  }
  .product.product--list .product__details {
    overflow: initial;
    padding: 0;
  }
  .product.product--list .product__details .product-desc, .product.product--list .product__details .product-meta, .product.product--list .product__details .product-purchase {
    width: 100%;
    border: 0;
  }
  .product.product--list .product__details .product-desc {
    margin-bottom: 25px;
    padding-top: 20px;
  }
  .product.product--list .product__details .product-desc .titlebtm {
    padding-top: 16px;
  }
  .product.product--list .product__details .product-meta {
    padding-bottom: 20px;
  }
  .product.product--list .product__details .product-meta .author {
    margin-bottom: 10px;
    display: inline-block;
  }
  .product.product--list .product__details .product-meta .love-comments {
    display: inline-block;
    margin-left: 15px;
  }
  .product.product--list .product__details .product-meta .product-tags {
    margin-bottom: 10px;
  }
  .product.product--list .product__details .product-meta .love-comments p {
    margin-bottom: 0;
  }
  .product.product--list .product__details .product-meta .love-comments p + p {
    margin-left: 10px;
  }
  .product.product--list .product__details .product-purchase {
    border-top: 1px solid #ececec;
    margin-bottom: 10px;
  }
  .product.product--list .product__details .product-purchase .price_love {
    padding-bottom: 0;
  }
  .product.product--list .product__details .product-purchase .sell {
    display: inline-block;
  }
  .product.product--list-small .product__details .product-meta {
    padding-bottom: 5px;
  }
  .product.product--list-small .product__details .product-meta .rating {
    margin-left: 15px;
    padding-bottom: 5px;
  }
  .product.product--list-small .product__details .product-meta .love-comments p + p {
    display: none;
  }
  .product.product--list-small .product__details .product-purchase {
    padding: 20px;
    margin-bottom: 0;
  }
  /* TABLE */
  .table-responsive {
    border: 0;
  }
  /* counter up area */
  .counter-up .counter {
    width: 50%;
  }
  .counter-up .counter:nth-child(1), .counter-up .counter:nth-child(2) {
    margin-bottom: 40px;
  }
  /* news area */
  .news {
    margin-bottom: 25px;
  }
  .news .news__thumbnail img {
    width: 100%;
  }
  /* proposal area */
  .proposal {
    text-align: center;
    padding: 66px 13%;
  }
  /* call to action */
  .call-to-action {
    padding: 70px 0;
  }
  /* PROMOTION AREA */
  .promotion-area {
    padding: 100px 0;
  }
  /* PARTNER AREA */
  .partners {
    text-align: center;
  }
  /* FOOTER AREA */
  .footer-area .footer-menu {
    padding-left: 0;
    margin-bottom: 40px;
  }
  .footer-area .field-wrapper {
    margin: 30px 0;
  }
  /* SEARCH AREA */
  .search .search__field {
    padding: 0;
  }
  .search .search__field .field-wrapper .btn {
    padding: 0 15px;
  }
  /* FILTERBAR */
  .filter-bar {
    padding-bottom: 20px;
  }
  .filter-bar .filter--dropdown {
    width: 100%;
    border-right: 0;
    border-bottom: 1px solid #ececec;
  }
  .filter-bar .filter--dropdown.filter--range .dropdowns {
    width: auto;
  }
  .filter-bar .filter--dropdown .dropdowns {
    top: 100%;
  }
  .filter-bar .filter--dropdown:first-child {
    border-left: 0;
  }
  .filter-bar .filter--dropdown .dropdown-trigger {
    display: block;
    padding: 0;
  }
  .filter-bar .filter--dropdown .dropdown-trigger span {
    float: right;
    display: inline-block;
    line-height: inherit;
  }
  .filter-bar .filter__option {
    padding: 8px 0;
  }
  .filter-bar .filter--select {
    margin: 0;
    width: 100%;
  }
  .filter-bar .filter--layout {
    display: none;
  }
  .filter-bar.filter--bar2 .filter--text {
    float: none;
    width: 100%;
    text-align: center;
  }
  .filter-bar.filter--bar2 .pull-right {
    float: none !important;
  }
  /* ITEM PREVIEW*/
  .tab1, .tab3, .tab4 {
    margin-bottom: 40px;
  }
  .tab1 .item-navigation ul li, .tab3 .item-navigation ul li, .tab4 .item-navigation ul li {
    display: block;
    margin: 0;
    float: none;
    width: 100%;
  }
  .tab1 .item-navigation ul li a, .tab3 .item-navigation ul li a, .tab4 .item-navigation ul li a {
    line-height: 55px;
  }
  .tab1 .item-navigation ul li.active, .tab3 .item-navigation ul li.active, .tab4 .item-navigation ul li.active {
    background: rgba(6, 116, 236, 0.1);
  }
  .tab4 .item-navigation {
    width: 100%;
  }
  ul.nav-tabs.nav--tabs2 {
    display: block;
  }
  /* THREAD */
  .thread.thread_review2 .thread-list .single-thread .media-body .media-heading .rev_item {
    margin-left: 0;
  }
  .thread.thread_review2 .rev_time {
    display: none;
  }
  .thread .thread-list .single-thread .depth-2 {
    margin-left: 50px;
  }
  .author-reviews .author-profile-area .product-title-area .product__title, .author-reviews .author-profile-area .shortcode_module_title .product__title, .author-reviews .author-profile-area .dashboard_contents .dashboard_title_area .product__title, .dashboard_contents .author-reviews .author-profile-area .dashboard_title_area .product__title {
    width: auto;
  }
  /* CART PAGE */
  .product_archive .product__additional_info {
    padding-left: 0;
  }
  .product_archive .title_area {
    display: none;
  }
  /* AUTHOR PAGE */
  .author-info {
    margin-bottom: 40px;
  }
  .author-profile-area .product-title-area .product__title, .author-profile-area .shortcode_module_title .product__title, .author-profile-area .dashboard_contents .dashboard_title_area .product__title, .dashboard_contents .author-profile-area .dashboard_title_area .product__title {
    width: calc(100% - 138px);
  }
  .author-profile-area .filter-bar2 {
    padding-bottom: 0;
  }
  .author-profile-area .filter-bar2 .pull-right {
    display: none;
  }
  .author-profile-area .filter-bar2 .pull-right .filter__option {
    display: none;
  }
  /* USER AREA */
  .user_area .user_single .user__short_desc {
    display: block;
    max-width: 100%;
    border: none;
  }
  .user_area .user_single .user__meta, .user_area .user_single .user__status {
    width: 50%;
    max-width: 100%;
    margin-top: 20px;
  }
  /* NOTIFICATION MODULE */
  .notifications_module .notification {
    padding: 16px 30px;
  }
  .notifications_module .notification .notification__info {
    width: calc(100% - 65px);
  }
  .notifications_module .notification .notification__info .info_avatar {
    display: none;
  }
  .notifications_module .notification .notification__info .info .time {
    margin-top: 0;
  }
  .notifications_module .notification .notification__icons .noti_icon {
    line-height: 35px;
    width: 35px;
    margin-right: 6px;
    font-size: 13px;
  }
  /* CARRIER */
  .know_us .know_us_content {
    margin-bottom: 40px;
  }
  .know_us .know_us_content P {
    margin-bottom: 20px;
  }
  /* JOB PAGE */
  .job_detail_module.information .job__content .informations li {
    width: 50%;
    margin-bottom: 30px;
  }
  .job_detail_module.information .job__content .informations li .info {
    display: block;
  }
  /* DASHBOARD AREA */
  .dashboard-area .dashboard__title .pull-right {
    float: none !important;
  }
  .dashboard-area .dashboard_menu_area {
    padding: 10px 0;
  }
  .dashboard-area .dashboard_menu_area .dashboard_menu li a {
    line-height: 20px;
  }
  .dashboard__title h4 {
    font-size: 30px;
  }
  .statistics_data .single_stat_data .single_stat__title {
    font-size: 24px;
  }
  .statistics_data .single_stat_data p {
    font-size: 14px;
    line-height: 15px;
  }
  .single_item_visitor .dashboard__content .item_info .select-wrap, .single_item_visitor .dashboard__content .item_info .info {
    float: none;
    text-align: left;
  }
  .single_item_visitor .dashboard__content .item_info .info {
    margin-top: 20px;
  }
  .single_item_visitor .dashboard__title #visit_legend {
    float: none;
  }
  .recent_sells .dashboard__content ul li .single_sell .single_sell__thumb-title {
    width: calc(100% - 60px);
  }
  .recent_sells .dashboard__content ul li .single_sell .single_sell__thumb-title .prod_thumbnail {
    max-width: 75px;
  }
  .recent_sells .dashboard__content ul li .single_sell .single_sell__thumb-title .prod_title {
    width: calc(100% - 78px);
    padding-left: 15px;
  }
  .chart_top .v_refer .nav-tabs li a {
    padding: 10px;
    font-size: 20px;
  }
  /* PRODUCT PURCHASE */
  .dashboard_purchase .product_archive .single_product .product__description img {
    width: 120px;
  }
  .dashboard_purchase .product_archive .single_product .product__description .short_desc {
    width: calc(100% - 125px);
  }
  /* DASHBOARD PURCHASE */
  .dashboard_purchase .filter-bar2 {
    padding: 25px;
  }
  .dashboard_purchase .filter-bar2 .dashboard__title {
    display: none;
  }
  .dashboard_purchase .filter-bar2 .filter__option {
    padding: 10px 0;
  }
  .dashboard_purchase .filter-bar2 .filter__option p {
    line-height: 35px;
  }
  .dashboard_purchase .product_archive .single_product .product__additional_info {
    margin-top: 30px;
  }
  .dashboard_purchase .product_archive .single_product .product__price_download {
    margin-top: 30px;
  }
  .dashboard_purchase .product_archive .single_product .product__price_download .item_price {
    display: none;
  }
  .dashboard_purchase .product_archive .single_product .product__price_download .item_action .btn {
    min-width: 155px;
  }
  /* ADD CREDIT PAGE */
  .credit_modules .modules__content .amounts ul li + li, .shortcode_modules .modules__content .amounts ul li + li, .upload_modules .modules__content .amounts ul li + li, .withdraw_module .modules__content .amounts ul li + li {
    margin-left: 10px;
  }
  .credit_modules .modules__content .amounts ul li p, .shortcode_modules .modules__content .amounts ul li p, .upload_modules .modules__content .amounts ul li p, .withdraw_module .modules__content .amounts ul li p {
    min-width: 110px;
    padding: 12px 0;
    font-size: 25px;
  }
  .credit_modules .payment_method li, .shortcode_modules .payment_method li, .upload_modules .payment_method li, .withdraw_module .payment_method li {
    width: 28%;
    margin-right: 10px;
    margin-bottom: 20px;
  }
  .credit_modules .payment_method li .custom-radio label img, .shortcode_modules .payment_method li .custom-radio label img, .upload_modules .payment_method li .custom-radio label img, .withdraw_module .payment_method li .custom-radio label img, .credit_modules .payment_method li .custom-radio .form-group p.label img, .form-group .credit_modules .payment_method li .custom-radio p.label img, .shortcode_modules .payment_method li .custom-radio .form-group p.label img, .form-group .shortcode_modules .payment_method li .custom-radio p.label img, .upload_modules .payment_method li .custom-radio .form-group p.label img, .form-group .upload_modules .payment_method li .custom-radio p.label img, .withdraw_module .payment_method li .custom-radio .form-group p.label img, .form-group .withdraw_module .payment_method li .custom-radio p.label img {
    width: 100%;
    height: auto;
  }
  /* STATEMENT PAGE AREA */
  .dashboard_statement_area .dashboard_title_area h3 {
    margin-bottom: 20px;
  }
  .dashboard_statement_area .dashboard_title_area .date_area {
    text-align: center;
  }
  .dashboard_statement_area .dashboard_title_area .date_area .input_with_icon, .dashboard_statement_area .dashboard_title_area .date_area .select-wrap {
    max-width: initial;
    width: 100%;
    margin-bottom: 20px;
  }
  .statement_info_card {
    margin-bottom: 30px;
  }
  .statement_info_card .info_wrap .info {
    text-align: left;
  }
  /* UPLOAD PAGE START */
  .upload_modules .modules__content .upload_wrapper, .withdraw_module .modules__content .upload_wrapper {
    border-bottom: 1px solid #ececec;
    padding-bottom: 20px;
  }
  .upload_modules .modules__content .upload_wrapper .custom_upload, .withdraw_module .modules__content .upload_wrapper .custom_upload {
    display: block;
  }
  .upload_modules .modules__content .upload_progress, .withdraw_module .modules__content .upload_progress {
    width: calc(100% - 36px);
    padding-left: 0;
  }
  aside.upload_sidebar {
    margin-top: 40px;
  }
  /* DASHBOARD MANAGE ITEM */
  .dashboard-edit .filter-bar.dashboard__title h3 {
    display: none;
  }
  /* SUPPORT PAGE */
  .support_threads .support_thread--single .support_thread_info {
    width: 100%;
  }
  .support_threads .support_thread--single .support_thread_info .support_tag {
    margin-left: 0;
    margin-top: 10px;
  }
  .support_threads .support_thread--single .support_thread_meta {
    width: 100%;
    margin-top: 20px;
  }
  .support_threads .support_thread--single .support_thread_meta ul li {
    padding: 5px 7px 7px;
  }
  .support_threads .support_thread--single .support_thread_meta ul li.answer_time {
    text-align: left;
  }
  .support_threads .thread_sort_area {
    padding: 22px 25px;
  }
  .support_threads .thread_sort_area .sort_options {
    float: none;
  }
  .support_threads .thread_sort_area .sort_options ul li {
    margin-bottom: 10px;
  }
  .support_threads .thread_sort_area .thread_search_area {
    float: none;
  }
  .support_threads .thread_sort_area .thread_search_area .searc-wrap input {
    width: 100%;
  }
  aside.support--sidebar, aside.faq--sidebar, aside.affliate_rule_module, aside.sidebar--blog {
    margin-top: 50px;
  }
  aside.support--sidebar .login_promot, aside.faq--sidebar .login_promot, aside.affliate_rule_module .login_promot, aside.sidebar--blog .login_promot {
    padding: 25px 20px;
    font-size: 17px;
  }
  /* FORUM DETAIL AREA */
  .forum_detail_area .forum--issue .title_vote h3 {
    font-size: 22px;
  }
  .forum_detail_area .forum--replays .area_title {
    padding: 20px;
  }
  .forum_detail_area .forum--replays .forum_single_reply {
    padding: 25px;
  }
  .forum_detail_area .forum--replays .forum_single_reply .reply_content {
    width: calc(100% - 40px);
  }
  .forum_detail_area .forum--replays .forum_single_reply .reply_avatar {
    width: 35px;
  }
  .forum_detail_area .forum--replays .forum_single_reply .name_vote .pull-left {
    float: none !important;
  }
  .forum_detail_area .forum--replays .forum_single_reply .name_vote .vote {
    padding: 8px 10px;
    float: none;
    display: inline-block;
  }
  /* LOGIN AREA */
  .login, .signup_form, .recover_pass {
    padding: 40px 0;
  }
  .login .login--form, .signup_form .login--form, .recover_pass .login--form {
    padding: 25px 35px 0;
  }
  .login .login--header, .signup_form .login--header, .recover_pass .login--header {
    padding: 0 35px 25px;
  }
  .login .login_assist .recover, .signup_form .login_assist .recover, .recover_pass .login_assist .recover, .login .login_assist .signup, .signup_form .login_assist .signup, .recover_pass .login_assist .signup {
    float: none;
    text-align: left;
  }
  /* BLOG DEFAULT */
  .blog--default .blog__title h4 {
    font-size: 22px;
  }
  .blog--default .blog__content {
    padding: 0 25px;
  }
  .blog--default .blog__content .blog__meta {
    margin-top: 20px;
  }
  .sidebar--blog .sidebar--post .card-title ul li + li {
    padding-left: 0;
  }
  /* SINGLE BLOG CONTENT */
  .single_blog_content .share_tags .share .social_share ul li + li {
    margin-left: 3px;
  }
  .single_blog_content .share_tags .share .social_share span {
    line-height: 35px;
    width: 35px;
  }
  /* HOW IT WORKS */
  .content_block3 .area_content {
    margin-bottom: 30px;
  }
  /* ABOUT US PAGE */
  .about_hero {
    padding: 105px 0;
  }
  .about_hero .about_hero_contents h1, .about_hero .about_hero_contents p {
    font-size: 30px;
    line-height: 48px;
  }
  .about_hero .about_hero_contents .about_hero_btns {
    padding-top: 20px;
  }
  .content_block1 .content_image, .content_block2 .content_image, .content_block2 .content_image2, .content_block1 .mission_image, .content_block2 .mission_image {
    display: none;
  }
  .timeline .happening:nth-child(2n+2) .happening--detail {
    padding: 50px 30px 0 0;
  }
  .timeline .happening .happening--period {
    padding: 45px 30px 0 0;
  }
  .timeline .happening .happening--detail {
    padding: 45px 0 0 30px;
  }
  /* 404 PAGE */
  .four_o_four_area .not_found h3 {
    line-height: 30px;
  }
  /* EVENT DETAILS CSS */
  .single_speaker {
    width: 100%;
    padding-right: 0;
  }
  .single_speaker:nth-child(2n+1), .single_speaker:nth-child(2n+2) {
    padding: 0;
  }
  .event_module .list_item li {
    line-height: 28px;
  }
  .google_map .location_address {
    position: initial;
    top: auto;
    left: auto;
    transform: translate(0);
    text-align: center;
    width: 100%;
  }
  .google_map .location_address .addres_module {
    background: #f7f7f7;
  }
  .author-card .author-infos .author-badges ul {
    justify-content: center;
  }
  .badges.community-badges .author-badges .badge-single {
    width: 50%;
  }
  .item-info ul.nav-tabs li {
    width: 100%;
  }
}

/* 320 TO 480 */
@media (max-width: 480px) {
  .credit_modules .custom_amount input, .shortcode_modules .custom_amount input, .upload_modules .custom_amount input, .withdraw_module .custom_amount input {
    width: 205px;
  }
  .upload_wrapper .progress_wrapper {
    width: 80%;
  }
  .invoice .invoice__meta .address {
    width: 100%;
  }
  .invoice .invoice__meta .date_info {
    width: 100%;
    text-align: left;
  }
  /* section padding */
  .section--padding {
    padding: 80px 0;
  }
  .xs-fullwidth {
    width: 100%;
  }
  .logo img {
    width: 100%;
  }
  .social, .single_blog_content .share_tags .share .social_share {
    text-align: center;
  }
  .social ul li, .single_blog_content .share_tags .share .social_share ul li {
    margin-bottom: 10px;
  }
  .pagination-area.pagination-area2 {
    padding: 25px 10px;
  }
  /* btns */
  .btn--lg {
    min-width: 155px;
    line-height: 45px;
    font-size: 14px;
  }
  .container {
    width: 100%;
  }
  /* BREADCRUMB */
  .breadcrumb-area .page-title {
    font-size: 24px;
  }
  /* ACCRODION */
  .accordion .accordion__single h4 a {
    padding: 15px 20px;
    font-size: 16px;
    line-height: 23px;
  }
  .accordion .accordion__single h4 a span {
    display: inline-block;
    vertical-align: middle;
    margin-right: -3px;
    width: calc(100% - 23px);
  }
  .accordion .accordion__single h4 a i {
    display: inline-block;
    vertical-align: middle;
    margin-right: -3px;
  }
  .hero-area.hero--2 .hero__content__title h1 {
    font-size: 38px;
    line-height: 41px;
  }
  .hero-area.hero--2 .hero__content__title .tagline {
    line-height: 32px;
  }
  .toggle_title h4 {
    font-size: 18px;
  }
  /* CARDS */
  .card_style1 .card_style1__info figcaption {
    padding: 30px;
  }
  /* PRODUCTS */
  .product--card .product-desc {
    height: auto;
  }
  .product--card3 .product-desc {
    padding: 25px 25px 20px;
  }
  .product .product-desc {
    padding: 25px;
  }
  .product .product-purchase {
    padding: 15px 25px;
  }
  .product .rating ul {
    margin-bottom: 5px;
  }
  .product .rating ul li span {
    font-size: 13px;
  }
  .product.product--list .product__details .product-meta .love-comments {
    margin-left: 0;
  }
  .product.product--list .product__details .product-meta .product-tags {
    display: none;
  }
  .product.product--list-small .product__details .product-meta .love-comments {
    display: none;
  }
  /* HERO AREA */
  .hero-area {
    height: 539px;
  }
  .hero-area .hero-content .content-wrapper {
    padding-top: 70px;
  }
  .hero-area .hero__content__title h1 {
    font-size: 30px;
  }
  .hero-area .hero__content__title .tagline {
    font-size: 18px;
    padding-bottom: 30px;
  }
  .hero-area .search_box .search-btn {
    margin-left: 0;
  }
  .hero-area .hero__btn-area .btn {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .hero-area .hero__btn-area .btn:first-child {
    margin-right: 0;
  }
  .hero-area .search-area {
    position: relative;
    -webkit-transform: translateY(-23%);
    -moz-transform: translateY(-23%);
    -ms-transform: translateY(-23%);
    -o-transform: translateY(-23%);
    transform: translateY(-23%);
  }
  /* FEATURED PRODUCT AREA */
  .featured-products .featured__product-description {
    padding: 25px 25px 0 25px;
  }
  /* SORTING CSS */
  .sorting ul li a {
    font-size: 12px;
    padding: 0 11px;
    line-height: 30px;
  }
  .price_love p {
    display: none;
  }
  /* COUNTER UP AREA */
  .counter-up .counter {
    width: 100%;
    margin-bottom: 40px;
  }
  .counter-up .counter .count {
    margin-top: 20px;
  }
  .tags ul > li {
    margin-bottom: 10px;
  }
  /* TESTIMONIAL ARES */
  .testimonial-slider .slick-arrow {
    border-radius: 0;
    color: #fff;
    top: 0;
    right: 0;
    line-height: 40px;
    background: #0674ec;
    width: 40px;
    font-size: 14px;
  }
  .testimonial-slider .slick-arrow.lnr-chevron-left {
    right: 0;
    left: auto;
    transform: translateX(-100%);
  }
  .testimonial-slider .slick-arrow.lnr-chevron-right {
    transform: translateX(0);
  }
  .testimonial {
    margin: 0;
    padding: 30px 25px;
  }
  .testimonial .testimonial__about {
    text-align: center;
  }
  .testimonial .testimonial__about .name-designation {
    display: block;
    padding-left: 0;
  }
  /* NEWS AREA */
  .news .news__meta .other li + li {
    margin-left: 10px;
  }
  /* PROMOTIONAL AREA */
  .promotion-content .promotion__title {
    font-size: 32px;
    line-height: 42px;
  }
  /* OVERVIEW AREA */
  .overview_cont {
    margin-bottom: 30px;
  }
  .overview-title {
    font-size: 24px;
    line-height: 35px;
    margin-bottom: 15px;
  }
  .pagination-area .page-numbers {
    line-height: 32px;
    min-width: 40px;
  }
  /* ITEM PREVIEW */
  .item-preview .item__preview-thumb .thumb-slider {
    width: calc(100% - 90px);
  }
  .item-preview .item__preview-thumb .prev-nav span {
    line-height: 30px;
    font-size: 14px;
    padding: 0 10px;
  }
  .item-preview .item__preview-thumb .prev-nav span + span {
    margin-left: 5px;
  }
  .item-preview .item-action {
    margin-top: -20px;
  }
  .item-preview .item-action a {
    margin-left: 0 !important;
    margin-top: 20px;
  }
  /* THREAD */
  .thread .thread-list .single-thread .media-body .comment-tag.buyer {
    margin-left: 0;
    margin-top: 10px;
  }
  .thread .thread-list .single-thread .media-body a.reply-link {
    padding-top: 13px;
  }
  .thread .thread-list .single-thread .media-left {
    padding-right: 15px;
  }
  .thread .thread-list .single-thread .media-left a {
    height: 55px;
    width: 55px;
  }
  .thread .thread-list .single-thread .media {
    padding: 30px 15px 26px;
  }
  .thread .thread-list .single-thread .depth-2 {
    margin-left: 25px;
  }
  /* CART AREA */
  .product_archive .product__description .short_desc {
    width: 100%;
    padding-left: 0;
    margin-top: 30px;
  }
  /* CHECKOUT AREA CSS */
  .payment_options ul li img {
    display: none;
  }
  .payment_options ul li p {
    float: none;
  }
  /* THIS IS THREAD AREA */
  .thread.thread_review .thread-list .single-thread .media-body .rating, .thread.thread_review .thread-list .single-thread .media-body .review_tag {
    float: left;
    clear: both;
    display: initial;
  }
  .thread.thread_review .thread-list .single-thread .media-body .review_tag {
    margin-left: 0;
    margin-top: 5px;
  }
  /* USER AREA */
  .user_area .user_single .user__meta {
    width: 100%;
    display: block;
    min-height: 100%;
    margin: 15px 0 20px;
  }
  .user_area .user_single .user__meta p {
    display: inline-block;
  }
  .user_area .user_single .user__meta p + P {
    margin-left: 10px;
  }
  .user_area .user_single .user__short_desc .user_info {
    width: 100%;
    padding-left: 0;
    margin-top: 20px;
  }
  .user_area .user_single .user__short_desc .user_info a {
    padding-bottom: 5px;
  }
  /* MESSAGE AREA */
  .messaging_sidebar .messaging__header, .recent_message .messaging__header {
    padding: 20px;
  }
  .messaging_sidebar .messaging__header .messaging_action .text, .recent_message .messaging__header .messaging_action .text {
    display: none;
  }
  .messaging_sidebar .messaging__header .messaging_action .lnr.lnr-sync, .recent_message .messaging__header .messaging_action .lnr.lnr-sync {
    display: none;
  }
  .messaging_sidebar .messaging__header .messaging_action .btn, .recent_message .messaging__header .messaging_action .btn {
    padding: 0 15px;
  }
  .messaging_sidebar .messaging__contents, .recent_message .messaging__contents, .recent_message .dashboard__content {
    margin-bottom: 40px;
  }
  .messaging_sidebar .messaging__contents .messages .message, .recent_message .messaging__contents .messages .message, .recent_message .dashboard__content .messages .message {
    padding: 25px;
  }
  .messaging_sidebar .messaging__contents .messages .message .message__actions_avatar, .recent_message .messaging__contents .messages .message .message__actions_avatar, .recent_message .dashboard__content .messages .message .message__actions_avatar {
    margin-bottom: 15px;
  }
  .messaging_sidebar .messaging__contents .messages .message .message__actions_avatar .actions span, .recent_message .messaging__contents .messages .message .message__actions_avatar .actions span, .recent_message .dashboard__content .messages .message .message__actions_avatar .actions span, .messaging_sidebar .messaging__contents .messages .message .message__actions_avatar .actions .custom_checkbox, .recent_message .messaging__contents .messages .message .message__actions_avatar .actions .custom_checkbox, .recent_message .dashboard__content .messages .message .message__actions_avatar .actions .custom_checkbox {
    display: inline-block;
    margin-top: 0;
    margin-left: 3px;
  }
  .messaging_sidebar .messaging__contents .messages .message .message__actions_avatar .avatar, .recent_message .messaging__contents .messages .message .message__actions_avatar .avatar, .recent_message .dashboard__content .messages .message .message__actions_avatar .avatar {
    display: none;
  }
  .messaging_sidebar .messaging__contents .messages .message .message_data, .recent_message .messaging__contents .messages .message .message_data, .recent_message .dashboard__content .messages .message .message_data {
    width: 100%;
    padding-left: 0;
  }
  .chat_area .chat_area--title h3, .recent_message .dashboard__content .chat_area--title h3 {
    width: calc(100% - 70px);
  }
  .chat_area .chat_area--conversation .head .name_time p, .recent_message .dashboard__content .chat_area--conversation .head .name_time p {
    float: none;
  }
  .chat_area .chat_area--conversation .body, .recent_message .dashboard__content .chat_area--conversation .body {
    padding-left: 0;
  }
  .chat_area .chat_area--conversation .body .attachments .attachment_head a, .recent_message .dashboard__content .chat_area--conversation .body .attachments .attachment_head a {
    margin-left: 0;
  }
  .chat_area .chat_area--conversation .body .attachments .attachment_head p, .recent_message .dashboard__content .chat_area--conversation .body .attachments .attachment_head p {
    display: block;
  }
  .chat_area .chat_area--conversation .body .attachments .attachment, .recent_message .dashboard__content .chat_area--conversation .body .attachments .attachment {
    overflow: hidden;
  }
  .chat_area .chat_area--conversation .body .attachments .attachment ul li, .recent_message .dashboard__content .chat_area--conversation .body .attachments .attachment ul li {
    width: 50%;
    float: left;
    padding: 5px;
  }
  .chat_area .chat_area--conversation .body .attachments .attachment ul li + li, .recent_message .dashboard__content .chat_area--conversation .body .attachments .attachment ul li + li {
    margin-left: 0;
  }
  /* SINGLE JOB PAGE HERO AREA */
  .job_hero_area {
    padding-top: 110px;
    padding-bottom: 100px;
  }
  .job_hero_area p {
    padding: 30px 0 40px;
    font-size: 18px;
  }
  .job_hero_area h1 {
    font-size: 42px;
    line-height: 48px;
  }
  .dashboard_module .dashboard__content {
    padding: 0 20px 18px;
  }
  .job_area .single_job {
    padding: 30px;
  }
  .job_area .single_job .job__title {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .job_area .single_job .job__location_type p {
    margin-bottom: 10px;
  }
  .feature_area .single_feature {
    text-align: center;
  }
  .feature_area .single_feature .feature__icon {
    vertical-align: top;
    margin-bottom: 15px;
  }
  .feature_area .single_feature .feature__content {
    width: 100%;
    padding-left: 0;
  }
  /* JOB PAGE */
  .job_detail_module .job__title {
    font-size: 22px;
    line-height: 30px;
  }
  .job_detail_module.information .job__content .informations li {
    width: 100%;
    margin-bottom: 20px;
  }
  .job_detail_module .job__content .content_list {
    padding-left: 20px;
  }
  .job_detail_module .job__content .content_list li {
    padding-left: 20px;
  }
  /* DASHBOARD PAGE */
  .country_statistics .dashboard__title h4 {
    margin-bottom: 15px;
  }
  .country_statistics .dashboard__title .select-wrap {
    float: none;
  }
  .country_statistics .dashboard__title .select-wrap select {
    width: 100%;
  }
  .recent_sells .dashboard__content ul li .single_sell .single_sell__thumb-title {
    width: 100%;
  }
  .recent_sells .dashboard__content ul li .single_sell .single_sell__thumb-title .prod_title {
    width: 100%;
    display: block;
    padding-left: 0;
  }
  .recent_sells .dashboard__content ul li .single_sell .ammount {
    float: none;
  }
  .chart_top .v_refer .nav-tabs li a {
    font-size: 16px;
  }
  .visit_data .v_refer .select-wrap {
    float: none;
  }
  /* DASHBOARD PURCHASE */
  .dashboard_purchase .product_archive .single_product .product__additional_info {
    margin-top: 20px;
  }
  .dashboard_purchase .product_archive .single_product .product__description .short_desc {
    width: 100%;
  }
  .dashboard_purchase .product_archive .single_product .product__price_download {
    margin-top: 20px;
  }
  .dashboard_purchase .product_archive .single_product .product__price_download .item_action {
    float: none;
    display: block;
    text-align: center;
  }
  /* ADD CREDIT PAGE */
  .credit_modules .modules__content .amounts ul li, .shortcode_modules .modules__content .amounts ul li, .upload_modules .modules__content .amounts ul li, .withdraw_module .modules__content .amounts ul li {
    margin-right: 10px;
  }
  .credit_modules .modules__content .amounts ul li + li, .shortcode_modules .modules__content .amounts ul li + li, .upload_modules .modules__content .amounts ul li + li, .withdraw_module .modules__content .amounts ul li + li {
    margin-left: 0;
  }
  .credit_modules .modules__content .amounts ul li p, .shortcode_modules .modules__content .amounts ul li p, .upload_modules .modules__content .amounts ul li p, .withdraw_module .modules__content .amounts ul li p {
    min-width: 100px;
    font-size: 20px;
    padding: 10px 0;
    line-height: 24px;
  }
  .credit_modules .modules__content .payment_method li, .shortcode_modules .modules__content .payment_method li, .upload_modules .modules__content .payment_method li, .withdraw_module .modules__content .payment_method li {
    width: 42%;
  }
  /* DASHBOARD WITHDRAWAL PAGE */
  .dashboard-withdraw .dashboard_title_area .dashboard__title, .dashboard-withdraw .dashboard_title_area .pull-right {
    float: none !important;
    text-align: center;
  }
  .dashboard-withdraw .dashboard_title_area .btn {
    display: block;
    width: 100%;
  }
  .withdraw_module .button_wrapper .btn {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
  .withdraw_module .withdraw_amount .fee {
    margin-left: 0;
  }
  .withdraw_module .withdraw_amount .input-group input {
    width: 207px;
  }
  /* FORUM DETAIL AREA */
  .forum_detail_area .comment-form-area {
    padding: 25px;
  }
  .featured_event .event_img {
    padding-right: 0;
  }
  .featured_event .featured_event_detail {
    padding: 30px 20px;
  }
  .featured_event .featured_event_detail h1 {
    font-size: 30px;
    margin-bottom: 25px;
  }
  .featured_event .featured_event_detail .date_place li {
    display: block;
    margin-left: 0;
  }
  .featured_event .featured_event_detail .date_place li + li {
    margin-top: 17px;
  }
  .featured_event .featured_event_detail .countdown li {
    font-size: 22px;
  }
  .featured_event .featured_event_detail .countdown li span {
    font-size: 14px;
  }
  .featured_event .featured_event_detail .countdown li + li {
    margin-left: 10px;
  }
  .news {
    margin-bottom: 30px;
  }
  .news .news__content {
    height: auto;
  }
  .inline.pull-right {
    float: none !important;
  }
  .tab2 .item-navigation ul li, .tab3 .item-navigation ul li {
    display: block;
    float: none;
  }
  .badges.community-badges .author-badges .badge-single {
    width: 100%;
  }
  .home2 .product__title a + a {
    margin-left: 0;
  }
  .footer-menu {
    display: block;
  }
  .info-contact {
    margin-bottom: 30px;
  }
  .prod_slide_prev, .prod_slide_next {
    position: static;
    display: inline-block;
    left: auto;
    transform: none;
    margin-top: 30px;
  }
}

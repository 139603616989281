// this  page contains style for modal

/*****************************
	--- MODAL
*****************************/
@import "../common/theme";
.modal{
  .modal-header{
    padding: 30px;
    display: block;
    p{
      margin: 0;
    }
  }
  .modal-title{
    margin-bottom: 17px;
  }
  @media (min-width: 768px){
    .modal-dialog {
        width: 750px;
        margin: 30px auto;
    }
  }
  .modal-body{
    padding: 30px;
  }
  .modal_close{
    background: $scolor;
    line-height: 40px;
    color: #fff;
    margin-left: 10px;
    padding: 0 36px;
  }
}

/*item removal modal */
.item_removal {
  .btns{
    text-align: center;
    a{
      &+a{
        margin-left: 20px;
      }
    }
  }
  .modal-body{
    text-align: center;
    padding: 50px 0;
    h4{
      margin-bottom: 20px;
      font-size: 24px;
      margin-bottom: 50px;
    }
    .modal-dialog{

    }
  }

  .modal-dialog{
    top: 33%;
  }
}

.item_remove_modal{
    .modal-header{
        position: relative;
        flex-direction: column;
        button{
            position: absolute;
            right: 30px;
            top: 45px;
        }
    }
}
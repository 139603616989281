/* HELPER CLASSES: this file contains classes for quick uses */
/*****************************
	-- HELPER
*****************************/
.no-padding{
  padding: 0;
}
.no-margin{
  margin: 0;
}
.padding-left0{
  padding-left: 0;
}
.ltr{
  text-align: right;
}
.rtl{
  text-align: left;
}
.br_0{
  border-right: none!important;
}
.bt1{
  border-top: 1px solid #e4e4e4;
}
.v_middle{
  display: inline-block;
  vertical-align: middle;
  margin-right: -3px;
  float: none;
}
.inline{
  display: inline-block;
}
.text--white{
  color: #fff;
}
.rounded{
  border-radius: 200px !important;
}

// border
.border-right1{
  border-right: 1px solid $borderColor;
}

.bold{
  font-weight: 500;
}



/* verticaly middle */
.ver_mid{
  display: table-cell;
  vertical-align: middle;
}
.shortcode_module_title{
  @extend .product-title-area;
}